
import React, { ReactComponentElement, ReactElement, RefObject, useCallback, useEffect, useMemo, useRef, useState } from 'react';
import { createRoot } from 'react-dom/client';
import * as ReactDOM from 'react-dom';
import { Fragment } from 'react'
import { Menu, Transition } from '@headlessui/react'
import { ArrowUpLeftIcon, ChartBarIcon, ChevronDownIcon, LockClosedIcon, LockOpenIcon, TvIcon } from '@heroicons/react/20/solid'
import { DocumentDuplicateIcon } from "@heroicons/react/20/solid";
import { 
    CursorArrowRaysIcon,
    PencilIcon,
    MagnifyingGlassIcon,
    ArrowDownLeftIcon,
    ArrowsUpDownIcon,
    PaintBrushIcon,
    ChatBubbleLeftIcon,
    ComputerDesktopIcon,
    VideoCameraIcon,
    MicrophoneIcon,
    PhoneXMarkIcon,
    HeartIcon,
    ClipboardIcon,
    PhoneIcon,
    UsersIcon,
    ArrowsPointingInIcon,
    SpeakerWaveIcon,
    UserIcon,
    SignalIcon,
    SignalSlashIcon,
    SunIcon
} from '@heroicons/react/24/outline'
import { 
    CursorArrowRaysIcon as CursorArrowRaysIconSolid,
    CursorArrowRippleIcon as CursorArrowRippleIconSolid,
    ClipboardIcon as ClipboardIconSolid,
    PencilIcon as PencilIconSolid,
    MagnifyingGlassIcon  as MagnifyingGlassIconSolid,
    PaintBrushIcon as PaintBrushIconSolid,
    ArrowLeftCircleIcon as ArrowLeftCircleIconSolid,
    EllipsisHorizontalCircleIcon as EllipsisHorizontalCircleIconSolid,
    RectangleStackIcon as RectangleStackIconSolid,
    ArrowDownLeftIcon as ArrowDownLeftIconSolid,
    ArrowsUpDownIcon as ArrowsUpDownIconSolid,
    ChatBubbleLeftIcon as ChatBubbleLeftIconSolid,
    ComputerDesktopIcon as ComputerDesktopIconSolid,
    VideoCameraIcon as VideoCameraIconSolid,
    MicrophoneIcon as MicrophoneIconSolid,
    PhoneXMarkIcon as PhoneXMarkIconSolid,
    PhoneIcon as PhoneIconSolid,
    HeartIcon as HeartIconSolid,
    UsersIcon as UsersIconSolid,
    SpeakerWaveIcon as SpeakerWaveIconSolid,
    CheckCircleIcon as CheckCircleIconSolid,
    XMarkIcon as XMarkIconSolid,
} from '@heroicons/react/20/solid'

import { 
    Mic, 
    MicOff ,
    Video,
    VideoOff,
    Circle,
    MousePointer,
    Icon,
    Phone,
    EyeOff
} from 'react-feather';
import { MediaDeviceDetailedInfo } from './TokyoConnection';
import { randomInt } from 'crypto';
import { OverlayMode, TokyoDesktopElementViewModel, penSizeToIconWidth } from './TokyoDesktopElement';
import { buildInfo, channelToString } from '../BuildInfo';
import { Button } from 'react-bootstrap';
import { productFriendlyName } from './utils/Utils';
import { isUndefined } from 'lodash';


const copyToClipboard = (str: string) => {
    navigator.clipboard.writeText(str);
};

const CopyButton = (props: { value: string, className?: string, style? : React.CSSProperties }) => {
    const [showCopied, setShowCopied] = useState<boolean>(false);
    const [copyTimeout, setCopyTimeout] = useState<NodeJS.Timeout | null>(null);

    const handleCopyClick = () => {
        //ReactGA.event("ep_copy_activation_code_clicked");
        if (copyTimeout !== null) {
            clearTimeout(copyTimeout);
        }
        try {
            copyToClipboard(props.value);
        } catch(e) {}
        setShowCopied(true);

        setCopyTimeout(setTimeout(() => {
            setShowCopied(false);
        }, 1000));

    }
    return <button
        type="button"
        className={`${props.className ?? ""} align-middle inline items-center rounded-md bg-sky-600 px-2 py-1 text-xs text-center font-medium text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600`}
        onClick={() => handleCopyClick()}
        style={props.style}
    >
        {showCopied ? <>Copied</> : <DocumentDuplicateIcon className="h-3 w-3 text-white" aria-hidden="true" />}
    </button>
}


type buttonTypes =
 "cursor_normal"
 | "cursor_highlighted"
 | "cursor_requestControl"
 | "pencil_normal"
 | "pencil_highlighted"
 | "shape_line"
 | "shape_ellipse"
 | "shape_rectangle"
 | "shape_arrow"

 function useOutsideAlerter(ref : RefObject<HTMLDivElement>, handleOutsideClick : () => void) {

    const handler = (ev : MouseEvent) => {
        if (ref.current && ev.target && !ref.current.contains(ev.target as Node)) {
            handleOutsideClick();
        }
    }

    useEffect(() => {
      // Bind the event listener
      document.addEventListener("mousedown", handler);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handler);
      };
    }, [ref]);
  }


export type ButtonAction = {
    type : "cursor",
    value : TokyoDesktopElementViewModel.CursorMode
} | {
    type : "penSize",
    value : TokyoDesktopElementViewModel.PenSize
} | {
    type : "color",
    value : TokyoDesktopElementViewModel.Color
} | {
    type : "localScreen"
    value : "share" | "stopSharing"
} | {
    type : "remoteScreen"
    value : "share" | "stopSharing"
} | {
    type : "localWebcam"
    value : "share"
    deviceId : string | null
}  | {
    type : "localWebcam"
    value : "stopSharing"
} | {
    type : "remoteWebcam"
    value : "share" | "stopSharing"
} | {
    type : "localMic"
    value : "unmute"
    deviceId : string | null
}  | {
    type : "localMic"
    value : "mute"
} | {
    type : "remoteMic"
    value : "mute" | "unmute"
} | {
    type : "call"
    value : "start" | "addDevice" | "invite" | "end"
} | {
    type : "help"
    value : "show"
} | {
    type : "chat"
    value : "show"
} | {
    type : "chat"
    value : "setClipboard"
} | {
    type : "custom"
    value : string
}

const ColorIcon = (active : boolean, color : string, borderColor : string, scale? : number) => 
    <svg height="20" width="20">
        <ellipse cx="10" cy="10" rx={10 * (scale ?? 1.0)} ry={10 * (scale ?? 1.0)}  className={`${borderColor}`}/>
        <ellipse cx="10" cy="10" rx={9 * (scale ?? 1.0)} ry={9 * (scale ?? 1.0)}  className={`${color}`}/>
    </svg>


const colorToUICOlor = (color : TokyoDesktopElementViewModel.Color) => {
    switch (true) {
        case color === 'black':
            return {
                active : "fill-black",
                secondary : "fill-gray-800"
            }
        case color === 'cyan':
            return {
                active : "fill-cyan-500",
                secondary : "fill-cyan-400"
            }
        case color === 'magenta':
            return {
                active : "fill-pink-500",
                secondary : "fill-pink-400"
            }
        case color === 'yellow':
            return {
                active : "fill-yellow-500",
                secondary : "fill-yellow-400"
            }
        default:
            throw new Error(color satisfies never);
    }
}

const classNames = (...classes : any) => {
    return classes.filter(Boolean).join(' ')
  }

type ButtonAccent = "neutral" | "red" | "cyan" | "green" | "amber"
type ButtonSelectionAccent = "red" | "amber" | "cyan"

type ButtonStyle = {type: "selectable", selectionAccent?: ButtonSelectionAccent, selected : boolean, valueType : "standard" | "disable" | "requiredPermission"} | {type: "stateful", selectionAccent?: ButtonSelectionAccent, active : boolean} | {type: "standard", accent : ButtonAccent}

type ButtonIcon = {type : "icon", icon : any}  | {type : "elem",  elem : (active : boolean) => ReactElement}

type Button = {
    type : "cursorMode" | "drawingMode" | "zoomMode" | "shapesMode" | "toolSize" | "colors" | "chat" | "video" | "audio" | "screen"  | "call" | "help" | "misc",
    name : string,
    icon: {type : "icon", icon : any}  | {type : "elem",  elem : any},
    highlighted: "idle" | "partial" | "active" | "activemic",
    menuAlignment? : "left" | "right" 
    enabled? : boolean
    items: {
        type? : "standard" | "about",
        name : string
        enabled? : boolean
        //accent : ButtonAccent
        style? : ButtonStyle
        active? : boolean
        icon : ButtonIcon
        action : ButtonAction
        subItems? : {
            name : string
            style : ButtonStyle
            icon : ButtonIcon
            action : ButtonAction
        }[][]
    }[][]
}

const buttonStyleToProperties = (style : ButtonStyle) => {
    
    if (style.type === 'standard') {
        if (style.accent === 'neutral') {
            return { 
                    rowDiv : "bg-white text-gray-700 hover:bg-gray-100 hover:text-gray-900", 
                    icon : "text-gray-400 group-hover:text-gray-700" 
                };
        } else if (style.accent === 'green') {
            return { 
                    rowDiv : "bg-white text-gray-700 hover:bg-green-50 hover:text-green-950", 
                    icon : "text-gray-400 group-hover:text-green-900"
                };
        }else if (style.accent === 'red') {
            return { 
                    rowDiv : "bg-white text-gray-700 hover:bg-red-50 hover:text-red-950", 
                    icon : "text-gray-400 group-hover:text-red-900"
                };
        }else if (style.accent === 'amber') {
            return { 
                    rowDiv : "bg-white text-gray-700 hover:bg-amber-50 hover:text-amber-950", 
                    icon : "text-gray-400 group-hover:text-amber-900"
                };
        }else if (style.accent === 'cyan') {
            return { 
                    rowDiv : "bg-white text-gray-700 hover:bg-cyan-50 hover:text-cyan-950", 
                    icon : "text-gray-400 group-hover:text-cyan-900"
                };
        } else {
            throw "Match exception";
        }
    } else if (style.type === 'stateful') {
        const bgAccent500 = (style.selectionAccent || "cyan") === "cyan" ? "bg-cyan-500" : (style.selectionAccent === 'amber') ? "bg-amber-500" : "bg-red-500";
        const hoverBgAccent400 = (style.selectionAccent || "cyan") === "cyan" ? "hover:bg-cyan-400" : (style.selectionAccent === 'amber') ? "hover:bg-amber-400" : "hover:bg-red-400";
        const groupHoverBgAccent400 = (style.selectionAccent || "cyan") === "cyan" ? "group-hover:bg-cyan-400" : (style.selectionAccent === 'amber') ? "group-hover:bg-amber-400" : "group-hover:bg-red-400";
        return style.active ? { 
                rowDiv : `font-semibold ${bgAccent500} text-white ${hoverBgAccent400}`, 
                icon : "text-white group-hover:text-white",
                toggleSolidDiv : `bg-white group-hover:bg-white`, 
                toggleSolidInnerDiv : `${bgAccent500} ${groupHoverBgAccent400}`, 
            } : { 
                rowDiv : "font-semibold text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900", 
                icon : "text-gray-700 group-hover:text-gray-900" ,
                toggleSolidDiv : `bg-gray-700 group-hover:bg-gray-900`, 
                toggleSolidInnerDiv : `bg-white group-hover:bg-gray-100`, 
            };
    } else if (style.type === 'selectable') {
        const bgAccent500 = (style.selectionAccent || "cyan") === "cyan" ? "bg-cyan-500" : (style.selectionAccent === 'amber') ? "bg-amber-500" : "bg-red-500";
        const hoverBgAccent400 = (style.selectionAccent || "cyan") === "cyan" ? "hover:bg-cyan-400" : (style.selectionAccent === 'amber') ? "hover:bg-amber-400" : "hover:bg-red-400";

        if (style.selected) {
            if (style.valueType === "standard" || style.valueType === "requiredPermission") {
                return { 
                    rowDiv : `${bgAccent500} text-white ${hoverBgAccent400}`, 
                    icon : "text-white group-hover:text-white" 
                };
            } else if (style.valueType === "disable") {
                return { 
                    rowDiv : "text-gray-700 bg-white hover:bg-white hover:text-gray-700", 
                    icon : "text-gray-700 group-hover:text-gray-700" 
                };
            } else {
                throw "Match exception";
            }
        } else {
            if (style.valueType === "standard" || style.valueType === "requiredPermission") {
                return { 
                    rowDiv : `text-gray-700 bg-white ${hoverBgAccent400} hover:text-white`,
                    icon : "text-gray-700 group-hover:text-white"
                };
            } else if (style.valueType === "disable") {
                return { 
                    rowDiv : "text-gray-700 bg-white hover:bg-gray-100 hover:text-gray-900", 
                    icon : "text-gray-700 group-hover:text-gray-900"
                };
            } else {
                throw "Match exception";
            }
        }
    } else {
        throw "Match exception";
    }
}

const createButton = (button : Button, overlayMode : OverlayMode, localMicAudioLevel : number | null, localMicAudioActive : boolean, buttonActionHandler? : (action : ButtonAction) => void) => {

    const handleCLick = (action : ButtonAction) => {
        //alert(messagePort)
        buttonActionHandler ? buttonActionHandler(action) : 0;
    };

    const [menuState, setMenuState] = useState<{state : "open"} | {state : "closed"}>({state : "closed"})

    const menuRef = useRef<HTMLDivElement>(null)
    const menuItemsRef = useRef<HTMLDivElement>(null)

    const [currentHoverSubMenu, setCurrentHoverSubMenu] = useState<{div : HTMLDivElement, item : NonNullable<Button["items"][0][0]["subItems"]>} | null>(null)

    useEffect(() => {
        if (menuState.state === 'open') {
            
        } else {
            setCurrentHoverSubMenu(null);
        }
      }, [menuItemsRef.current, menuState]);
      

    //console.log(menuItemsRef.current ? menuItemsRef.current.offsetTop : null)

    useOutsideAlerter(menuRef, () => setMenuState({state: 'closed'}))

    return <div ref={menuRef} className={`relative flex inline-block text-left m-1 cursor-pointer`}>
      <div onClick={() => {setMenuState({state: 'open'}); }} className={`overflow-hidden inline-flex w-full justify-center gap-x-1.5 rounded ${button.highlighted === 'active' || button.highlighted === 'activemic' ? 'bg-cyan-500 ring-cyan-600 hover:bg-cyan-400' : button.highlighted === 'partial' ? 'bg-amber-500 ring-amber-600 hover:bg-amber-400' : 'bg-white ring-gray-300 hover:bg-gray-50'}  text-sm font-semibold text-gray-900 ring-0 ring-inset ${overlayMode === 'clickThrough' ? "shadow-lg" : ""}`}>
        <div className='flex flex-col justify-end top-0 bg-clip-border w-11 h-9'>
            {button.highlighted === 'activemic' ? <div className='w-full bg-cyan-400' style={{ height : localMicAudioActive ? `${Math.floor((localMicAudioLevel ?? 0.0) * 80) + 20}%` : "0%"}}></div> : <></>}
        </div>
        <div  className='absolute top-2'>
            {
                button.icon.type === "icon" ? <button.icon.icon className={`h-5 w-5  stroke-2 ${button.highlighted === 'active' || button.highlighted === 'activemic' ? "stroke-white" : ""}`}/> : button.icon.elem(false)
            }
        </div>
      </div>
      {
        menuState.state === "open" ? <>
            <div ref={menuItemsRef} className={`absolute shadow-xl ${button.menuAlignment === "right" ? "left-0" : "right-0"} z-10 mt-12 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100`} role='menu'>
                
                {
                    
                    //className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm flex')}
                    button.items.map((itemList) => {
                        return <div className="py-1" role="none" key={itemList.map((item, i1) => `${item.action.type}.${item.action.value}`).join(".")}>{itemList.map((item, i) => {
                            const colors = buttonStyleToProperties(item.style ?? {type: "standard", accent: "neutral"});
                            switch (true) {
                                case !item.type || item.type === 'standard':
                                    return <div key={`${item.action.type}.${item.action.value}-${i}`}>
                                        <a
                                        href="#"
                                        onClick={() => {if (item.action) { handleCLick(item.action);setMenuState({state: 'closed'})}}}
                                        onPointerEnter={(ev) => setCurrentHoverSubMenu(item.subItems ? {div: ev.target as HTMLDivElement, item: item.subItems} : null )}
                                        className={`${colors.rowDiv} block px-4 py-2 text-sm flex group`}
                                        >
                                            {
                                                item.icon.type === "icon" ? <item.icon.icon className={`flex-none h-5 w-5 mr-2 stroke-2 ${colors.icon}`} /> : <div className='mr-2'>{item.icon.elem(item.style?.type === 'selectable' && item.style?.selected)}</div>
                                            }
                                        <span className='grow'>{item.name}</span>
                                        {
                                            //
                                            item.style?.type === 'stateful' && colors.toggleSolidDiv ? <div  className='flex flex-none ml-2 content-center' >
                                                <div style={{width: "28px", height: "16px"}} className={`flex rounded-3xl self-center ${item.style.active === (item.style.selectionAccent !== "red") ? "flex-row-reverse" : "flex-row"} ${colors.toggleSolidDiv}`}>
                                                    <div style={{width: "12px", height: "12px", margin: "2px"}} className={`rounded-3xl ${colors.toggleSolidInnerDiv}`}></div>
                                                </div>
                                            </div> : <></>
                                        }
                                        </a>
                                    
                                    </div>
                                case item.type === 'about':
                                    return <div key={`${item.action.type}.${item.action.value}`}>
                                        <>
                                            <div>
                                                <span className={'text-gray-400 block px-4 py-2 text-xs flex'}>© Tank Studios {new Date(Date.parse(buildInfo.build.date)).getFullYear()}</span>
                                            </div>
                                            <div>
                                                <span className={'text-gray-400 block px-4 pb-2 text-xs flex'}>{buildInfo.product.name} ● {buildInfo.product.subProduct}</span>
                                            </div>
                                            <div>
                                                <span className={'text-gray-400 block px-4 pb-2 text-xs flex'}>{buildInfo.source.gitCommitShortHash} ● {buildInfo.build.environment} ● {buildInfo.build.id ?? "no build id"}</span>
                                            </div>
                                            <div>
                                                <span className={'text-gray-400 block px-4 pb-2 text-xs flex'}>{channelToString(buildInfo.channel)} ● {buildInfo.runtimeEnvironment.environment}</span>
                                            </div>
                                            <div>
                                                <span className={'text-gray-400 block px-4 pb-2 text-xs flex'}>{new Date(Date.parse(buildInfo.build.date)).toUTCString()}</span>
                                            </div>
                                        </>
                                    </div>
                                default:
                                    throw new Error(item.type satisfies never)
                            }
                            
                            })}</div>;
                        })
                    }
                </div>
                {
                 currentHoverSubMenu ? <div 
                    className={`absolute shadow-xl z-10 mt-0 w-56 origin-top-right divide-y divide-gray-100 rounded-md bg-white ring-1 ring-black ring-opacity-5 focus:outline-none transform opacity-100 scale-100`} 
                    role='menu'
                    style={Object.assign({top: `${(currentHoverSubMenu?.div.offsetTop ?? 0) + (menuItemsRef.current?.offsetTop ?? 0) - 4}px`}, button.menuAlignment === "right" ? {left : `${menuItemsRef.current?.offsetWidth ?? 0}px` } : {right : `${(menuItemsRef.current?.offsetWidth ?? 0)}px`})}
                    >
                
                {
                    
                    //className={classNames(active ? 'bg-gray-100 text-gray-900' : 'text-gray-700', 'block px-4 py-2 text-sm flex')}
                    currentHoverSubMenu.item.map((itemList) => {
                        return <div className="py-1" role="none" key={itemList.map((item, i1) => `${item.action.type}.${item.action.value}`).join(".")}>{itemList.map((item, i) => {
                            const colors = buttonStyleToProperties(item.style ?? {type: "standard", accent: "neutral"})
                            return <div key={`${item.action.type}.${item.action.value}-${i}`}>
                                <a
                                href="#"
                                onClick={() => {if (item.action) { handleCLick(item.action);setMenuState({state: 'closed'})}}}
                                className={`${colors.rowDiv} block px-4 py-2 text-sm flex group`}
                                >
                                    {
                                        item.icon.type === "icon" ? <item.icon.icon className={`flex-none h-5 w-5 mr-2 stroke-2 ${colors.icon}`} /> : <div className='mr-2'>{item.icon.elem(false)}</div>
                                    }
                                <div className="inline">
                                    <span className="inline">{item.name}</span>
                                    {
                                        item.style.type === 'selectable' && item.style.valueType === 'disable' ? <CheckCircleIconSolid width={18} height={18}  className={`inline ml-1 ${colors.icon} ${item.style.selected ? "" : "opacity-0 group-hover:opacity-100"}`}/> : <></>
                                    }
                                    </div>
                                </a>
                            
                            </div>
                            
                            })}</div>;
                        })
                    }
                </div> : <></>
            }</> : <></>
      }
    </div>;
  }

  const ToolbarOverlay = (props : {
    className? : string, 
    children: React.ReactNode, 
    videoWidth : number, 
    setUIScale? : (uiScale : number) => void, 
    scaleToFit: Boolean, 
    mode : OverlayMode,
    visible : boolean,
    viewModel : TokyoDesktopElementViewModel.TokyoDesktopElementViewModel,
    localMicAudioActive : boolean,
    localMicAudioLevel : number | null
    setViewModelProperties : (viewModelProperties : Partial<TokyoDesktopElementViewModel.TokyoDesktopElementViewModel>) => void
    buttonActionHandler? : (buttonAction : ButtonAction) => void}) => {

    const toolbarHeight = 50;
    const [height, setHeight] = useState<number | null>(null);
    const [width, setWidth] = useState<number | null>(null);
    const [left, setLeft] = useState<number>(0);
    let scaleUI = props.scaleToFit ? (width ? width / props.videoWidth : 1.0) : 1.0;
    //console.log(`ToolbarOverlay setWidth: ${scaleUI}`)
    
    //console.log("props.viewModel")
    //console.log(props.viewModel)

    
let buttons : (Button| null)[] = useMemo(() => {
    //console.log(`buttons memo ${props.mode}`)
    return [
    {
        type: "cursorMode",
        name : "Cursor",
        icon: {type: "icon", icon: MousePointer},
        menuAlignment: "right",
        highlighted: (props.viewModel.cursorMode === 'cursor' || props.viewModel.cursorMode === 'highlightedCursor') ? "active" : "idle",
        items : [[
            { 
                name: "Request control of the desktop",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.remoteDesktopControlButtons,
                icon: {type: "icon", icon: MousePointer },
                style: {type: 'selectable', valueType : "requiredPermission", selected: props.viewModel.cursorMode === 'cursor'},
                action: {
                    type : "cursor",
                    value : "cursor"
                }
            }, 
            { 
                name: "Request control of the desktop with highlighted cursor",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.remoteDesktopControlButtons,
                icon: {type: "icon", icon: CursorArrowRippleIconSolid },
                style: {type: 'selectable', valueType : "requiredPermission", selected: props.viewModel.cursorMode === 'highlightedCursor'},
                action: {
                    type : "cursor",
                    value : "highlightedCursor"
                }
            }
        ]]
    },
    {
        type: "drawingMode",
        name : "Pencil",
        icon: {type: "icon", icon: PencilIcon},
        menuAlignment: "right",
        highlighted: (props.viewModel.cursorMode === 'pencil' || props.viewModel.cursorMode === 'line' || props.viewModel.cursorMode === 'ellipse' || props.viewModel.cursorMode === 'rectangle' || props.viewModel.cursorMode === 'arrow') ? "active" : "idle",
        items : [[
            { 
                name: "Pencil",
                icon: {type: "icon", icon: PencilIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'pencil'},
                action: {
                    type : "cursor",
                    value : "pencil"
                }
            },
            { 
                name: "Line",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.shapeTools,
                icon: {type: "icon", icon: ArrowLeftCircleIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'line'},
                action: {
                    type : "cursor",
                    value : "line"
                }
            },
            { 
                name: "Ellipse",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.shapeTools,
                icon: {type: "icon", icon: EllipsisHorizontalCircleIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'ellipse'},
                action: {
                    type : "cursor",
                    value : "ellipse"
                }
            },
            { 
                name: "Rectangle",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.shapeTools,
                icon: {type: "icon", icon: RectangleStackIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'rectangle'},
                action: {
                    type : "cursor",
                    value : "rectangle"
                }
            },
            { 
                name: "Arrow",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.shapeTools,
                icon: {type: "icon", icon: ArrowDownLeftIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'arrow'},
                action: {
                    type : "cursor",
                    value : "arrow"
                }
            },
            { 
                name: "Use my phone to draw",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.usePhoneActions,
                icon: {type: "icon", icon: PhoneIconSolid},
                style: {type: 'selectable', valueType : "standard", selected: false},
                action: {
                    type : "custom",
                    value : "dummy"
                }
            }
        ],[
            {
                name: `People can draw in this ${productFriendlyName}`,
                icon: {type: "icon", icon: LockOpenIcon},
                style: {type: 'stateful', selectionAccent: "red", active: false},
                action: {
                    type : "custom",
                    value: "blockNewInvites"
                },
                subItems: [
                    [{ 
                        name: `People can draw in this ${productFriendlyName}`,
                        icon: {type: "icon", icon: LockOpenIcon},
                        style: {type: "selectable", selectionAccent: "red", valueType : "disable", selected: true},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `People cannot draw in this ${productFriendlyName}`,
                        icon: {type: "icon", icon: LockClosedIcon},
                        style: {type: "selectable", selectionAccent: "red", valueType : "standard", selected: false},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }]
    ]
    },
    {
        type: "zoomMode",
        enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.zoomTools,
        name : "Zoom",
        icon: {type: "icon", icon: MagnifyingGlassIcon},
        menuAlignment: "right",
        highlighted: (props.viewModel.cursorMode === 'magnifier' || props.viewModel.cursorMode === 'vignette') ? "active" : "idle",
        items : [[
            { 
                name: props.viewModel.localScreenShare.enabled ? "Magnify" : "Request region to magnify",
                icon: {type: "icon", icon: MagnifyingGlassIconSolid},
                style: {type: 'selectable', valueType : props.viewModel.localScreenShare.enabled ? "standard" : "requiredPermission", selected: props.viewModel.cursorMode === 'magnifier'},
                action: {
                    type : "cursor",
                    value : "magnifier"
                }
            },
            { 
                name: "Spotlight",
                icon: {type: "icon", icon: SunIcon},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.cursorMode === 'vignette'},
                action: {
                    type : "cursor",
                    value : "vignette"
                }
            }
        ]]
    },
    {
        type: "toolSize",
        name : "Pen Size",
        icon:  {type: "elem", elem: (active : boolean) => ColorIcon(active, `${active ? "fill-black" : "fill-gray-800"}`, `${active ? "fill-black" : "fill-gray-800"}` , penSizeToIconWidth(props.viewModel.penSize))},
        highlighted: "idle",
        items : [[
            { 
                name: "Small",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`,  penSizeToIconWidth("small"))},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.penSize === 'small'},
                action: {
                    type : "penSize",
                    value : "small"
                }
            },
            { 
                name: "Medium",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`, penSizeToIconWidth("medium"))},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.penSize === 'medium'},
                action: {
                    type : "penSize",
                    value : "medium"
                }
            },
            { 
                name: "Large",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`, `${active ? "fill-white" : "fill-black"} group-hover:fill-white`,  penSizeToIconWidth("large"))},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.penSize === 'large'},
                action: {
                    type : "penSize",
                    value : "large"
                }
            }
        ]]
    },
    {
        type: "colors",
        name : "Colors",
        icon: {type: "elem", elem: (active : boolean) => ColorIcon(active,  active ? colorToUICOlor(props.viewModel.color).active : colorToUICOlor(props.viewModel.color).secondary, "fill-white")},
        highlighted: "idle",
        items : [[
            { 
                name: "Cyan",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, active ? colorToUICOlor("cyan").active : colorToUICOlor("cyan").secondary, "fill-white")},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.color === 'cyan'},
                action: {
                    type : "color",
                    value : "cyan"
                }
            },
            { 
                name: "Magenta",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active,  active ? colorToUICOlor("magenta").active : colorToUICOlor("magenta").secondary, "fill-white" )},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.color === 'magenta'},
                action: {
                    type : "color",
                    value : "magenta"
                }
            },
            { 
                name: "Yellow",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, active ? colorToUICOlor("yellow").active : colorToUICOlor("yellow").secondary,  "fill-white" )},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.color === 'yellow'},
                action: {
                    type : "color",
                    value : "yellow"
                }
            },
            { 
                name: "Black",
                icon: {type: "elem", elem: (active : boolean) => ColorIcon(active, active ? colorToUICOlor("black").active : colorToUICOlor("black").secondary,  "fill-white")},
                style: {type: 'selectable', valueType : "standard", selected: props.viewModel.color === 'black'},
                action: {
                    type : "color",
                    value : "black"
                }
            }
        ]]
    },
    null,
    {
        type: "chat",
        name : "Chat",
        icon: {type: "icon", icon: ChatBubbleLeftIcon},
        highlighted: "idle",
        items : [[
            { 
                name: "Chat",
                icon: {type: "icon", icon: ChatBubbleLeftIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action : {
                    type:  "chat",
                    value: 'show'
                }
            },
            { 
                name: "Set Clipboard",
                icon: {type: "icon", icon: ClipboardIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action : {
                    type:  "chat",
                    value: 'setClipboard'
                }
            }]]
    },
    {
        type: "screen",
        name : "screen",
        icon: {type: "icon", icon: ComputerDesktopIcon},
        highlighted: "idle",
        items : [[
            { 
                name: true ? "Whiteboard" : "My screen (Screen 1)",
                icon: {type: "icon", icon: TvIcon},
                style: {type: 'selectable', valueType : "standard", selected : true},
                action: {
                    type : "localMic",
                    value: 'mute'
                },
                subItems: [[
                    {
                        name: "Whiteboard",
                        icon: {type: "icon", icon: TvIcon},
                        style: {type: "selectable", valueType : "standard", selected : true},
                        action: {
                            type : "custom",
                            value : "l2"
                        }
                    },
                    { 
                        name: `My screen`,
                        icon: {type: "icon", icon: ComputerDesktopIconSolid},
                        style: {type: "selectable", valueType : "standard", selected : false},
                        action: {
                            type: "localWebcam",
                            value: `stopSharing`
                        }
                    },
                    {
                        name: "My webcam",
                        icon: {type: "icon", icon: VideoCameraIconSolid},
                        style: {type: "selectable", valueType : "standard", selected : false},
                        action: {
                            type : "custom",
                            value : "l2"
                        }
                    },
                    {
                        name: "My phone camera",
                        icon: {type: "icon", icon: Phone},
                        style: {type: "selectable", valueType : "standard", selected : false},
                        action: {
                            type : "custom",
                            value : "l2"
                        }
                    },
                    {
                        name: "Poll",
                        icon: {type: "icon", icon: ChartBarIcon},
                        style: {type: "selectable", valueType : "standard", selected : false},
                        action: {
                            type : "custom",
                            value : "l2"
                        }
                    }]]
            },{ 
                name: true ? "Not recording" : "Recording",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.recording,
                icon: {type: "icon", icon: Circle},
                style: {type: 'stateful', active: false},
                action: {
                    type : "localMic",
                    value: 'mute'
                }
            },{ 
                name: true ? "Not broadcasting" : "Broadcasting",
                enabled: buildInfo.featureFlags.tokyoSession.overlayButtons.broadcasting,
                icon: {type: "icon", icon: true ? SignalSlashIcon  : SignalIcon},
                style: {type: 'stateful', active: false},
                action: {
                    type : "localMic",
                    value: 'mute'
                }
            },
        ],[
            ["Jonathan Smith", false] as [string, boolean],
            ["José Berríos", true] as [string, boolean],
            ["Hagen Danner", false] as [string, boolean],
            ["Bowden Francis", false] as [string, boolean],
            ["Yimi García", false] as [string, boolean]
        ].map(([name, enabled]) => {
            return { 
                name,
                icon: {type: "icon", icon: UsersIconSolid},
                accent: "neutral",
                action: {
                    type : "custom",
                    value: "dummy"
                },
                subItems: [
                    [{ 
                        name: `Screen sharing disabled`,
                        icon: {type: "icon", icon: VideoOff},
                        style: {type: "selectable", valueType : "disable", selectionAccent: "amber", selected: !enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Screen sharing`,
                        icon: {type: "icon", icon: Video},
                        style: {type: "selectable", valueType : "standard", selectionAccent: "amber", selected: enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        }),[
            {
                name: `People can share their screen in this ${productFriendlyName}`,
                icon: {type: "icon", icon: ComputerDesktopIconSolid},
                style: {type: 'stateful', selectionAccent: "red", active: false},
                action: {
                    type : "custom",
                    value: "blockNewInvites"
                },
                subItems: [
                    [{ 
                        name: `People can share their screen in this ${productFriendlyName}`,
                        icon: {type: "icon", icon: ComputerDesktopIconSolid},
                        style: {type: "selectable", selectionAccent: "red", valueType : "disable", selected: true},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `People cannot share their screen in this ${productFriendlyName}`,
                        icon: {type: "icon", icon: EyeOff},
                        style: {type: "selectable", selectionAccent: "red", valueType : "standard", selected: false},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        ]]
    },
    {
        type: "video",
        name : "video capture",
        icon: {type: "icon", icon: props.viewModel.localScreenShare.enabled || props.viewModel.localWebcam.state === 'shared' ? Video : VideoOff},
        highlighted: props.viewModel.localScreenShare.enabled || props.viewModel.localWebcam.state === 'shared' ? "active" : props.viewModel.localWebcam.state === 'sharedPendingPermission' ? "partial" : "idle",
        items : [[
            { 
                name: (() => {
                    const activeDeviceId = props.viewModel.localWebcam.state === 'shared' ? props.viewModel.localWebcam.deviceId : false
                    const activeDevice = (props.viewModel.mediaDevices.state === 'loaded') && activeDeviceId ? props.viewModel.mediaDevices.deviceInfo.find(device => device.deviceId === activeDeviceId) ?? null : null;
                    return activeDevice ?  `${activeDevice.label}` : `Not sharing`;
                })(),
                style: {type: "stateful", active: props.viewModel.localWebcam.state === 'shared'},
                icon: {type: "icon", icon: props.viewModel.localWebcam.state === 'shared' ? Video : VideoOff},
                action: (props.viewModel.localWebcam.state === 'shared' || props.viewModel.localWebcam.state === 'sharedPendingPermission' ? {
                    type : "localWebcam",
                    value : "stopSharing"
                } : {
                    type : "localWebcam",
                    value : "share",
                    deviceId: null
                }),
                subItems: [
                    [{ 
                        name: `Not sharing`,
                        icon: {type: "icon", icon: VideoOff},
                        style: {type: "selectable", valueType : "disable", selected: props.viewModel.localWebcam.state !== 'shared'},
                        action: {
                            type: "localWebcam",
                            value: `stopSharing`
                        }
                    } satisfies NonNullable<Button["items"][0][0]["subItems"]>[0][0] as NonNullable<Button["items"][0][0]["subItems"]>[0][0]].concat((props.viewModel.mediaDevices.state === 'loaded') ? props.viewModel.mediaDevices.deviceInfo.filter(device => device.kind === 'videoinput').map((device) => {
                        const active = props.viewModel.localWebcam.state === 'shared' ? props.viewModel.localWebcam.deviceId === device.deviceId : false
                        return { 
                            name: `${device.label === '' ? "Default webcam" : device.label}`,
                            icon: {type: "icon", icon: device.kind === 'audioinput' ? MicrophoneIconSolid : device.kind === 'videoinput' ? Video : device.kind === 'audiooutput' ? SpeakerWaveIconSolid : HeartIconSolid },
                            style: {type: "selectable", valueType : "standard", selected: active},
                            action: {
                                type: "localWebcam",
                                value: `share`,
                                deviceId: device.deviceId === '' ? null : device.deviceId
                            }
                        }  satisfies NonNullable<Button["items"][0][0]["subItems"]>[0][0]
                        }) : []).concat(
                [{
                    name: "Use my phone camera",
                    icon: {type: "icon", icon: Phone},
                    style: {type : 'selectable', valueType : "standard", selected : false},
                    action: {
                        type : "custom",
                        value : "l2"
                    }
                }])]
            },
        ],[
            ["Jonathan Smith", false] as [string, boolean],
            ["José Berríos", true] as [string, boolean],
            ["Hagen Danner", false] as [string, boolean],
            ["Bowden Francis", false] as [string, boolean],
            ["Yimi García", false] as [string, boolean]
        ].map(([name, enabled]) => {
            return { 
                name,
                icon: {type: "icon", icon: UsersIconSolid},
                accent: "neutral",
                action: {
                    type : "custom",
                    value: "dummy"
                },
                subItems: [
                    [{ 
                        name: `Webcam disabled`,
                        icon: {type: "icon", icon: VideoOff},
                        style: {type: "selectable", valueType : "disable", selectionAccent: "amber", selected: !enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Webcam enabled`,
                        icon: {type: "icon", icon: Video},
                        style: {type: "selectable", valueType : "standard", selectionAccent: "amber", selected: enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        })
        ,[
            {
                name: `Webcams are enabled for this ${productFriendlyName}`,
                icon: {type: "icon", icon: Video},
                style: {type: 'stateful', selectionAccent: "red", active: false},
                action: {
                    type : "custom",
                    value: "blockNewInvites"
                },
                subItems: [
                    [{ 
                        name: `Webcams are enabled for this ${productFriendlyName}`,
                        icon: {type: "icon", icon: Video},
                        style: {type: "selectable", selectionAccent: "red", valueType : "disable", selected: true},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Webcams are disabled for this ${productFriendlyName}`,
                        icon: {type: "icon", icon: VideoOff},
                        style: {type: "selectable", selectionAccent: "red", valueType : "standard", selected: false},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        ]]
    },
    {
        type: "audio",
        name : "audio capture",
        icon: {type: "icon", icon: props.viewModel.localMic.state === 'unmuted' ? Mic : MicOff},
        highlighted: props.viewModel.localMic.state === 'unmuted' ? "activemic" : "idle",
        items : [[
            { 
                name: (() => {
                    const activeDeviceId = props.viewModel.localMic.state === 'unmuted' ? props.viewModel.localMic.deviceId : false
                    const activeDevice = (props.viewModel.mediaDevices.state === 'loaded') && activeDeviceId ? props.viewModel.mediaDevices.deviceInfo.find(device => device.deviceId === activeDeviceId) ?? null : null;
                    return activeDevice ?  `${activeDevice.label}` : `Muted`;
                })(),
                active : props.viewModel.localMic.state === 'unmuted',
                icon: {type: "icon", icon: props.viewModel.localMic.state === 'unmuted' ? Mic : MicOff},
                style: {type: 'stateful', active : props.viewModel.localMic.state === 'unmuted'},
                action: (props.viewModel.localMic.state === 'unmuted' ? {
                    type : "localMic",
                    value: 'mute'
                } : {
                    type : "localMic",
                    value: 'unmute',
                    deviceId: null
                }) ,
                subItems: [
                    [{ 
                        name: `Muted`,
                        icon: {type: "icon", icon: MicOff},
                        style: {type: "selectable", valueType : "disable", selected: props.viewModel.localMic.state === 'muted'},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    } satisfies NonNullable<Button["items"][0][0]["subItems"]>[0][0] as NonNullable<Button["items"][0][0]["subItems"]>[0][0]].concat((props.viewModel.mediaDevices.state === 'loaded') ? props.viewModel.mediaDevices.deviceInfo.filter(device => device.kind === 'audioinput').map((device) => {
                        const active = props.viewModel.localMic.state === 'unmuted' ? props.viewModel.localMic.deviceId === device.deviceId : false
                        return { 
                            name: `${device.label === '' ? "Default mic" : device.label}`,
                            icon: {type: "icon", icon: device.kind === 'audioinput' ? MicrophoneIconSolid : device.kind === 'videoinput' ? VideoCameraIconSolid : device.kind === 'audiooutput' ? SpeakerWaveIconSolid : HeartIconSolid },
                            style: {type: "selectable", valueType : "standard", selected: active},
                            action: {
                                type: "localMic",
                                value: `unmute`,
                                deviceId : device.deviceId === '' ? null : device.deviceId
                            }
                        } satisfies NonNullable<Button["items"][0][0]["subItems"]>[0][0]
                        }) : []).concat(
                [{
                    name: "Use my phone mic",
                    icon: {type: "icon", icon: PhoneIconSolid},
                    style: {type: "selectable", valueType : "standard", selected: false},
                    action: {
                        type : "custom",
                        value : "l2"
                    },
                }])]
            },
        ],[
            ["Jonathan Smith", false] as [string, boolean],
            ["José Berríos", true] as [string, boolean],
            ["Hagen Danner", false] as [string, boolean],
            ["Bowden Francis", false] as [string, boolean],
            ["Yimi García", false] as [string, boolean]
        ].map(([name, enabled]) => {
            return { 
                name,
                icon: {type: "icon", icon: UsersIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action: {
                    type : "custom",
                    value: "dummy"
                },
                subItems: [
                    [{ 
                        name: `Muted`,
                        icon: {type: "icon", icon: MicOff},
                        style: {type: "selectable", valueType : "disable", selectionAccent: "amber", selected: !enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Unmuted`,
                        icon: {type: "icon", icon: Mic},
                        style: {type: "selectable", valueType : "standard", selectionAccent: "amber", selected: enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        }),[
            {
                name: "Mics are enabled for this Pin Wheel",
                icon: {type: "icon", icon: Mic},
                style: {type: 'stateful', selectionAccent: "red", active: false},
                action: {
                    type : "custom",
                    value: "blockNewInvites"
                },
                subItems: [
                    [{ 
                        name: `Mics are enabled for this Pin Wheel`,
                        icon: {type: "icon", icon: Mic},
                        style: {type: "selectable", selectionAccent: "red", valueType : "disable", selected: true},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Mics are disabled for this Pin Wheel`,
                        icon: {type: "icon", icon: MicOff},
                        style: {type: "selectable", selectionAccent: "red", valueType : "standard", selected: false},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        ]]
    },
    {
        type: "call",
        name : "call",
        icon: {type: "icon", icon: (props.viewModel.callStatus.type === 'connected') || (props.viewModel.callStatus.type === 'pendingConnection') ? UsersIcon : UsersIcon},
        highlighted: (props.viewModel.callStatus.type === 'connected') ? "active" : "idle",
        items : [
            //[
            //    {
            //        name: (props.viewModel.callStatus.type === 'connected') ? `Call active` : `Idle`,
            //        icon: {type: "icon", icon: (props.viewModel.callStatus.type === 'connected') ? PhoneIconSolid : PhoneXMarkIconSolid},
            //        style: {type: 'stateful', selectionAccent: "cyan", active: (props.viewModel.callStatus.type === 'connected')},
            //        action: {
            //            type : "call",
            //            value: (props.viewModel.callStatus.type === 'connected') ? 'end' : 'start'
            //        },
            //        subItems: [
            //            [{ 
            //                name: `Idle`,
            //                icon: {type: "icon", icon: PhoneXMarkIconSolid},
            //                style: {type: "selectable", selectionAccent: "cyan", valueType : "disable", selected: (props.viewModel.callStatus.type !== 'connected')},
            //                action: {
            //                    type : "call",
            //                    value: 'end'
            //                }
            //            },{ 
            //                name: `Call active`,
            //                icon: {type: "icon", icon: PhoneIconSolid},
            //                style: {type: "selectable", selectionAccent: "cyan", valueType : "standard", selected: (props.viewModel.callStatus.type === 'connected')},
            //                action: {
            //                    type : "call",
            //                    value: 'start'
            //                }
            //            }]]
            //    }
            //],
        [
            {
                name: "Brian Hoary - Tank Studios", //props.viewModel.localUser.state === 'loggedIn' ? props.viewModel.localUser.displayName : "Not logged in",
                icon: {type: "icon", icon: UsersIconSolid},
                style: {type: 'standard', accent: "cyan"},
                action: {
                    type : "call",
                    value: "invite"
                }
            } as Button["items"][0][0]].concat([
            ["Jonathan Smith", false] as [string, boolean],
            ["José Berríos", true] as [string, boolean],
            ["Hagen Danner", false] as [string, boolean],
            ["Bowden Francis", false] as [string, boolean],
            ["Yimi García", false] as [string, boolean]
        ].map(([name, enabled]) => {
            return { 
                name,
                icon: {type: "icon", icon: UsersIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action: {
                    type : "custom",
                    value: "dummy"
                },
                subItems: [
                    [{ 
                        name: `Mic is muted`,
                        icon: {type: "icon", icon: VideoOff},
                        style: {type: "selectable", valueType : "disable", selectionAccent: "amber", selected: !enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `Mic is unmuted`,
                        icon: {type: "icon", icon: Video},
                        style: {type: "selectable", valueType : "standard", selectionAccent: "amber", selected: enabled},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            } as Button["items"][0][0]
        })),[
            {
                name: "Invite people",
                icon: {type: "icon", icon: UsersIconSolid},
                style: {type: 'standard', accent: "cyan"},
                action: {
                    type : "call",
                    value: "invite"
                } 
            }],[
            {
                name: `New people cannot join the ${productFriendlyName}`,
                icon: {type: "icon", icon: LockClosedIcon},
                style: {type: 'stateful', selectionAccent: "red", active: true},
                action: {
                    type : "custom",
                    value: "blockNewInvites"
                },
                subItems: [
                    [{ 
                        name: `New people can join the ${productFriendlyName}`,
                        icon: {type: "icon", icon: LockOpenIcon},
                        style: {type: "selectable", selectionAccent: "red", valueType : "disable", selected: false},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    },{ 
                        name: `New people cannot join the ${productFriendlyName}`,
                        icon: {type: "icon", icon: LockClosedIcon},
                        style: {type: "selectable", selectionAccent: "red", valueType : "standard", selected: true},
                        action: {
                            type : "localMic",
                            value: 'mute'
                        }
                    }]]
            }
        ],[{ 
            name: "Exit",
            icon: {type: "icon", icon: XMarkIconSolid},
            style: {type: 'standard', accent: "red"},
            action: {
                type : "call",
                value: "end"
            }
        }]]
    },
    null,
    {
        type: "help",
        name : "Help",
        icon: {type: "icon", icon: HeartIcon},
        highlighted: false ? "active" : "idle",
        items : [[
            { 
                name: "Help",
                icon: {type: "icon", icon: HeartIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action: {
                    type : "help",
                    value: "show"
                }
            },
            { 
                name: "About",
                type: "about",
                icon: {type: "icon", icon: HeartIconSolid},
                style: {type: 'standard', accent: "neutral"},
                action: {
                    type : "help",
                    value: "show"
                }
            }
        ]]
    },
] satisfies (Button | null)[]}, [props.viewModel]);

//console.log(`overlay viewmodel: ${props.mode}`)
//console.log(props.viewModel)

let ammendedButtons = useMemo(() => {
    const newButtons = buttons.concat([
        {
            type: "misc",
            name : "misc",
            icon: {type: "icon", icon: (props.viewModel.mediaDevices.state === 'loaded') ? HeartIcon : SpeakerWaveIcon},
            highlighted: "idle",
            items : [
                (props.viewModel.mediaDevices.state === 'loaded') ? props.viewModel.mediaDevices.deviceInfo.map((device) => {
                    const deviceTypeName =
                        (() => {
                            if (device.kind === "audioinput") {
                                return "Audio Input";
                            }
                            else if (device.kind === "videoinput") {
                                return "Video Input";
                            }
                            else if (device.kind === "audiooutput") {
                                return "Audio Output";
                            }
                            else if (device.kind === "") {
                                return "Audio Output";
                            }
                            return "Unknown"
                        })();
                    return { 
                        name: `${device.label}`,
                        icon: {type: "icon", icon: device.kind === 'audioinput' ? MicrophoneIconSolid : device.kind === 'videoinput' ? VideoCameraIconSolid : device.kind === 'audiooutput' ? SpeakerWaveIconSolid : HeartIconSolid },
                        accent: "cyan",
                        action: {
                            type: "custom",
                            value: `deviceId-${device.deviceId}`
                        }
                    }
                    }) : []
                ]
            }
        ]
    );
    return buttons.filter(button => button ? isUndefined(button.enabled) || button.enabled : true).map((button) => {
        return button ? Object.assign({}, button, {items :  button.items.map(items => items.filter(item => item ? isUndefined(item.enabled) || item.enabled : true)) }) : button;
    });
    }, [buttons, props.viewModel]);

    const [shortCode, setShortCode] = useState<string>("")

    useEffect(() => {
            
        const generateShortCode = () => {
            const legalChars = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ0123456789-~'.split('');
            return Array.from(Array(Math.ceil(48 / 6)).keys()).map(() => legalChars[Math.floor(Math.random() * 64)]).join("");
        }
        setShortCode(generateShortCode());
        

        if (props.setUIScale)
            props.setUIScale(scaleUI) ;
    }, [scaleUI]);
        
    const div = useCallback((node : HTMLDivElement) => {
      if (node !== null) {
        const sizeChanged = () => {
            setHeight(node.parentElement!.clientHeight);
            //setWidth(node.getBoundingClientRect().width);
            setWidth(node.parentElement!.clientWidth)
            setLeft(node.parentElement!.offsetLeft)
            //console.log(`node.parentElement!.clientLeft: ${node.parentElement!.offsetLeft}`)
        }
        window.addEventListener("resize", () => {
            //console.log("resize() fired")
            sizeChanged();
        })
        sizeChanged();
      }
    }, [props.videoWidth, props.visible]);



    return <React.Fragment>
        <div ref={div} className={`${props?.className ?? ""} content-start flex-col`} style={
            {
                touchAction: "none", 
                pointerEvents: props.mode === "clickThrough" ? "none" : "none",
                width: `${(width ?? 1) / scaleUI}px`, 
                height: `${(height ?? 1) / scaleUI}px`, 
                //left: `${left}px`,
                transform: `matrix(${scaleUI}, 0, 0, ${scaleUI}, ${width ? (1.0 - 1.0 / scaleUI) * width / 2.0: 0}, ${(1.0 - 1.0 / scaleUI) * (height ?? 1 * scaleUI) / 2.0})`
                
                //transform: `matrix(${scaleUI}, 0, 0, ${scaleUI}, 0,0)`
                }}>
                {props.mode === "clickThrough" ? props.children : []}
                <div className={`w-full justify-center items-center flex absolute `} style={{
                    height:"50px",
                    background : props.mode === "clickSolid" ?  `rgba(0, 0, 0, 0.0)` :  `rgba(0, 0, 0, 0.2)`, }}>
                    {
                        <div style={{width: "36px", height: "36px"}} className={`bg-gray-900 rounded-full mr-4 cursor-pointer pointer-events-auto ${props.mode === "clickThrough" ? "shadow-lg" : ""}`}/>
                    }               
                    {
                        ammendedButtons.map((button, i) => {
                            return <div className={`${button?.type ? "" : "w-6"}`} style={{pointerEvents:"auto"}} key={`${button?.type ?? i}-${i}` }>{button ? createButton(button, props.mode, props.localMicAudioLevel, props.localMicAudioActive, props.buttonActionHandler) : []}</div>
                        })
                    }
                </div>
                {
                    props.viewModel.callStatus.type === 'pendingConnection' && false ? <div className='m-4 py-4 px-4 justify-center self-center items-center flex rounded-lg absolute' style={{
                        background : props.mode === "clickSolid" ?  `rgba(0, 0, 0, 0.0)` :  `rgba(0, 0, 0, 0.2)`, top: "80px" }}>
                        <div className='block'>
                            <button className={`${props.mode === "clickSolid" ? "pointer-events-auto" : "text-transparent"} group text-gray-700 flex align-middle items-center rounded-md bg-white px-2 py-1 text-base text-center font-semibold shadow-sm hover:bg-gray-100 hover:text-gray-900 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600`}
                                onClick={() => { if (props.viewModel.callStatus.type !== "initialising" && props.viewModel.callStatus.type !== "disconnected") { copyToClipboard(`https://distribution.tokyo.tankstudios.ltd/?uuid=${props.viewModel.callStatus.sessionId}`)}}}
                                style={{width:"300px"}}>
                                    <PhoneIconSolid className={`flex-none h-8 w-8 m-2 mr-4 text-gray-400 group-hover:text-cyan-500`} />
                                    <span className='grow mr-4 my-2'>Invite someone to the call</span>
                            </button>
                            <div className='flex justify-center mt-2 hidden'>
                                <span className={`${props.mode === "clickSolid" ? "text-white pointer-events-auto" : "text-transparent"}`}>https://tyko.io/{shortCode}</span><CopyButton className={`ml-2 ${props.mode === "clickSolid" ? "pointer-events-auto" : "invisible"}`} value={`https://distribution.tokyo.tankstudios.ltd/?uuid=${""}`}/>
                            </div>
                        </div>
                    </div> : <></>
    }
        </div>

    </React.Fragment>
}

export {
    ToolbarOverlay,
    CopyButton
}