"use client"
import '../globals.css'
import { Inter } from 'next/font/google'
import ReactGA from "react-ga4";
//
//
//

import Head from "next/head";
import { Fragment, PropsWithChildren, useEffect, useMemo, useState } from "react";
//import { useRouter } from "next/router";
import Link from "next/link";
import {
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  Squares2X2Icon,
  DocumentTextIcon,
  DocumentPlusIcon,
  UserIcon,
  PhoneIcon,
  ClockIcon,
  VideoCameraIcon,
  Cog8ToothIcon,
  BuildingOffice2Icon,
  CreditCardIcon,
  ShieldCheckIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon,
  ComputerDesktopIcon,
  CubeIcon,
  FingerPrintIcon,
  StarIcon,
  LockClosedIcon,
  HeartIcon
} from '@heroicons/react/24/outline'
//import 'bootstrap/dist/css/bootstrap.css';
import { FiCompass} from "react-icons/fi";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Provider } from 'react-redux';
import { usePathname, useSearchParams } from 'next/navigation';
import { buildInfo, channelToString } from "src/BuildInfo";// ''/ 'src/epicPenHelpers/build_info';
import { EPTUser, EpicPenContext, EpicPenProvider} from 'src/tokyoComponents/utils/Identity';
import { User as EPUser, User, createEpicPenLicencingAPI, createFakeUser, tokyoServicesServerURL } from 'src/tokyoComponents/utils/WebAPI';
import { Metadata } from 'next';
import mixpanel from 'mixpanel-browser';
import { useUser } from 'src/tokyoComponents/utils/Identity';
import { productFriendlyName } from 'src/tokyoComponents/utils/Utils';
import { isUndefined } from 'lodash';
import { Product } from 'src/tokyoComponents/PreBuildUtils';

export type NavigationState = {
  title: string;
  product: "all" | Product
  adminOnly? : boolean;
  enabled? : boolean
  url?: string | undefined;
  items: NavigationState[];
  icon?: React.ReactNode;
  special?: boolean
  HIcon?: React.ForwardRefExoticComponent<React.PropsWithoutRef<React.SVGProps<SVGSVGElement>>>;
  badge?: {
    color: string;
    text: string | number;
  };
};

const inter = Inter({ subsets: ['latin'] })


const Item: React.FC<NavigationState & {navType : "standard" | "settings"}> = ({url, icon, HIcon, title, badge, items, navType, special}) => {
  const [hidden, setHidden] = useState<boolean>(true);
  const pathname = usePathname();
  let active = pathname === url ? true : false;
  //let special = true;

  if (items.length === 0) {
    return (
      <li>
        <Link href={`${url}`} className={ active ? `${navType === 'standard' ? "bg-sky-700" : "bg-gray-700"} text-white group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold` : `${special ? "border border-solid border-[2px] border-gray-200 p-[6px]" : "p-2"} ${navType === 'standard' ? "text-sky-200" : "text-gray-200"} hover:text-white ${navType === 'standard' ? "hover:bg-sky-700" : "hover:bg-gray-700"} group flex gap-x-3 rounded-md text-sm leading-6 font-semibold`} >
          {
            HIcon ? <HIcon className="h-6 w-6 shrink-0 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true"/> :
            <HomeIcon className="h-6 w-6 shrink-0 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true"/>
          }
            {title}
        </Link>
      </li>
    );
  } else {
    return <>{items.map((item, i) => <Item navType={navType} {... item} key={i} />)}</>
  }
};

const getNavState = (user : EPTUser, navType : string) => {
  return [
    {
      title: "Applications",
      adminOnly : true,
      product: "epicpen",
      navType : "standard",
      items: [
        {
          url: "/",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: StarIcon,
          special: true,
          title: "Download Epic Pen",
          items: [],
        },
        {
          url: "/activationcodes",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: Squares2X2Icon,
          title: "Activation codes",
          items: [],
        },
        {
          url: "/orders",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: DocumentTextIcon,
          title: "Orders",
          items: [],
        }
      ]
    },
    {
      title: "Admin",
      adminOnly : false,
      product: "epicpen",
      items: [
        {
          url: "/admin/users",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: UsersIcon,
          title: "Users",
          items: [],
        },
        {
          url: "/admin/activationcodes",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: Squares2X2Icon,
          title: "Activation codes",
          items: [],
        },
        {
          url: "/admin/orders",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: DocumentTextIcon,
          title: "Orders",
          items: [],
        },
        {
          url: "/admin/customorders",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: DocumentPlusIcon,
          title: "Custom orders",
          items: [],
        }
      ]
    },
    {
      title: null,
      adminOnly : false,
      product: "tokyo",
      navType : "standard",
      items: [
        {
          url: "/call",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: StarIcon,
          title: `New ${productFriendlyName}`,
          special: true,
          items: [],
        },
        {
          url: "/recent",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: ClockIcon,
          title: "Recent",
          items: [],
          enabled : buildInfo.featureFlags.pages.recent
        },
        {
          url: "/contacts",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: UsersIcon,
          title: "Contacts",
          items: [],
        },
        {
          url: "/calander",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: CalendarIcon,
          title: "Calander",
          items: [],
          enabled : buildInfo.featureFlags.pages.calendar
        },
        {
          url: "/recordings",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: VideoCameraIcon,
          title: "Recordings",
          items: [],
          enabled : buildInfo.featureFlags.pages.recordings
        }
      ]
    },
    {
      title: "Settings",
      adminOnly : false,
      product: "tokyo",
      navType : "settings",
      items: [
        {
          url: "/settings",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: Cog8ToothIcon,
          title: "General",
          items: [],
        },
        {
          url: "/settings/profile",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: UserIcon,
          title: "Profile",
          items: [],
        },
        {
          url: "/settings/organisation",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: BuildingOffice2Icon,
          title: "Organisation",
          items: [],
          enabled : buildInfo.featureFlags.pages.settings.organisation
        },
        {
          url: "/settings/organisation/members",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: UsersIcon,
          title: "Members",
          items: [],
          enabled : buildInfo.featureFlags.pages.settings.organisation
        },
        {
          url: "/settings/security",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: FingerPrintIcon,
          title: "Security",
          items: [],
        },
        {
          url: "/settings/billing",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: CreditCardIcon,
          title: "Billing",
          items: [],
          enabled : buildInfo.featureFlags.billing
        },
        {
          url: "/settings/devices",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: ComputerDesktopIcon,
          title: "Devices",
          items: [],
          enabled : buildInfo.featureFlags.pages.settings.devices
        },
        {
          url: "/settings/privacy",
          product: "tokyo",
          icon: <FiCompass size={20} />,
          HIcon: LockClosedIcon,
          title: "Data & Privacy",
          items: [],
        },
      ]
    },
    {
      title: "Service admin",
      adminOnly : false,
      product: "tokyo",
      navType : "settings",
      items: [
        {
          url: "/settings/admin/users",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: UsersIcon,
          title: "Users",
          items: [],
        },
        {
          url: "/settings/admin/organisations",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: BuildingOffice2Icon,
          title: "Organisations",
          items: [],
        },
        {
          url: "/settings/admin/subscriptions",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: CreditCardIcon,
          title: "Subscriptions",
          items: [],
        },
        {
          url: "/settings/admin/orders",
          product: "all",
          icon: <FiCompass size={20} />,
          HIcon: DocumentTextIcon,
          title: "Orders",
          items: [],
        }
      ]
    },
  ].filter((navItem) => {
    const userHasAdmin = user.state === 'loggedIn' && user.hasEPUser && user.epUser.role === 'admin'
    const validProduct = navItem.product === "all" || navItem.product === buildInfo.product.name;
    return (navItem.adminOnly ? userHasAdmin : true) && validProduct && navItem.navType === navType;
  }) as NavigationState[];
}

export const HeaderElement = ({title, buttons} : {title : string, buttons : {name : string, hero? : boolean}[]}) => {
  return <div className="md:flex md:items-center md:justify-between mb-12 px-1">
  <div className="min-w-0 flex-1">
    <h2 className="pb-2 -mb-2 text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
      {title}
    </h2>
  </div>
  <div className="mt-4 flex md:ml-4 md:mt-0">
    {
      buttons.map((button, i) => {
        let isFirst = i === 0;
        let isLast = i === buttons.length - 1;

        return <button
          type="button"
          className={`${isFirst ? "" : "ml-3"} ${button.hero ? "bg-sky-600 text-white hover:bg-sky-700 focus-visible:outline-sky-600" : "bg-white text-gray-900 ring-1 ring-inset ring-gray-300 hover:bg-gray-50 "} inline-flex items-center rounded-md px-3 py-2 text-sm font-semibold shadow-sm focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 `}
        >
          {button.name}
      </button>;
      })
    }
  </div>
</div>
}


export const RootLayoutElement = ({children} : PropsWithChildren) => {
  const [sidebarOpen, setSidebarOpen] = useState(false)
  const user = useUser();

  const pathname = usePathname()
  const navType : "standard" | "settings" = pathname.startsWith("/settings") ? "settings" : "standard";
  const navColor : "standard" | "settings" = "standard";
  const initialState = getNavState(user, navType);
  
  return <html lang="en">
  <head>
    <meta name="robots" content="noindex, nofollow" />
  </head>
          <body className={`${inter.className}'`}>
          
    <div>
      <Transition.Root show={sidebarOpen} as={Fragment}>
        <Dialog as="div" className="relative z-50 lg:hidden" onClose={setSidebarOpen}>
          <Transition.Child
                  as={Fragment}
                  enter="transition-opacity ease-linear duration-300"
                  enterFrom="opacity-0"
                  enterTo="opacity-100"
                  leave="transition-opacity ease-linear duration-300"
                  leaveFrom="opacity-100"
                  leaveTo="opacity-0"
                >

            <div className="fixed inset-0 bg-gray-900/80"></div>
          </Transition.Child>
        <div className="fixed inset-0 flex">
                  <Transition.Child
                    as={Fragment}
                    enter="transition ease-in-out duration-300 transform"
                    enterFrom="-translate-x-full"
                    enterTo="translate-x-0"
                    leave="transition ease-in-out duration-300 transform"
                    leaveFrom="translate-x-0"
                    leaveTo="-translate-x-full"
                  >
                  <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                    <Transition.Child
                        as={Fragment}
                        enter="ease-in-out duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="ease-in-out duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                      >
            <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                          <button type="button" className="-m-2.5 p-2.5" onClick={() => setSidebarOpen(false)}>
                <span className="sr-only">Close sidebar</span>
                <svg className="h-6 w-6 text-white" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M6 18L18 6M6 6l12 12" />
                </svg>
              </button>
            </div>
                      </Transition.Child>

            <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${navColor === 'standard' ? "bg-sky-600" : "bg-gray-600"}  px-6 pb-4`}>
              <div className="flex h-16 shrink-0 items-center">
                <Link href="/">
                  <svg className="h-8 w-auto fill-white" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path d="M23.5 6.5C17.5 6.5 13.75 9.5 12.25 15.5C14.5 12.5 17.125 11.375 20.125 12.125C21.8367 12.5529 23.0601 13.7947 24.4142 15.1692C26.6202 17.4084 29.1734 20 34.75 20C40.75 20 44.5 17 46 11C43.75 14 41.125 15.125 38.125 14.375C36.4133 13.9471 35.1899 12.7053 33.8357 11.3308C31.6297 9.09158 29.0766 6.5 23.5 6.5ZM12.25 20C6.25 20 2.5 23 1 29C3.25 26 5.875 24.875 8.875 25.625C10.5867 26.0529 11.8101 27.2947 13.1642 28.6693C15.3702 30.9084 17.9234 33.5 23.5 33.5C29.5 33.5 33.25 30.5 34.75 24.5C32.5 27.5 29.875 28.625 26.875 27.875C25.1633 27.4471 23.9399 26.2053 22.5858 24.8307C20.3798 22.5916 17.8266 20 12.25 20Z"/>
                  </svg>
                </Link>
              </div>
              <nav className="flex flex-1 flex-col">
                <ul role="list" className="flex flex-1 flex-col gap-y-7">
                  
                  {
                    initialState.map((menu, i) => {
                      return <Fragment key={i}>
                        <li>
                          {
                            menu.title ? <div className={`text-xs font-semibold leading-6 ${navColor === 'standard' ? "text-sky-200" : "text-gray-200"}`}>{menu.title}</div> : <></>
                          }
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            
                {menu.items.filter((item) => isUndefined(item.enabled) || item.enabled).map((l0, a) => (
                            <Item navType={navColor} {...l0} key={a} />))}
                          </ul>
                        </li>
                      </Fragment>
                    })
                  }
                  
                </ul>
                <div>
                  <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{buildInfo.product.name}</span>
                </div>
                <div>
                  <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{channelToString(buildInfo.channel)}</span>
                </div>
                <div>
                  <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{buildInfo.runtimeEnvironment.environment}</span>
                </div>
                <div>
                  <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>git sha: {buildInfo.source.gitCommitShortHash}</span>
                </div>
              </nav>
            </div>
                    </Dialog.Panel>
                  </Transition.Child>
        </div>
              </Dialog>
            </Transition.Root>

      <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
        <div className={`flex grow flex-col gap-y-5 overflow-y-auto ${navColor === 'standard' ? "bg-sky-600" : "bg-gray-600"} px-6 pb-4`}>
          <div className="flex h-16 shrink-0 items-center">
            <Link href="/">
              <svg className="h-8 w-auto fill-white" viewBox="0 0 47 40" fill="none" xmlns="http://www.w3.org/2000/svg">
                <path d="M23.5 6.5C17.5 6.5 13.75 9.5 12.25 15.5C14.5 12.5 17.125 11.375 20.125 12.125C21.8367 12.5529 23.0601 13.7947 24.4142 15.1692C26.6202 17.4084 29.1734 20 34.75 20C40.75 20 44.5 17 46 11C43.75 14 41.125 15.125 38.125 14.375C36.4133 13.9471 35.1899 12.7053 33.8357 11.3308C31.6297 9.09158 29.0766 6.5 23.5 6.5ZM12.25 20C6.25 20 2.5 23 1 29C3.25 26 5.875 24.875 8.875 25.625C10.5867 26.0529 11.8101 27.2947 13.1642 28.6693C15.3702 30.9084 17.9234 33.5 23.5 33.5C29.5 33.5 33.25 30.5 34.75 24.5C32.5 27.5 29.875 28.625 26.875 27.875C25.1633 27.4471 23.9399 26.2053 22.5858 24.8307C20.3798 22.5916 17.8266 20 12.25 20Z"/>
              </svg>
            </Link>
          </div>
          <nav className="flex flex-1 flex-col">
            <ul role="list" className="flex flex-1 flex-col gap-y-7">

              {
                    initialState.map((menu, i) => {
                      return <Fragment key={i}>
                        <li>
                          <div className={`text-xs font-semibold leading-6 ${navColor === 'standard' ? "text-sky-200" : "text-gray-200"}`}>{menu.title}</div>
                          <ul role="list" className="-mx-2 mt-2 space-y-1">
                            
                {menu.items.filter((item) => isUndefined(item.enabled) || item.enabled).map((l0, a) => (
                            <Item navType={navColor} {...l0}  key={a}/>))}
                          </ul>
                        </li>
                      </Fragment>
                    })
              }
            </ul>
            <div>
              <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{buildInfo.product.name}</span>
            </div>
            <div>
              <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{channelToString(buildInfo.channel)}</span>
            </div>
            <div>
              <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>{buildInfo.runtimeEnvironment.environment}</span>
            </div>
            <div>
              <span className={`${navColor === 'standard' ? "text-sky-200" : "text-gray-200"} text-xs`}>git sha: {buildInfo.source.gitCommitShortHash}</span>
            </div>
          </nav>
        </div>
      </div>

      <div className="lg:pl-72">
        <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
          <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setSidebarOpen(true)}>
            <span className="sr-only">Open sidebar</span>
            <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
              <path strokeLinecap="round" strokeLinejoin="round" d="M3.75 6.75h16.5M3.75 12h16.5m-16.5 5.25h16.5" />
            </svg>
          </button>

          <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true"></div>

          <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
            {false ? <form className="relative flex flex-1" action="#" method="GET">
              <label htmlFor="search-field" className="sr-only">Search</label>
              <svg className="pointer-events-none absolute inset-y-0 left-0 h-full w-5 text-gray-400" viewBox="0 0 20 20" fill="currentColor" aria-hidden="true">
                <path fillRule="evenodd" d="M9 3.5a5.5 5.5 0 100 11 5.5 5.5 0 000-11zM2 9a7 7 0 1112.452 4.391l3.328 3.329a.75.75 0 11-1.06 1.06l-3.329-3.328A7 7 0 012 9z" clipRule="evenodd" />
              </svg>
              <input id="search-field" className="block h-full w-full border-0 py-0 pl-8 pr-0 text-gray-900 placeholder:text-gray-400 focus:ring-0 sm:text-sm" placeholder="Search..." type="search" name="search"/>
            </form> : <div className="relative flex flex-1" />
            }
            <div className="flex items-center gap-x-4 lg:gap-x-6">
              {
              false ?<button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                <span className="sr-only">View notifications</span>
                <svg className="h-6 w-6" fill="none" viewBox="0 0 24 24" strokeWidth="1.5" stroke="currentColor" aria-hidden="true">
                  <path strokeLinecap="round" strokeLinejoin="round" d="M14.857 17.082a23.848 23.848 0 005.454-1.31A8.967 8.967 0 0118 9.75v-.7V9A6 6 0 006 9v.75a8.967 8.967 0 01-2.312 6.022c1.733.64 3.56 1.085 5.455 1.31m5.714 0a24.255 24.255 0 01-5.714 0m5.714 0a3 3 0 11-5.714 0" />
                </svg>
              </button> : <></>
              }

              {false ? <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true"></div> : <></>}

              <Menu as="div" className="relative">
                      <Menu.Button className="-m-1.5 flex items-center p-1.5">
                  <span className="sr-only">Open user menu</span>
                  {
                    user.state !== 'uninitialised' ? user.hasEPUser && user.epUser.avatarId ? <>
                      <img 
                        className="h-8 w-8 rounded-lg bg-gray-200 shadow-sm" 
                        src={`${tokyoServicesServerURL}avatar/${user.epUser.avatarId}/32`}
                        srcSet={`${tokyoServicesServerURL}avatar/${user.epUser.avatarId}/64 2x`}
                        aria-hidden="true">
                      </img>
                    </> : <>
                      <svg className="h-8 w-8 rounded-lg bg-gray-200 shadow-sm" fill="none" viewBox="0 0 24 24" aria-hidden="true">
                        <circle cx={12} cy={10} r={5} className='fill-sky-600' />
                        <circle cx={12} cy={24} r={8} className='fill-sky-600' />
                      </svg>
                    </> : <>
                      <div className="h-8 w-8 rounded-lg bg-slate-200 animate-pulse"/>
                    </>
                  }
                  <span className={`ml-4 hidden lg:flex lg:items-center`}>
                    <span className="text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">{user.state === 'loggedIn' && user.hasEPUser ? <div>@{user.epUser.organisation ? `${user.epUser.organisation.name}/` : ""}{user.epUser.name}{user.epUser.loadedFromCache && !buildInfo.channel.isProduction ? "-cached" : ""}</div> : <div className='h-4 bg-slate-200 animate-pulse rounded-lg' style={{width:"80px"}}></div>}</span>
                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                  </span>
                </Menu.Button>
                <Transition
                  as={Fragment}
                  enter="transition ease-out duration-100"
                  enterFrom="transform opacity-0 scale-95"
                  enterTo="transform opacity-100 scale-100"
                  leave="transition ease-in duration-75"
                  leaveFrom="transform opacity-100 scale-100"
                  leaveTo="transform opacity-0 scale-95"
                >
                  <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-gray-900/5 focus:outline-none divide-y divide-gray-100">
                  {user.state !== 'uninitialised' ? <>
                <div className="px-1 py-1">
                  <Menu.Item>
                    <Link href="/settings/profile" className="px-3 py-1 text-gray-900 hover:text-black hover:bg-gray-100 group flex w-full items-center rounded-md px-2 py-2 text-sm" role="menuitem" tabIndex={-1} id="user-menu-item-0" >
                      <UserIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Profile
                    </Link>
                  </Menu.Item>
                  <Menu.Item>
                    <Link href="/settings" className="px-3 py-1 text-gray-900 hover:text-black hover:bg-gray-100 group flex w-full items-center rounded-md px-2 py-2 text-sm" role="menuitem" tabIndex={-1} id="user-menu-item-0" >
                    <Cog8ToothIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Settings
                    </Link>
                   </Menu.Item>
                  <Menu.Item>
                    <Link href="/support" className="px-3 py-1 text-gray-900 hover:text-black hover:bg-gray-100 group flex w-full items-center rounded-md px-2 py-2 text-sm" role="menuitem" tabIndex={-1} id="user-menu-item-0" >
                    <HeartIcon
                        className="mr-2 h-5 w-5"
                        aria-hidden="true"
                      />
                      Support
                    </Link>
                   </Menu.Item>
                   </div>
                  <div className="px-1 py-1">
                    <Menu.Item>
                      <button className="px-3 py-1 text-gray-900 hover:text-black hover:bg-gray-100 group flex w-full items-center rounded-md px-2 py-2 text-sm" role="menuitem" tabIndex={-1} id="user-menu-item-1" onClick={() => user.logout()}>
                        <ArrowRightOnRectangleIcon
                          className="mr-2 h-5 w-5"
                          aria-hidden="true"
                        />
                        Sign out
                      </button>
                    </Menu.Item>
                  </div></> : <>
                      <div className="px-1 py-1">
                        <Menu.Item>
                          <Link href="/signin" className="px-3 py-1 text-gray-900 hover:text-black hover:bg-gray-100 group flex w-full items-center rounded-md px-2 py-2 text-sm" role="menuitem" tabIndex={-1} id="user-menu-item-0" >
                            <ArrowLeftOnRectangleIcon
                            className="mr-2 h-5 w-5"
                            aria-hidden="true"
                          />
                            Log in
                            </Link>
                        </Menu.Item>
                      </div>
                    </>
                  }
                </Menu.Items>
              </Transition>
              </Menu>
            </div>
          </div>
        </div>

        <main className="py-10 bg-white min-h-screen">
          <div className="px-4 sm:px-6 lg:px-8">
            {children}
          </div>
        </main>
      </div>
    </div>
    </body>
  </html>
}
