"use client"
import React, { useState, useEffect, useMemo, useContext, createContext, PropsWithChildren } from "react";
import { buildInfo } from "../../BuildInfo";
import { LocalStorage, createGUID, nameof } from "./Utils";
import { DetailedUser, createEpicPenLicencingAPI, createFakeUser } from "./WebAPI";
import auth0, { Auth0DecodedHash, LibErrorCodes, SpecErrorCodes } from 'auth0-js';
import { jwtDecode } from "jwt-decode";

import { usePathname, useRouter } from 'next/navigation'
import { $Enums, CountryCode, LanguageCode, UserPermission } from "@prisma/client";
import { RumInitConfiguration, datadogRum as datadogRumOriginal } from "@datadog/browser-rum";
import mixpanel from 'mixpanel-browser';
import { users } from "src/common/data";


type NewDataDog = typeof datadogRumOriginal &  {
    setTrackingConsent? : (trackingConcent : "not-granted" | "granted") => void
    init : (initConfiguration : RumInitConfiguration & {trackingConsent : "granted" | "not-granted"}) => void
}

const datadogRum : NewDataDog = datadogRumOriginal as any;

const datadogInit = (trackingConsent : "granted" | "not-granted") => {
    datadogRum.init({
        applicationId: '4cfae08f-58d6-40af-bf48-abf22eb27bb8',
        clientToken: 'pub22b8cd84fdd1a5a14acc9e410190b538',
        site: 'datadoghq.eu',
        service: 'tokyo-web-application',
        env: `${buildInfo.runtimeEnvironment.environment}-${buildInfo.channel.trunk}`,
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        version: buildInfo.source.gitCommitHash,
        sessionSampleRate: 100,
        sessionReplaySampleRate: 20,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input",
        proxy: (options) => `https://rum.tokyo.tankstudios.ltd${options.path}?${options.parameters}`,
        allowedTracingUrls: [
            "https://service.tokyo.tankstudios.ltd"
        ],
        trackingConsent: trackingConsent
        // Specify URLs to propagate trace headers for connection between RUM and backend trace
        //allowedTracingUrls: [
        //  { match: "https://example.com/api/", propagatorTypes: ["tracecontext"] },
        //],
    });
}

if (true) {
    mixpanel.init("83be4c7f934b1d32de699f064a940036", {
        debug: true,
        track_pageview: true,        
        opt_out_tracking_by_default: true,
        api_host: 'https://mixpanel.tokyo.tankstudios.ltd',
    });

    mixpanel.register({ 
        version: buildInfo.source.gitCommitHash,
        env : `${buildInfo.runtimeEnvironment.environment}-${buildInfo.channel.trunk}`,
    });


    if ((datadogRum as any).setTrackingConsent) {
        datadogInit("not-granted");
    } else {
    }

}



type EPAuth0User = {sub : string} & {ep_user_id : string}

export type EPTUser = ({
    state : "uninitialised"
    countryCode : CountryCode
    language : LanguageCode
  } | {
    state : "loggedIn"
    countryCode : CountryCode
    language : LanguageCode
    mode : "live" | "dummy"
    hasEPUser : false
    epUserId : string
    api : ReturnType<typeof createEpicPenLicencingAPI>
    logout : () => void
  } | {
    state : "loggedIn",
    mode : "live" | "dummy"
    hasEPUser : true
    epUserId : string
    api : ReturnType<typeof createEpicPenLicencingAPI>
    epUser : DetailedUser & {loadedFromCache : boolean}
    logout : () => void
  }) & {
    reload : () => void
  }

  type TokenCallback = {
    accessToken: string
    refreshToken:string
    scope: string
    expiresIn: number
    tokenType: "Bearer"
}

  export const logIn = (email : string, password : string) => {
    return new Promise<{status : "success"} | {status : "failure", errorCode : LibErrorCodes | SpecErrorCodes | null, description : string}>((resolve, reject) => {
      const webAuth = new auth0.WebAuth({
        clientID: auth0Info.clientId,
        domain: auth0Info.domain,
        audience: auth0AuthorisationParams.audience,
        responseMode: "fragment",
        scope: "offline_access",
      });
  
      const loginState = createGUID();
      const loginNonce = createGUID();
      LocalStorage.setItem("loginState", {value: loginState});
      LocalStorage.setItem("loginNonce", {value: loginNonce});
  
      webAuth.login ({
        responseType: auth0AuthorisationParams.responseType,
        email: email,
        password: password,
        redirectUri: auth0AuthorisationParams.redirect_uri,
        state: loginState,
        nonce: loginNonce,
        realm: "Username-Password-Authentication"
      }, (error, result) => {
        if (error) {
          resolve({
            status: "failure",
            errorCode: error.code as LibErrorCodes | SpecErrorCodes | null,
            description: error.error_description ?? ""
          });
        } else {
          resolve({
            status: "success"
          });
        }
      })
    });
  }

  export type SignUpDetails = {
    email : string, 
    password : string, 
    firstName : string, 
    lastName : string, 
    username : string,
    usernameSetByUser : boolean,
    company : string, 
    companyId : string,
    sector : $Enums.MarketResearch_Sector,
    organisationType : $Enums.MarketResearch_OrgnisationType,
    employeeCount : $Enums.MarketResearch_EmployeeCount,
    intendedUseCase : string,
    productUsageLoggingConsent : UserPermission
  }

  
  export const signUpAndLogIn = (signUpDetails : SignUpDetails) => {
    return new Promise<{status : "success"} | {status : "failure", errorCode : LibErrorCodes | SpecErrorCodes | null, description : string}>((resolve, reject) => {
      const webAuth = new auth0.WebAuth({
        clientID: auth0Info.clientId,
        domain: auth0Info.domain,
        audience: auth0AuthorisationParams.audience,
        responseMode: "fragment",
        scope: "offline_access",
      });
  
      const loginState = createGUID();
      const loginNonce = createGUID();
      LocalStorage.setItem("loginState", {value: loginState});
      LocalStorage.setItem("loginNonce", {value: loginNonce});
      const TT : keyof SignUpDetails = "email";
  
      webAuth.signupAndAuthorize ({
        email: signUpDetails.email,
        username: signUpDetails.username,
        password: signUpDetails.password,
        connection: "Username-Password-Authentication",
        userMetadata : 
            Object.assign(
                {
                    product : `tokyo-${buildInfo.channel.trunk}`
                }, 
                ...(Object.keys(signUpDetails) as (keyof SignUpDetails)[]).filter((key) => (["email", "username", "password", "usernameSetByUser"] satisfies (keyof SignUpDetails)[]).findIndex((val) => val === key) === -1).map((key) => {return { [key] : signUpDetails[key].toString()};}))
        
      }, (error, result? : TokenCallback) => {
        if (error) {
          resolve({
            status: "failure",
            errorCode: error.code as LibErrorCodes | SpecErrorCodes | null,
            description: error.error_description ?? ""
          });
        } else if (result) {
            LocalStorage.setItem("accessTokenAndId", {
                accessToken: result.accessToken,
                id: jwtDecode<EPAuth0User>(result.accessToken).ep_user_id
            })
            resolve({
                status: "success"
            });
        }
      })
    });
  }


//@ts-ignore
const EpicPenContext = createContext<EPTUser>();

export const auth0AuthorisationParams = {
    redirect_uri: buildInfo.runtimeEnvironment.isDevMachine ? "https://localhost:3001/" : buildInfo.product.name === 'epicpen' ? `https://${buildInfo.channel.isProduction ? "" : "dev."}accounts.epicpen.com/` :  buildInfo.product.name === 'tokyo' ?  `https://${buildInfo.channel.trunk}.webapp.tokyo.tankstudios.ltd/` : "",
    audience: 'https://licencing.epicpen.com/',
    scope: 'read:current_user',
    responseType: "token id_token",
} 

const useProductionTenant = true;
export const auth0Info = {
    domain : `${useProductionTenant ? "" : "dev."}auth.epicpen.com`,
    clientId : useProductionTenant ? "g2QlbxdBa9WKjhyS2dPZcaVTAfxjADv2" : "brtBtel2fbShRzuI9apGobBcWr0uc8zv"
}


export const getLoginRedirectPathnameMailbox = () => {
    const result = LocalStorage.getItem("loginRedirectPathname");
    LocalStorage.removeItem("loginRedirectPathname");
    return result;
}

function createUser() {
    const defaultLanguage = "en";
    const defaultCountryCode = "US";
    
    const webAuth = new auth0.WebAuth({
        clientID: auth0Info.clientId,
        domain: auth0Info.domain,
        responseMode: "fragment"
      });


    const parseHash = () => {
        if (window.location.hash?.startsWith("#")) {
            const tt = window.location.hash.substring(1).split("&").map((str) => str.split("="))
            const map = new Map(tt as any)
            const obj = Object.fromEntries(map) as {
                access_token: string
                id_token: string
                state: string
            };

            window.location.hash = "";
            return obj.access_token && obj.id_token && obj.state ? obj : null;
        } else {
            return null;
        }
      }; 

    const checkSession = (state : string, nonce : string) => {
        return new Promise<{accessToken : string, state : string, idToken : string}>((resolve, reject) => {
            
            webAuth.checkSession({
                audience: auth0AuthorisationParams.audience,
                scope: auth0AuthorisationParams.scope,
                responseType: auth0AuthorisationParams.responseType,
                redirectUri: auth0AuthorisationParams.redirect_uri,
                state: state,
                nonce: nonce
            }, (error, result : {accessToken : string, state : string, idToken : string}) => {
                if (error) {
                    reject(error);
                } else {
                    resolve(result)
                }
            })
        })
    }

    const validateToken = (token : string | null) => {
        return token && (Date.now() < (jwtDecode(token)?.exp ?? 0) * 1000);
    }

    const reload = () => setUserRefreshToken(() => createGUID())
    const [user, setUser] = useState<EPTUser>({ state : "uninitialised", countryCode : "US", language: "en", reload});

    const productUsageTelemetryPermission = user.state === "loggedIn" && user.hasEPUser && user.epUser.productUsageTelemetryPermission === "granted"

    useEffect(() => {
        if (user.state === "loggedIn" && user.hasEPUser) {
            if (productUsageTelemetryPermission) {
                mixpanel.opt_in_tracking();
                mixpanel.identify(user.epUserId);

                mixpanel.people.set({
                    $email: user.epUser.email,
                    $name: user.epUser.name,
                    $first_name: user.epUser.firstName,
                    $last_name: user.epUser.lastName,
                    $created: user.epUser.meta_creationDateTime,
                    accountCountry : user.epUser.address.countryCode
                });

                if (datadogRum.setTrackingConsent) {
                    datadogRum.setTrackingConsent("granted")
                } else {
                    datadogInit("granted");
                }

                datadogRum.setUser({
                    email: user.epUser.email,
                    id: user.epUserId,
                    name: user.epUser.name,
                });
            } else {
                mixpanel.opt_out_tracking();
                
                if (datadogRum.setTrackingConsent) {
                    datadogRum.setTrackingConsent("not-granted")
                } else {
                }
                //
            }
        }
        
    }, [productUsageTelemetryPermission, user])


    const router = useRouter()
    const pathname = usePathname()

    
    useEffect(() => {
        mixpanel.track_pageview();
    }, [pathname])

    const [userRefreshToken, setUserRefreshToken] = useState(createGUID());

    useEffect(() => {
        (async () => {
            const pathUrl = pathname !== "/signin" && pathname !== "/signup" ? getLoginRedirectPathnameMailbox() : null;

            if (pathUrl?.pathname) {
                router.push(pathUrl.pathname);
            }

            const tryGetAccessTokenAndId = async () => {
                const loginState = LocalStorage.getItem("loginState")
                const loginNonce = LocalStorage.getItem("loginNonce")
                const sessionStorageAccessTokenAndId = LocalStorage.getItem("accessTokenAndId"); 
                
                const parsedHash = (loginState && loginNonce) ? parseHash() : null;
                
                if (parsedHash && parsedHash.state === loginState?.value && (Date.now() < (jwtDecode(parsedHash.access_token)?.exp ?? 0) * 1000) && (jwtDecode(parsedHash.id_token) as {nonce : string}).nonce === loginNonce?.value) {
                    const accessTokenAndId = {accessToken: parsedHash.access_token, id: jwtDecode<{ep_user_id : string}>(parsedHash.id_token).ep_user_id}
                    
                    LocalStorage.setItem("accessTokenAndId", accessTokenAndId);
                    console.log("tryGetAccessToken got from location hash")
                    console.log(`expires: ${new Date((jwtDecode(parsedHash.access_token)?.exp ?? 0) * 1000).toISOString()}`)
                    return accessTokenAndId;
                } else if (sessionStorageAccessTokenAndId && validateToken(sessionStorageAccessTokenAndId.accessToken)) {
                    console.log("tryGetAccessToken got from session storage")
                    console.log(`expires: ${new Date((jwtDecode(sessionStorageAccessTokenAndId!.accessToken)?.exp ?? 0) * 1000).toISOString()}`)
                    return sessionStorageAccessTokenAndId;
                } else {
                    console.log("tryGetAccessToken failed")
                    console.log(sessionStorageAccessTokenAndId)
                    if (pathname !== "/signin" && pathname !== "/signup") {
                        LocalStorage.setItem("loginRedirectPathname", {pathname : window.location.pathname})
                        router.push("/signin")
                    }
                    return null;
                }
            }

            const logout = () => {
                LocalStorage.removeItem("accessTokenAndId");
                LocalStorage.removeItem("loginRedirectPathname");
                router.push("/signin")
            }


            const accessTokenAndId = await tryGetAccessTokenAndId();
            if (accessTokenAndId){
                //const accessTokenPayload = jwtDecode<{ep_user_id : string}>(accessTokenAndId.accessToken);
                //console.log("parsedHashResult.accessTokenPayload")
                //console.log(accessTokenPayload)
                //console.log(accessTokenPayload.ep_user_id);

                const getAccessToken = (() => {
                    var accessTokenUpdatable = accessTokenAndId.accessToken;

                    return async() => {
                        const checkSessionState = createGUID()
                        const checkSessionNonce = createGUID()

                        if (validateToken(accessTokenUpdatable)) {
                            return accessTokenUpdatable;
                        } else {
                            const authResult = await checkSession(checkSessionState, checkSessionNonce);
                            if (authResult.state === checkSessionState && jwtDecode<{nonce : string}>(authResult.idToken).nonce === checkSessionNonce) {
                                accessTokenUpdatable = authResult.accessToken;
                                return accessTokenUpdatable;
                            } else {
                                throw "Error getting new access token"
                            }
                        }
                    }
                })();
                const epicPenLicencingAPI = createEpicPenLicencingAPI(accessTokenAndId.id, getAccessToken, false);
                
                const currentUserCacheStorageKey = `${buildInfo.source.gitCommitHash}.${buildInfo.channel}.cache.currentUser`;
                setUser((userState) => {
                    const cachedUser = LocalStorage.getItem("cache-currentUser");
                    if (!(userState.state === "loggedIn" && userState.hasEPUser && userState.epUser.loadedFromCache)) {
                        return userState;
                    } else {
                        return cachedUser && !(userState.state === "loggedIn" && userState.hasEPUser && userState.epUser.loadedFromCache) ? {
                            state : "loggedIn",
                            mode: "live",
                            countryCode: defaultCountryCode,
                            language: defaultLanguage,
                            hasEPUser: true,
                            epUser : Object.assign({}, cachedUser, {loadedFromCache : true}),
                            epUserId: accessTokenAndId.id,
                            api: epicPenLicencingAPI,
                            logout: logout,
                            reload: reload
                        } : {
                            state : "loggedIn",
                            mode: "live",
                            countryCode: defaultCountryCode,
                            language: defaultLanguage,
                            hasEPUser: false,
                            epUser : null,
                            epUserId: accessTokenAndId.id,
                            api: epicPenLicencingAPI,
                            logout: logout,
                            reload: reload
                            }
                    }
                });
                
                const user = (await epicPenLicencingAPI.getCurrentUser());
                LocalStorage.setItem("cache-currentUser", user);
                //console.log("epicPenLicencingAPI.getCurrentUser()")
                //console.log(user)
                setUser((userState) => {
                    return {
                        state : "loggedIn",
                        mode: "live",
                        countryCode: defaultCountryCode,
                        language: defaultLanguage,
                        hasEPUser: true,
                        epUser : Object.assign({}, user, {loadedFromCache : false}),
                        epUserId: accessTokenAndId.id,
                        api: epicPenLicencingAPI,
                        logout: logout,
                        reload: reload
                        }
                });
            } else {
                console.log("no access token")
            }
        })();
    }, [userRefreshToken])

    return user;
}


const EpicPenProvider = (props : PropsWithChildren<{}>) => {

    const EpicPenInnerProvider = (props : PropsWithChildren<{}>) => {
        const user = createUser()
        return <EpicPenContext.Provider value={user}>
            {props.children}
        </EpicPenContext.Provider>
    }

    return <EpicPenInnerProvider>
        {props.children}
    </EpicPenInnerProvider>
}


function useUser() {
    const user = useContext(EpicPenContext);
    return user;
}

const getUserLocale = (user : EPTUser) => {
    if (user.state === "uninitialised" || (user.state === "loggedIn" && user.hasEPUser === false)) {
        return `${user.language}-${user.countryCode}`;
    } else {
        return `${user.epUser.language}-${user.epUser.address.countryCode}`;
    }
}
  
export {
    EpicPenContext,
    EpicPenProvider,
    useUser,
    getUserLocale
}