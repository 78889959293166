"use client"
import React, { useRef } from 'react';
import {  useState, useEffect } from 'react';

import { setInterval, clearInterval } from 'timers';
import { ButtonAction, ToolbarOverlay } from './ToolbarOverlay';
import mixpanel from 'mixpanel-browser';
import { TokyoDesktopElementClickSolidPortMessage, TokyoDesktopElementClickThroughPortMessage, TokyoDesktopElementController, TokyoDesktopElementViewModel } from './TokyoDesktopElement';
import { stat } from 'fs';
import { User } from './utils/WebAPI';

export const createGUID = (): string => {
    //@ts-ignore
    return crypto.randomUUID ? crypto.randomUUID() : ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
        (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    );
}
 

export const DefaultDesktopSize : Size = {
    width: 800,
    height: 600
}

export type CursorState = {
    state: "onscreen", 
    x : number, 
    y : number, 
    pressed: boolean,
    color : TokyoDesktopElementViewModel.Color,
    timestamp: number
} | {
        state: "offscreen", 
        timestamp: number
    }

export type OverlayMode = "clickSolid" | "clickThrough"

export type Size = {
    width : number,
    height: number
}

type RemoteStreamMetaData = {streamId : string, streamType : "webcam" | "desktop"}


export type CursorInfoDataChannelMessage = 
{type : "desktopViewLineSegment", data : LineSegment}
| {type : "desktopViewCursorState", data : CursorState}


export type GeneralDataChannelMessage = 
{type : "echo", data : {message : string}}
| {type : "ping", data : {id : string}}
| {type : "pong", data : {id : string}}
| {type : "identityAndProperties", data : {id : string, desktopSize : Size | null}}
| {type : "streamMetadata", streamMetadata : RemoteStreamMetaData}

export type RTCConnectionStatus = 
{
    code: "pendingSignal" | "connecting" | "initialisingConnection" | "disconnected"
}
| {
    code : "connected"
    peerId : string
    connectionType : "host" | "srflx" | "prflx" | "relay" | null
}
export type LineSegment = {
    x1: number, 
    y1 : number, 
    x2: number, 
    y2: number, 
    thickness : number,
    color : TokyoDesktopElementViewModel.Color,
    id : string
    fadePeriod : number,
    strokeId : string
}

export type Stroke = {
    lineSegments : LineSegment[],
    id : string
}

export type CallStatus = {
    type : "initialising"
} | {
    type : "pendingConnection"
    sessionId : string
} | {
    type : "connected"
    sessionId : string
    remoteUserId : string
}| {
    type : "disconnected"
}


type LocalMicMediaSteamBase<T extends string> = {
    state : "muted"
} | {
    state : "unmutedPendingPermission",
    deviceId : string | null
} | Omit<{
    state : "unmuted"
    mediaStream : MediaStream
    disposeAudioStream : () => void
    addAudioLevelListener : (listener : (level : number) => void) => void
    deviceId : string
}, T>

export type LocalMicMediaStream = LocalMicMediaSteamBase<"">
export type LocalMicStatus = LocalMicMediaSteamBase<"mediaStream" | "disposeAudioStream" | "addAudioLevelListener">
export type RemoteMicStatus = LocalMicMediaSteamBase<"mediaStream" | "disposeAudioStream" | "addAudioLevelListener" | "deviceId">

type LocalWebcamMediaStreamBase<T extends string> = {
    state: "notShared"
} | {
    state: "sharedPendingPermission",
    deviceId : string | null
} | Omit<{
    state: "shared"
    mediaStream : MediaStream
    disposeVideoStream : () => void
    deviceId : string
}, T>

export type LocalWebcamMediaStream = LocalWebcamMediaStreamBase<"">
export type LocalWebcamStatus = LocalWebcamMediaStreamBase<"mediaStream" | "disposeVideoStream">

export type RemoteWebcamStatus = LocalWebcamMediaStreamBase<"mediaStream" | "disposeVideoStream" | "deviceId">//"shared" | "notShared"



export type MediaDeviceDetailedInfo = {
    state : "initialising"
} | {
    state : "loaded"
    deviceInfo : MediaDeviceInfo[]
}

export type TokyoConnectionMediaElements = {
    desktopVideoMediaStream : MediaStream | null
    localWebcamVideoMediaStream : MediaStream | null
    remoteWebcamVideoMediaStream : MediaStream | null
    remoteAudioMediaStream : MediaStream | null
}

export type TokyoConnectionController = ({
    status : "initialising"
    mediaElements : TokyoConnectionMediaElements
} | {
    status : "pendingConnection",
    localUserId : string,
    sessionId : string
    mediaElements : TokyoConnectionMediaElements
} | {
    status : "connected",
    localUserId : string,
    sessionId : string
    remoteUserId : string
    //desktopViewCursorState  : CursorState
    remoteDesktopViewCursorState : CursorState
    //desktopViewUIScale : number
    desktopSize : Size
    remoteDesktopViewStrokes : Stroke[]
    rtcInfo : {
        websocketStatus : "close" | "open" | null
        rtcConnectionStatus : RTCPeerConnectionState | null
        rtcICEConnectionStatus : RTCIceConnectionState | null
        rtcSignalingStatus : RTCSignalingState | null
        rtcConnectionType : "host" | "srflx" | "prflx" | "relay" | null
    }
    //recentPingTimes : number[]
    sendCursorInfo : (cursorInfo : CursorInfoDataChannelMessage) => void
    sendDesktopViewLineSegment : (lineSegment : LineSegment) => void
    sendDesktopViewCursorState : (cursorState : CursorState) => void
    //renegotiateConnection : () => void
    //sendDesktopViewUIScale : (uiScale : number) => void
    mediaElements : TokyoConnectionMediaElements
    localMicStatus : LocalMicStatus
    remoteMicStatus : RemoteMicStatus
    localWebcamStatus : LocalWebcamStatus
    remoteWebcamStatus : RemoteWebcamStatus
    requestEndCall : () => void
}) & {
    setLocalWebcamMediaStream : (localWebcamMediaStream : LocalWebcamMediaStream) => void
    setLocalMicMediaStream: (localMicMediaStream : LocalMicMediaStream) => void
}

type SignallingMessage =
    {type: "offer" | "answer", data : {callSessionId : string, sessionDescription : RTCSessionDescriptionInit}} 
    | {type: "candidate", data : {callSessionId : string, candidateInit : RTCIceCandidateInit} }
    | {type: "ping"}
    | {type : "generalDataChannelMessage", data : GeneralDataChannelMessage}
    | {type : "cursorInfoDataChannelMessage", data : CursorInfoDataChannelMessage}

type AzurePubSubMessage = {
    "type": "sendToGroup" | "message" | "system",
    "group": string,
    "ackId" : number,
    "noEcho": boolean,
    "dataType" : "json" //| "text" | "binary",
    "data": SignallingMessage//, // data can be string or valid json token depending on the dataType 
}


const createAckId = (() => {
    var akIdCount = 1;
    return () => {
        akIdCount = akIdCount + 1;
        return akIdCount;
    }
})();

type TokyoTurnResp = {iceServers : RTCIceServer[], expire : number}

type PubsubTokenResp = {clientAccessUri : string}

type TokyoRTCDataChannel = RTCDataChannel & {label : "general" | "cursor-info"}

type TokyoUser = {
    type : "signedIn",
    user : User
} | {
    type : "anonymousDevice",
    deviceId : string
}

const TokyoUserToId = (user : TokyoUser) => {
    if (user.type === 'anonymousDevice') {
        return `anonymous-device-${user.deviceId}`
    } else if (user.type === 'signedIn')  {
        return `${user.user.id}`
    }
}

export const useTokyoConnection = (props : {
    callSessionId? : string, 
    screenInfo? : {id : string, displayId: string, name: string, width : number, height : number, uiScale : number}, 
    createSessionOffer? : boolean, 
    user : TokyoUser | null,
    getClickSolidMessageHandler : () => ((message : TokyoDesktopElementClickSolidPortMessage) => void),
    setMessageHandler : (handler : (message : TokyoDesktopElementClickThroughPortMessage) => void) => void,
    getMessageHandler : () => ((message : TokyoDesktopElementClickThroughPortMessage) => void)
    handleCallEndRequest : () => void
}) => {
  const [initStarted, setInitStarted] = useState<boolean>(false);

  
  const [state, setState] = useState<{
    state : "uninitialied"
} | {
    state : "hasPeerConnection",
    peerConnection : RTCPeerConnection
    createOffer: () => Promise<RTCSessionDescriptionInit>
    createAnswer: () => Promise<RTCSessionDescriptionInit>
    createAndSendOffer: () => Promise<void>
    generalDataChannelSend : ((message : GeneralDataChannelMessage) => void) | null
}>({state : "uninitialied"});

  const [websocketStatus, setWebsocketStatus] = useState<"close" | "open" | null>(null);
  const [rtcConnectionStatus, setRtcConnectionStatus] = useState<RTCPeerConnectionState | null>(null);
  const [rtcICEConnectionStatus, setRtcICEConnectionStatus] = useState<RTCIceConnectionState | null>(null);
  const [rtcSignalingStatus, setRtcSignalingStatus] = useState<RTCSignalingState | null>(null);
  const [rtcConnectionType, setRtcConnectionType] = useState<"host" | "srflx" | "prflx" | "relay" | null>(null);
  const [recentPingTimes, setRecentPingTImes] = useState<number[]>([]);
  //const forceLoadACS = useMemo(() => import('@azure/communication-calling'), []);

  const [desktopViewStrokes, setDesktopStrokes] = useState<Stroke[]>([])

  const [localWebcamMediaStream, setLocalWebcamMediaStream] = useState<LocalWebcamMediaStream>({state: "notShared"})
  const [localMicMediaStream, setLocalMicMediaStream] = useState<LocalMicMediaStream>({state: "muted"})

  const [desktopViewCursorState, setDesktopViewCursorState] = useState<CursorState>({state: "offscreen", timestamp: Date.now()})
  const [remoteDesktopViewCursorState, setRemoteDesktopViewCursorState] = useState<CursorState>({state: "offscreen", timestamp: Date.now()})

  //const [localMicStatus, setLocalMicStatus] = useState<LocalMicStatus>({state: "muted"});
  const localMicStatus = localMicMediaStream;
  const [remoteMicStatus, setRemoteMicStatus] = useState<RemoteMicStatus>({state: "muted"});
  //const [localWebcamStatus, setLocalWebcamStatus] = useState<LocalWebcamStatus>({state: "notShared"});
  const localWebcamStatus = localWebcamMediaStream;
   
  const [remoteWebcamStatus, setRemoteWebcamStatus] = useState<RemoteWebcamStatus>({state: "notShared"});
  
  
  
  useEffect(() => {
    (async () => {
        const abortController = new AbortController();
        const callSessionId = props.callSessionId ?? createGUID()

        const newlocalUserId = createGUID();

        const createSessionOffer = Boolean(props.createSessionOffer);
        
        let desktopVideoStream : MediaStream | null = null;
        const shareScreen = true;
        if (createSessionOffer && props.screenInfo?.id && shareScreen) {
            desktopVideoStream = await navigator.mediaDevices.getUserMedia({
                audio: false,
                video: {
                    //@ts-ignore
                    mandatory: {
                        chromeMediaSource: 'desktop',
                        chromeMediaSourceId: props.screenInfo.id,
                        minWidth: Math.min(1280, props.screenInfo.width),
                        maxWidth:  props.screenInfo.width,
                        minHeight: Math.min(720, props.screenInfo.height),
                        maxHeight:  props.screenInfo.height,
                    }
                }
            });
        }
        
        setConnectionController((controller) => {
            return {
                status: 'pendingConnection',
                localUserId: newlocalUserId,
                sessionId: callSessionId,
                mediaElements : controller.mediaElements,
                setLocalWebcamMediaStream,
                setLocalMicMediaStream
            }
        })

        const turnResponse : TokyoTurnResp = await (await fetch(`https://licencing.epicpen.com/tokyo/turn?sessionId=${callSessionId}`)).json();
        const peerConnection = new RTCPeerConnection({
            iceServers : turnResponse.iceServers,
            iceTransportPolicy: "relay"
        });
    
        
        const updateTURNServers = async () => {
            const turnResponse : TokyoTurnResp = await (await fetch(`https://licencing.epicpen.com/tokyo/turn?sessionId=${callSessionId}`)).json();
            if (peerConnection.connectionState !== "connected" && peerConnection.connectionState !== "closed") {
                peerConnection.setConfiguration({
                    iceServers : turnResponse.iceServers,
                    iceTransportPolicy: "relay"
                });
                
                setTimeout(() => {
                    updateTURNServers()
                }, (turnResponse.expire / 2) * 1000);
            }
        };
    
        setTimeout(async () => {
            await updateTURNServers()
        }, (turnResponse.expire / 2) * 1000)

        const signallingWebSocket = await (async () => {
            const pubsubTokenResponse : PubsubTokenResp = await fetch(`https://licencing.epicpen.com/tokyo/pubsub/token?sessionId=${callSessionId}`).then((result) => result.json());;
            const azureWebSocket = new WebSocket(pubsubTokenResponse.clientAccessUri, "json.webpubsub.azure.v1")

            const awaitInitialOpen = new Promise<void>(async (resolve, reject) => {
                azureWebSocket.addEventListener("open", () => {
                    resolve()
                })
            });

            const send = (message : SignallingMessage) =>  {
                const azureWebSocketSend = (message : AzurePubSubMessage) =>  signallingWebSocket.azureWebSocket.send(JSON.stringify(message))
                azureWebSocketSend({
                    type: "sendToGroup",
                    ackId: createAckId(),
                    data: message,
                    dataType: 'json',
                    group: callSessionId,
                    noEcho: true
                });
            }

            const keepAlive = () => {
                const interval = setInterval(function () {
                    if (azureWebSocket.readyState === azureWebSocket.OPEN) {
                        signallingWebSocket.send({
                            type: "ping"
                        });
                    }
                }, 800, { signal: abortController.signal });
                abortController.signal.addEventListener("abort", () => clearInterval(interval))
            };

            azureWebSocket.addEventListener("open", async evt => { 
                keepAlive();
            });
            azureWebSocket.addEventListener("close", () => {
                console.log("azureWebSocket: close")
            });
            azureWebSocket.addEventListener("error", (ev) => {
                console.log(`azureWebSocket: error: ${ev}`)
            });
            
            return {
                azureWebSocket,
                awaitInitialOpen,
                onMessage : (handler : (message : SignallingMessage) => void) => {
                    azureWebSocket.addEventListener("message", async (msg : MessageEvent<string>) => {
                        const signalMessage = (() => {
                            let message = JSON.parse(msg.data) as AzurePubSubMessage
                            if (message.type === "message") {
                                return message.data;
                            } else {
                                return null;
                            }
                        })();
                        if (signalMessage) {
                            handler(signalMessage)
                        }
                    });
                },
                send 
            }
        })();

        const createAnswer = async () => {
            const answer = await peerConnection.createAnswer();
            peerConnection.setLocalDescription(answer);
            return answer
        }
        
        signallingWebSocket.azureWebSocket.addEventListener("open", () => setWebsocketStatus("open"));
        signallingWebSocket.azureWebSocket.addEventListener("close", async evt => { 
            console.log("RTC Signalling Webhook: CLOSE")
            setWebsocketStatus("close")
        });
        
        
        signallingWebSocket.onMessage(async (message) => {
            console.log("signallingWebSocket.onMessage")
            console.log(message)
            switch(message.type) {
                case "offer":
                    console.log("RTC Signalling: OFFER RECEIVED")

                    const offerDesc = new RTCSessionDescription(message.data.sessionDescription);
                    peerConnection.setRemoteDescription(offerDesc);
                    const answer = await createAnswer();
                    console.log("RTC Signalling: ANSWER SENT")
                    signallingWebSocket.send({
                        type: "answer",
                        data : { 
                            callSessionId: callSessionId,
                            sessionDescription : answer
                        }
                    });
                    break;
                case "answer":
                    console.log("RTC Signalling: ANSWER RECEIVED")
                    if (message.data.callSessionId === callSessionId) {
                        const answerDesc = new RTCSessionDescription(message.data.sessionDescription);
                        peerConnection.setRemoteDescription(answerDesc);
                    }
                    break;
                case "candidate":
                    if (message.data.callSessionId === callSessionId) {
                        console.log("RTC Signalling: CANDIDATE RECEIVED")
                        const candidate = new RTCIceCandidate(message.data.candidateInit);
                        console.log(candidate);
                        peerConnection.addIceCandidate(candidate);
                    }
                    break;
            }
        });

        const createOffer = async () => {
            const offer = await peerConnection.createOffer()
            peerConnection.setLocalDescription(offer);
            return offer
        }

        const createAndSendOffer = async () => {
            const rtcSessionDescription = await createOffer()
            console.log("RTC Signalling: OFFER SENT")
            signallingWebSocket.send({
                type: "offer",
                data : { 
                    callSessionId: callSessionId,
                    sessionDescription: rtcSessionDescription
                }
            });
            
        }

        (async () => {
            if (createSessionOffer){
                await signallingWebSocket.awaitInitialOpen;
                console.log("RTC Signalling Webhook: OPEN")
                await createAndSendOffer();
            }
        })();
        
        
        abortController.signal.addEventListener("abort", async () => peerConnection.close());
        

        const initDataChannel = (dataChannel : TokyoRTCDataChannel) => {
                    
            if (dataChannel.label === "general") {
                generalDataChannelSend = (message : GeneralDataChannelMessage) => dataChannel.send(JSON.stringify(message))
            } else if (dataChannel.label === "cursor-info") {
                cursorInfoDataChannelSend = (message : CursorInfoDataChannelMessage) => dataChannel.send(JSON.stringify(message))
            }
                
            setState({
                state : "hasPeerConnection",
                peerConnection,
                createOffer,
                createAndSendOffer,
                createAnswer,
                generalDataChannelSend
            })
            abortController.signal.addEventListener("abort", () => dataChannel.close());
            //dataChannelRef.current = localDataChannel
            (window as any).keepalive = dataChannel;
            dataChannel.addEventListener("open", (ev) => {
                //setDataChannelStatus({status: "open", id: dc.id})
                    
                if (dataChannel.label === "general") {
                    setTimeout(() => {
                        if (dataChannel.readyState === 'open'){
                            console.log(`identify sent: ${newlocalUserId}`)
                            
                            generalDataChannelSend!({
                                type: "identityAndProperties",
                                data: {
                                    id: newlocalUserId,
                                    desktopSize : createSessionOffer ? {
                                        width:  props.screenInfo!.width,
                                        height:  props.screenInfo!.height
                                    } : null
                                }
                            });
                            

                        } else{
                            throw new Error("dataChannel not ready")
                        }
                    }, 1000);
                }
            })

            dataChannel.addEventListener("message", (ev : MessageEvent<string>) => {
                if (cursorInfoDataChannelSend && generalDataChannelSend) {
                    if (dataChannel.label === "cursor-info") {
                        const msg = JSON.parse(ev.data) as CursorInfoDataChannelMessage
                        switch(msg.type) {
                            case "desktopViewLineSegment":
                                addDesktopViewLineSegment(msg.data)
                                break;
                            case "desktopViewCursorState":
                                setRemoteDesktopViewCursorState(msg.data)
                                break;
                        }
                    } else if (dataChannel.label === "general") {
                        const msg = JSON.parse(ev.data) as GeneralDataChannelMessage
                        switch(true) {
                            case msg.type === "echo":
                                console.log("dataChannel onmessage ev.data: " + msg.data.message);
                                break;
                            case msg.type === "ping":
                                generalDataChannelSend({type : "pong", data: {id: msg.data.id}})
                                break;
                            case msg.type === "pong":
                                const time = Date.now() - pingDict[msg.data.id];
                                console.log(`pong: milliseconds: ${time}`)
                                //setRecentPingTImes((recentPingTimes) => recentPingTimes.concat([time]).slice(Math.max(recentPingTimes.length + 1 - 30, 0), Math.min(30, recentPingTimes.length + 1) ))
                                
                                //setConnectionController((controller) => {
                                //    if (controller.status === 'connected') {
                                //        return Object.assign({}, controller, {
                                //            recentPingTimes: controller.recentPingTimes.concat([time]).slice(Math.max(controller.recentPingTimes.length + 1 - 30, 0), Math.min(30, controller.recentPingTimes.length + 1)) 
                                //        });
                                //    } else {
                                //        return controller;
                                //    }
                                //});
                                break;
                            case msg.type === "streamMetadata":
                                if (remoteStreamInfoPromises[msg.streamMetadata.streamId]) {
                                    remoteStreamInfoPromises[msg.streamMetadata.streamId](msg.streamMetadata)
                                } else {
                                    remoteStreamInfo[msg.streamMetadata.streamId] = msg.streamMetadata
                                }

                                break;
                            case msg.type === "identityAndProperties":
                                console.log(`identify received: ${msg.data.id}`)
                                //setConnectedRTCPeerId(msg.data.id)
                                setConnectionController((controller) => {
                                    return {
                                        status: 'connected',
                                        desktopSize: msg.data.desktopSize ?? {
                                            width:  props.screenInfo!.width,
                                            height:  props.screenInfo!.height
                                        },
                                        mediaElements: controller.mediaElements,
                                        remoteDesktopViewStrokes: desktopViewStrokes,
                                        desktopViewUIScale: desktopViewUIScale,
                                        desktopViewCursorState: desktopViewCursorState,
                                        localUserId: newlocalUserId,
                                        recentPingTimes: recentPingTimes,
                                        remoteDesktopViewCursorState: remoteDesktopViewCursorState,
                                        remoteUserId: msg.data.id,
                                        rtcInfo: {
                                            rtcConnectionStatus: rtcConnectionStatus,
                                            rtcConnectionType: rtcConnectionType,
                                            rtcICEConnectionStatus: rtcICEConnectionStatus,
                                            rtcSignalingStatus: rtcSignalingStatus,
                                            websocketStatus: websocketStatus
                                        },
                                        sessionId: callSessionId,
                                        sendCursorInfo: (cursorInfo) => {
                                            cursorInfoDataChannelSend!(cursorInfo)
                                        },
                                        sendDesktopViewCursorState: (cursorState) => {
                                            setDesktopViewCursorState(cursorState);
                                            cursorInfoDataChannelSend!(
                                                {
                                                    type: 'desktopViewCursorState',
                                                    data: cursorState
                                                }
                                            )
                                        },
                                        sendDesktopViewLineSegment: (lineSegemnt) => {
                                            addDesktopViewLineSegment(lineSegemnt);
                                            cursorInfoDataChannelSend!(
                                                {
                                                    type: 'desktopViewLineSegment',
                                                    data: lineSegemnt
                                                }
                                            )
                                        },
                                        localMicStatus,
                                        remoteMicStatus,
                                        localWebcamStatus,
                                        remoteWebcamStatus,
                                        requestEndCall : props.handleCallEndRequest,
                                        setLocalWebcamMediaStream,
                                        setLocalMicMediaStream
                                        //sendDesktopViewUIScale: (uiScale) => {setDesktopViewUIScale(uiScale)}
                                    };
                                })
                                break;
                            default:
                                throw new Error(msg satisfies never)
                            }
                        }
                    }
            });
            dataChannel.addEventListener("closing", () => {
                //restartWebRTC();
            });
            dataChannel.addEventListener("close", () => {
                //setDataChannelStatus({status: "close"})
            });
            if (dataChannel.label === "general") {
                const ping = () => {
                    const pingId = createGUID();
                    pingDict[pingId] = Date.now();
                    if (generalDataChannelSend) {
                        generalDataChannelSend({type : "ping", data: {id: pingId}} )
                    }
                }

                const pingInterval = setInterval(() => {
                    if (dataChannel.readyState === 'open')
                        ping();
                }, 1000,)
                abortController.signal.addEventListener("abort", () => clearInterval(pingInterval))
            }
        }
    
        let pingDict = {} as {[key: string]: number};
        let generalDataChannelSend : ((message : GeneralDataChannelMessage) => void) | null = null;
        let cursorInfoDataChannelSend : ((message : CursorInfoDataChannelMessage) => void) | null = null;
        
        let remoteStreamInfo : {[id: string]: RemoteStreamMetaData} = {}
        let remoteStreamInfoPromises : {[id: string]: (value : RemoteStreamMetaData) => void} = {}




        if(createSessionOffer && props.screenInfo?.id) {
            const cursorInfoDataChannel = peerConnection.createDataChannel("cursor-info", {id : 0, ordered: false, maxRetransmits: 0}) as TokyoRTCDataChannel;
            const generalDataChannel = peerConnection.createDataChannel("general", {id : 1, ordered: true}) as TokyoRTCDataChannel;
            initDataChannel(cursorInfoDataChannel);
            initDataChannel(generalDataChannel);
            //const controller = new CaptureController();

            if (desktopVideoStream) {
                for (const track of desktopVideoStream.getTracks()) {
                    peerConnection.addTrack(track, desktopVideoStream);

                    let desktopStreamId = desktopVideoStream.id;
                    // ^? 
                    generalDataChannel.addEventListener("open", () => {
                        if (generalDataChannelSend) {
                            generalDataChannelSend({
                                type : 'streamMetadata',
                                streamMetadata : {
                                    streamId : desktopStreamId,
                                    streamType : "desktop"
                                }
                            })
                        }
                    })
                    
                }
                //for (const track of webcamStream?.getTracks() ?? []) {
                //    peerConnection.addTrack(track, webcamStream!);
                //}
            }
        } else {
            //const videoElement = document.createElement('video')
            //connectionController.mediaElements.desktopVideoMediaStream.current!.addEventListener("canplay", () => connectionController.mediaElements.desktopVideoMediaStream.current!.play())
            //connectionController.mediaElements.desktopVideoMediaStream.current!.controls = false;
            peerConnection.addEventListener("datachannel", (ev) => {
                initDataChannel(ev.channel as TokyoRTCDataChannel)
            });
        }

        peerConnection.addEventListener("track", async (ev) => {
            console.log("peerConnection.addEventListener('track') fired")
            const stream = ev.streams[0];
            const videoTrack = stream.getVideoTracks().length > 0 ? stream.getVideoTracks()[0] : null;
            const audioTrack = stream.getAudioTracks().length > 0 ? stream.getAudioTracks()[0] : null;


            const metaData = await new Promise<RemoteStreamMetaData | null>((resolve, reject) => {
                if (videoTrack){
                    const metaData = remoteStreamInfo[stream.id]
                    if (metaData) {
                        return resolve(metaData)
                    } else {
                        remoteStreamInfoPromises[stream.id] = resolve
                    }
                } else {
                    resolve(null);
                }
            })

            if (videoTrack && metaData && metaData.streamType === 'desktop') {
                setConnectionController((controller) => {
                    return Object.assign(
                        {}, 
                        controller, 
                        { 
                            mediaElements: Object.assign({}, controller.mediaElements, { desktopVideoMediaStream : stream } satisfies Partial<TokyoConnectionMediaElements>)
                        } satisfies Partial<TokyoConnectionController>
                    )
                })
            }
            if (videoTrack && metaData && metaData.streamType === 'webcam') {
                setConnectionController((controller) => {
                    return Object.assign(
                        {}, 
                        controller, 
                        { 
                            mediaElements: Object.assign({}, controller.mediaElements, { remoteWebcamVideoMediaStream : stream } satisfies Partial<TokyoConnectionMediaElements>)
                        } satisfies Partial<TokyoConnectionController>
                    )
                })
            }
            if (audioTrack) {
                audioTrack.enabled = true;
                setConnectionController((controller) => {
                    return Object.assign(
                        {}, 
                        controller, 
                        { 
                            mediaElements: Object.assign({}, controller.mediaElements, { remoteAudioMediaStream : stream } satisfies Partial<TokyoConnectionMediaElements>)
                        } satisfies Partial<TokyoConnectionController>
                    )
                })
            }
            //setVideoElement({element : videoElement})
        });

        const forceTURNServersOnly = true
        
        abortController.signal.addEventListener("abort", () => {
            signallingWebSocket.azureWebSocket.close();
        });


        peerConnection.addEventListener("icecandidateerror", (ev) => {
            console.log(`peerConnection.icecandidateerror`)
            console.log(ev)
        });

        peerConnection.addEventListener("icecandidate", (ev) => {
            if (ev.candidate && (forceTURNServersOnly ? ev.candidate.candidate.indexOf("relay") >= 0 : true) ) {
                console.log("peerConnection.onicecandidate")
                console.log(ev.candidate)
                
                console.log("RTC Signalling: CANDIDATE SENT")
                signallingWebSocket.send({
                    type: "candidate",
                    data: {
                        callSessionId: callSessionId,
                        candidateInit : ev.candidate
                    } 
                });
            }
        });

        peerConnection.addEventListener("connectionstatechange", (ev) => {
            setRtcConnectionStatus(peerConnection.connectionState)
            if (peerConnection.connectionState === 'failed') {
                //peerConnection.restartIce()
                //restartWebRTC(); 
            } else if (peerConnection.connectionState === 'connected') {
                //peerConnection.restartIce()
            } 
        } )
        peerConnection.addEventListener("iceconnectionstatechange", () => {
            console.log(`peerConnection.iceconnectionstatechange: ${peerConnection.iceConnectionState}`)
            setRtcICEConnectionStatus(peerConnection.iceConnectionState) 
        } );
        peerConnection.addEventListener("icegatheringstatechange", () => {
            console.log(`peerConnection.icegatheringstatechange: ${peerConnection.iceGatheringState}`)
        } );
        peerConnection.addEventListener("negotiationneeded", () => {
            console.log(`peerConnection.negotiationneeded`)
        } );
        peerConnection.addEventListener("signalingstatechange", () => {
            console.log(`peerConnection.signalingstatechange: ${peerConnection.signalingState}`)
            setRtcSignalingStatus(peerConnection.signalingState) 
        } )

        setState({
            state : "hasPeerConnection",
            peerConnection,
            createOffer,
            createAndSendOffer,
            createAnswer,
            generalDataChannelSend
        })
    })();
  }, [])


  useEffect(() => {
    const task = async () => {
        if (state.state === 'hasPeerConnection') {
            if (localWebcamMediaStream.state === 'shared') {
                setConnectionController((controller) => {
                    return Object.assign(
                        {}, 
                        controller, 
                        { 
                            mediaElements: Object.assign({}, controller.mediaElements, { localWebcamVideoMediaStream : localWebcamMediaStream.mediaStream } satisfies Partial<TokyoConnectionMediaElements>)
                        } satisfies Partial<TokyoConnectionController>
                    )
                })
                const videoTrack = localWebcamMediaStream.mediaStream.getVideoTracks()[0];
                videoTrack.enabled = true;
                if (state.generalDataChannelSend) {
                    state.generalDataChannelSend({
                        type : 'streamMetadata',
                        streamMetadata : {
                            streamId : localWebcamMediaStream.mediaStream.id,
                            streamType : "webcam"
                        }
                    })
                }
                state.peerConnection.addTrack(videoTrack, localWebcamMediaStream.mediaStream);
                await state.createAndSendOffer()
            }
        }
    }
    task();
  }, [localWebcamMediaStream.state, state.state])
  useEffect(() => {
    const task = async () => {
        if (state.state === 'hasPeerConnection') {
            if (localMicMediaStream.state === 'unmuted') {
                const audioTrack = localMicMediaStream.mediaStream.getAudioTracks()[0];
                state.peerConnection.addTrack(audioTrack, localMicMediaStream.mediaStream);
                await state.createAndSendOffer()
            }
        }
    }
    task();
  }, [localMicMediaStream.state, state.state])
  
  useEffect(() => {
    setConnectionController((controller) => {
        if (controller.status === 'connected') {
            const newController =  Object.assign({}, controller, {
                remoteDesktopViewStrokes: desktopViewStrokes,
                //desktopViewCursorState,
                remoteDesktopViewCursorState,
                localMicStatus,
                remoteMicStatus,
                localWebcamStatus,
                remoteWebcamStatus
            } as TokyoConnectionController);
            const test = Object.is(newController, controller)
            return newController
        } else if (controller.status === 'pendingConnection') {
            return controller;
        } else{
            return controller;
        }
    })

  }, [
    desktopViewStrokes, 
    desktopViewCursorState,
    remoteDesktopViewCursorState,
    localMicStatus,
    remoteMicStatus,
    localWebcamStatus,
    remoteWebcamStatus
    ]
);



  const [desktopViewUIScale, setDesktopViewUIScale] = useState<number>(1.0)

  const [connectionController, setConnectionController] = useState<TokyoConnectionController>({
    status: 'initialising',
    mediaElements : {
        desktopVideoMediaStream: null,
        remoteWebcamVideoMediaStream: null,
        localWebcamVideoMediaStream: null,
        remoteAudioMediaStream: null
    },
    setLocalWebcamMediaStream,
    setLocalMicMediaStream
  })

  const addDesktopViewLineSegment = (lineSegment : LineSegment) => {
    const id = lineSegment.id;
    const strokeId = lineSegment.strokeId;
    setDesktopStrokes((strokes) => {
        const existingStroke = strokes.find((stroke) => stroke.id === strokeId);
        if (existingStroke) {
            return strokes.map((stroke) => {
                if (stroke === existingStroke) {
                    return Object.assign({}, stroke, { lineSegments: stroke.lineSegments.filter((lineSegment) => lineSegment.id !== id).concat([lineSegment]) } satisfies Partial<Stroke>);
                } else {
                    return stroke;
                }
            });
        } else {
            return strokes.concat([ {id : strokeId, lineSegments: [lineSegment]} satisfies Stroke])
        }
    });

    setTimeout(() => 
        setDesktopStrokes((strokes) => 
            strokes.map(
                (stroke) => {
                    if (stroke.id === strokeId) {
                        return Object.assign({}, stroke, { lineSegments: stroke.lineSegments.filter((lineSegment) => lineSegment.id !== id)} satisfies Partial<Stroke>);
                    } else {
                        return stroke;
                    }
                }
            )
        )
    , lineSegment.fadePeriod);
  }

  
  const getConnectionType = async (pc : RTCPeerConnection) => {
    const stats = await pc.getStats();
    const iter = stats.values()
    let res = iter.next()
    let successfulCandidatePair = null;
    while(!res.done && !successfulCandidatePair) {
        if (res.value.state === "succeeded" && res.value.type === "candidate-pair") {
            successfulCandidatePair = res.value;
        } else {
            res = iter.next();
        }
    }
    
    //const localCandidate = successfulCandidatePair ? stats.get(successfulCandidatePair.localCandidateId) : null;
    //const connectionType = localCandidate?.candidateType as "host" | "srflx" | "prflx" | "relay"
    //return connectionType;

    return "host";
  }

  type MachineIdentity = {
    type : "registered",
    id : string,
    pubKey : string
    } | {
    type : "unregistered",
    pubKey : string
    }
    
  const redeemInviteTest = (inviteId : string, identity : {type : "anonymous", machineId : MachineIdentity} | {type: "user", id : string, machineId : MachineIdentity}) => {
    return {
        clientAccessUri: "",
        sessionId: "",
        iceServer: { urls: [], credential: "", username: "" } as RTCIceServer,
    };
  }



    return connectionController
}
