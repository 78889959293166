import React, { useState, useEffect, useMemo } from "react";
import { Link } from "react-router-dom";
import {
    Col,
    Row,
    Table,
} from "react-bootstrap";
import { SpinnerIcon } from "src/tokyoComponents/utils/Utils";


const LoadingSpinner = (props: any) => {
    return <Row className="icon-demo-content">
            <Col xl={12} lg={12} sm={12}>
                <SpinnerIcon className="mr-1 inline text-black"/> Loading...
            </Col>
        </Row>
}

export default LoadingSpinner;
