"use client"
import React, { useState, useEffect, useMemo, useCallback, useRef, useContext } from "react";

//import Select from "react-select";
import Select from 'react-select';
import AsyncSelect from 'react-select/async';
import { PaginatedTable, TableContainer } from "./TableContainer";
//import {
//    Card,
//    Form,
//} from "react-bootstrap";
import { EpicPenContext, getUserLocale } from "../../tokyoComponents/utils/Identity";
import { buildInfo } from "../../BuildInfo";
import { activationCodeToStatusBadge, countryCodeToName, createActivationCode, createGUID, formatActivationCode, moneyToDisplayString, nameof, paddleNums } from "../../tokyoComponents/utils/Utils";
import build from "react-countup";
import LoadingSpinner from "./../LoadingSpinner";
import ReactGA from "react-ga4";
import $ from 'jquery';
import { debounce, set } from 'lodash';
import EPModal, { EPModalButton } from "../EPModal";
import Link from "next/link";
import { createColumnHelper } from "@tanstack/react-table";
import { CopyButton } from "src/tokyoComponents/ToolbarOverlay";
import { BillingPeriod, Subscription, Subscription2Upload } from "src/tokyoComponents/utils/WebAPI";

interface ActivationCodeDisplay {
    id: string
    idFormatted?: string
    maxDevices: number
    deviceIdCount: number
}



interface ActivationCodeCellProps {
    activationCode: string
}

const ActivationCodeCell = (props: ActivationCodeCellProps) => {
    return <div>
        <span className="align-middle text-black text-nowrap font-medium font-mono mr-2">{formatActivationCode(props?.activationCode ?? "0000")}</span>
        <CopyButton value={formatActivationCode(props.activationCode)}/>
    </div>;
};


interface PaddlePrice {
    "gross": number,
    "net": number,
    "tax": number
}

interface PaddleProduct {
    "product_id": number,
    "product_title": string,
    "currency": string,
    "vendor_set_prices_included_tax": boolean,
    "price": PaddlePrice,
    "list_price": PaddlePrice,
    "subscription": {
        "trial_days": number,
        "interval": string,
        "frequency": number,
        "price": PaddlePrice,
        "list_price": PaddlePrice
    }
}

interface PaddleProducts {
    "customer_country": string
    "products": PaddleProduct[]
}



interface PaddleResponse<T> {
    success: boolean
    response: T
}

const AddActivationCodeModal = (props: { isOpen: boolean, close: () => void }) => {
    const paddleSandboxMode = !buildInfo.channel.isProduction;

    const user = useContext(EpicPenContext) || {};
    const [pricePerLicenceMonthly, setPricePerLicenceMonthly] = useState<number | null>(null);
    const [pricePerLicenceYearly, setPricePerLicenceYearly] = useState<number | null>(null);
    const [billingPeriod, setBillingPeriod] = useState<BillingPeriod>("monthly");
    const [numOfLicences, setNumOfLicences] = useState<number>(1);

    const [currency, setCurrency] = useState<string | null>(null);
    const [isLoading, setLoading] = useState<boolean>(true);


    const addActivationCodeClick = () => {
        const Paddle = (window as any).Paddle;
        const productId = (() => {
            if (billingPeriod === "monthly" && paddleSandboxMode) {
                return paddleNums.sandboxEpicPenProMonthlyPlanId //paddleNums.sandboxEpicPenProMonthlyPlanId;
            } else if (billingPeriod === "yearly" && paddleSandboxMode) {
                return paddleNums.sandboxEpicPenProYearlyPlanId;
            } else if (billingPeriod === "monthly" && !paddleSandboxMode) {
                return paddleNums.productionEpicPenProMonthlyPlanId;
            } else if (billingPeriod === "yearly" && !paddleSandboxMode) {
                return paddleNums.productionEpicPenProYearlyPlanId;
            }
        })();

        const passthrough = {
            userId: user.state == "loggedIn" && user.hasEPUser ? user.epUser.id : null
        }

        Paddle.Checkout.open({
            product: productId,
            email: user.state == "loggedIn" && user.hasEPUser ? user.epUser.email : null,
            allowQuantity: true,
            quantity: numOfLicences,
            disableLogout: true,
            coupon: null,
            passthrough: JSON.stringify(passthrough),
            customData: passthrough,
            success: window.location.href
        });
    }

    useEffect(() => {
        const getPaddleInfo = async () => {
            setLoading(true)
            try {
                $.ajax({
                    url: `https://${!buildInfo.channel.isProduction ? "sandbox-" : ""}checkout.paddle.com/api/2.0/prices`,
                    dataType: 'jsonp',
                    data: {
                        product_ids: (!buildInfo.channel.isProduction ? [paddleNums.sandboxEpicPenProMonthlyPlanId, paddleNums.sandboxEpicPenProYearlyPlanId] : [paddleNums.productionEpicPenProMonthlyPlanId, paddleNums.productionEpicPenProYearlyPlanId]).join(", "),
                        quantity: (1).toString()
                    },
                    success: (data: PaddleResponse<PaddleProducts>) => {
                        console.log(`paddle prices responseData: ${JSON.stringify(data)}`);
                        let formatter = new Intl.NumberFormat(undefined, {
                            style: 'currency',
                            currency: data.response.products[0].currency,
                        });

                        const products = data.response.products;
                        const epicPenMonthly = products.find((product) => product.product_id === (paddleSandboxMode ? paddleNums.sandboxEpicPenProMonthlyPlanId : paddleNums.productionEpicPenProMonthlyPlanId));
                        const epicPenYearly = products.find((product) => product.product_id === (paddleSandboxMode ? paddleNums.sandboxEpicPenProYearlyPlanId : paddleNums.productionEpicPenProYearlyPlanId));

                        setCurrency(data.response.products[0].currency)
                        setPricePerLicenceMonthly(epicPenMonthly?.price.gross!)
                        setPricePerLicenceYearly(epicPenYearly?.price.gross!)
                    }
                });
                console.log(`getPaddleInfo downloaded successfully`);
            } catch (e: any) {
                console.log(`getPaddleInfo() error: ${JSON.stringify(e)}`);
            }
            setLoading(false)
        };
        getPaddleInfo();
    }, [user]);

    return <EPModal
        title={"permanent activation code"}
        isOpen={props.isOpen}
        close={props.close}
        button={{
            text: "Add activation code",
            color: "blue",
            loading: false,
            onClick: addActivationCodeClick
        }}
    >{ isLoading?<div className="px-4 pt-4 pb-4 bg-white">< LoadingSpinner /></div> :
    <React.Fragment>
        <div className="modal-body">
            <h5>Payment rate</h5>
            <div className="mb-3">
                <span className="display-6">{moneyToDisplayString((billingPeriod === "monthly" ? pricePerLicenceMonthly! : pricePerLicenceYearly!) * numOfLicences, currency)}</span>
                <span> / {billingPeriod === "monthly" ? "Month" : "Year"}</span>
            </div>
            <h5>Payment period</h5>
            <div className="btn-group mb-3" role="group" aria-label="Basic radio toggle button group" onChange={(event: any) => { console.log((event.target as any).value); setBillingPeriod(() => ((event.target as any).value)) }}>
                <input type="radio" className="btn-check" name="btnradio" value="monthly" id="radio_monthly" key="monthly" defaultChecked />
                <label className="btn btn-outline-secondary" htmlFor="radio_monthly">Monthly</label>
                <input type="radio" className="btn-check" name="btnradio" value="yearly" id="radio_yearly" key="yearly" />
                <label className="btn btn-outline-secondary" htmlFor="radio_yearly">Yearly</label>
            </div>
            <h5>Number of devices</h5>
            <div className="mb-0">
                <input
                    type="number"
                    className="form-control"
                    name="numOfLicences"
                    contentEditable="true"
                    value={numOfLicences}
                    onChange={(event: any) => { setNumOfLicences(() => parseInt(((event.target as any).value))) }}
                    max="10000"
                    min="1"
                />

            </div>
        </div>
            </React.Fragment>
        }</EPModal>
}


const AddPermanentActivationCodeModal = (props: { isOpen: boolean, close: () => void }) => {

    const [isAdding, setIsAdding] = useState(false)

    const user = useContext(EpicPenContext) || {};
    const api = user.state !== "uninitialised" ? user.api : null;

    const [activationCodeUpload, setActivationCodeUpload] = useState<Subscription2Upload>({
        userId: "",
        notes: "",
        numOfLicences: 1,
        activationCode: createActivationCode(),
        displayName: "",
        alternativeAdminEmail: ""
    })

    console.log(activationCodeUpload)
    const requestData = useRef<{ id: string, abortController: AbortController }>({ id: createGUID(), abortController: new AbortController() });

    const loadOptions = useMemo(() => debounce((inputValue: string, callback: (options: object[]) => void) => {
        const getUsersAsync = async () => {
            if (api) {
            requestData.current.abortController.abort();
            const newRequestData = {
                id: createGUID(), abortController: new AbortController()
            };
            requestData.current = newRequestData;
            const users = await api!.getUsers(0, 60, inputValue ?? "", newRequestData.abortController);
            if (newRequestData.id === requestData.current.id)
                callback(users.status === "success" ? users.items.map((user) => { return { value: user.id, label: `${user.firstName} ${user.lastName} - ${user.email}`, color: '#666666' } }) : []);
            }else {
                //callback([]) 
            }
        }
        getUsersAsync();
    }, 400), [api]);
 
    const addClick = () => {
        const add = async () => {
            setIsAdding(() => true)
            const result = await api!.putSubscription2(activationCodeUpload)
            if (result.status === "failure") {

            } else {
                props.close();
            }
            console.log(result);
            setIsAdding(() => false)
        }
        add();
    }


    return <EPModal
        title={"permanent activation code"}
        isOpen={props.isOpen}
        close={props.close}
        button={{
            text: isAdding ? "adding..." : "Add permanent activation code",
            color: "blue",
            loading: isAdding,
            onClick: addClick
        }}
    >
        <div className="modal-body">
            <h5>Activation code</h5>
            <div className="input-group mb-3">
                <input type="text" className="form-control font-monospace font-weight-bold" placeholder="" aria-label="" value={formatActivationCode(activationCodeUpload.activationCode)} readOnly />

                <div className="input-group-append">
                    <button className="btn btn-primary waves-effect waves-light" type="button" onClick={() => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("activationCode"), createActivationCode())) }}>generate</button>
                </div>
            </div>

            <h5>Number of devices</h5>
            <div className="mb-3">
                <input
                    type="number"
                    className="form-control"
                    name="numOfLicences"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.numOfLicences}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("numOfLicences"), parseInt(((event.target as any).value)))) }}
                    max="10000"
                    min="1"
                />

            </div>
            <h5>Display name</h5>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.displayName}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("displayName"), ((event.target as any).value))) }}
                    max="10000"
                    min="1"
                />
            </div>
            <h5>Alternative admin email</h5>
            <div className="mb-3">
                <input
                    type="email"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.alternativeAdminEmail ?? ""}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("alternativeAdminEmail"), ((event.target as any).value))) }}
                />
            </div>
            <h5>Notes</h5>
            <div className="mb-3">
                <input
                    type="text"
                    className="form-control"
                    name="displayName"
                    contentEditable="true"
                    defaultValue={activationCodeUpload.notes ?? ""}
                    onChange={(event: any) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("notes"), ((event.target as any).value))) }}
                />
            </div>
            <h5>Owner</h5>
            <div className="mb-0">
                <AsyncSelect cacheOptions loadOptions={loadOptions} defaultOptions onChange={(value: any, action) => { setActivationCodeUpload((ac) => set(ac, nameof<Subscription2Upload>("userId"), value.value)) }} />
            </div>
        </div>
    </EPModal>



}

interface ActivationCodeTableProps {
    minimal?: boolean
    currentUserOnly: boolean
    userId?: string
    adminMode?: boolean
}



const ActivationCodeTable = (props: ActivationCodeTableProps) => {
    const user = useContext(EpicPenContext);

    const [isModalOpen, setIsModalOpen] = useState(false)
    const columnHelper = createColumnHelper<Subscription>()
    return PaginatedTable<Subscription>({
        minimal: props.minimal,
        fetchData: (user.state === "loggedIn") ? (async (pageIndex, pageSize, searchString, abortController) => await ((user.state === "loggedIn" ? user.api : null)!.getSubscription2s(pageIndex, pageSize, searchString, props.currentUserOnly, props.userId, abortController))) : null,
        addModal: (!props.adminMode ? { modal: <AddActivationCodeModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />, itemName: "activation code", open: () => setIsModalOpen(true) } : { modal: <AddPermanentActivationCodeModal isOpen={isModalOpen} close={() => setIsModalOpen(false)} />, itemName: "permanent activation code", open: () => setIsModalOpen(true) } ),
        columns: [
            columnHelper.accessor('activationCode', {
                header: "Activation code",
                meta: {includeInMinimal: true},
                cell: info => <ActivationCodeCell activationCode={info.getValue()} /> 
            }),
            columnHelper.accessor('quantityInUse', {
                header: "Activated devices",
                meta: {includeInMinimal: false},
                cell: (info) => {
                    const fullyActivated = info.getValue() === info.row.original.quantity;
                    const text = `${info.getValue()} / ${info.row.original.quantity}`;
                    return fullyActivated ? <b>{text}</b> : text;
                }
              }),
              
            columnHelper.accessor('mode', {
                header: "License type",
                meta: {includeInMinimal: false},
                cell: info => info.getValue() === "standard" ? "Subscription" : "Lifetime"
              }),
              
            columnHelper.accessor('status', {
                header: "Status",
                meta: {includeInMinimal: false},
                cell: info => activationCodeToStatusBadge(info.row.original)
            }),
              
            columnHelper.accessor('billingPeriod', {
                header: "Billing period",
                meta: {includeInMinimal: false},
                cell: info => info.row.original.mode === "standard" ? (info.getValue() === "monthly" ? "Monthly" : "Yearly") : ""
                }),
              
            columnHelper.accessor('meta_creationDateTime', {
                header: "Creation date",
                meta: {includeInMinimal: false, adminOnly: true},
                cell: info => `${new Date(info.getValue()).toLocaleTimeString(getUserLocale(user))} ${new Date(info.getValue()).toLocaleDateString(getUserLocale(user))}`
            }),
        
            //columnHelper.accessor('user.email', {
            //    header: "User",
            //    meta: {includeInMinimal: false, adminOnly: true},
            //    cell: info => <Link href={`/profile/${info.row.original.user?.id}`} className="text-sky-600 hover:text-sky-900">{info.getValue()}</Link>
            //    }),
            //  
            //columnHelper.accessor('user.email', {
            //    header: "User",
            //    meta: {includeInMinimal: false, adminOnly: true},
            //    cell: info => <Link href={`/profile/${info.row.original.user?.id}`} className="text-sky-600 hover:text-sky-900">{info.getValue()}</Link>
            //}),
            //  
            //columnHelper.accessor('user.countryCode', {
            //    header: "Country",
            //    meta: {includeInMinimal: false, adminOnly: true},
            //    cell: info => countryCodeToName(info.getValue())
            //    }),
            columnHelper.display({
                id: "viewDetails",
                meta: {includeInMinimal: true, alignRight : true},
                cell: info => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link href={`#`}
                        className="text-sky-600 hover:text-sky-900"
                    >
                        View details
                    </Link>
            }})
        ]
        
    })
}


export default ActivationCodeTable;
