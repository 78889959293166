"use client"
import Image from 'next/image'
import React from 'react';
import { useContext, useState, useEffect } from 'react';
import { Card, Col, Container, ProgressBar, Row } from 'react-bootstrap';
import { EpicPenContext, useUser } from 'src/tokyoComponents/utils/Identity';
import { LicenceDetails } from 'src/tokyoComponents/utils/WebAPI';
import Breadcrumbs from "src/components/Common/Breadcrumb";
import Link from 'next/link';
import ActivationCodeTable from 'src/epicPenComponents/tables/ActivationCodeTable';
import OrderTable from 'src/epicPenComponents/tables/OrderTable';
import { SpinnerIcon } from 'src/tokyoComponents/utils/Utils';

export default function Home() {
  
  const [isLoading, setLoading] = useState<boolean>(true);
  const [licenceDetails, setLicenceDetails] = useState<LicenceDetails | null>(null);

  const user = useUser();
  console.log("const user = useContext(EpicPenContext);")
  console.log(user)
  const api = user.state !== 'uninitialised' ? user.api : null;
  useEffect(() => {
      if (api) {
        const getActivationCodes = async () => {
            try {
                const licenceDetails = await api!.getLicenceDetailsOfCurrentUser()
                setLicenceDetails(() => licenceDetails);
                setLoading(false)
            } catch (e: any) {
                console.log(`call api error: ${JSON.stringify(e)}`);
            }
        };
        getActivationCodes();
      }
  }, [api]);

  
  return (
      <React.Fragment>
          <div className="page-content">
              <div className="fluid w-full">
                  <div className="md:flex md:items-center md:justify-between mb-12 px-1">
                    <div className="min-w-0 flex-1">
                      <h2 className="text-2xl font-bold leading-7 text-gray-900 sm:truncate sm:text-3xl sm:tracking-tight">
                        Dashboard
                      </h2>
                    </div>
                  </div>
                  { false ? <>
                  <div className="block mb-4 sm:flex ">
                    <div className="w-full sm:w-1/2 px-1 mb-2">
                            <div className="w-full rounded overflow-hidden shadow-lg">
                                  <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-4">
                                  <h3 className="text-base font-semibold leading-6 text-gray-900">Activation Codes</h3>
                                  <p className="mt-1 text-sm text-gray-500">A list of all your current Epic Pen Pro activation codes.</p>
                              </div>
                                <div className="px-4 pt-4 pb-4 bg-white">
                                  <ActivationCodeTable minimal={true} currentUserOnly={true} />
                              </div>
                              </div>
                      </div>
                      <div className="w-full sm:w-1/2 px-1 mb-2">
                            <div className="w-full rounded overflow-hidden shadow-lg">
                                  <div className="border-b border-gray-200 bg-white px-4 py-5 sm:px-4">
                                  <h3 className="text-base font-semibold leading-6 text-gray-900">Orders</h3>
                                  <p className="mt-1 text-sm text-gray-500">A history of your orders of Epic Pen Pro.</p>
                              </div>
                                <div className="px-4 pt-4 pb-4 bg-white">
                                  <OrderTable minimal={true} currentUserOnly={true} />
                              </div>
                              </div>
                      </div>
                  </div>
                  </> : <></>
                  }
              </div>
          </div>
      </React.Fragment>
  );


}
