import React, { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import PropTypes from "prop-types";
import {
  useAsyncDebounce,
} from "react-table";
import { useReactTable, RowModel, getCoreRowModel, getPaginationRowModel, getSortedRowModel, PaginationState, flexRender, Column, ColumnDef } from '@tanstack/react-table'
//import { Table, Row, Col, Button, Modal, Form } from "react-bootstrap";
import { Filter, DefaultColumnFilter } from "./../filters";
import LoadingSpinner from "./../LoadingSpinner";
import { EpicPenContext } from "../../tokyoComponents/utils/Identity";
import { SpinnerIcon, createGUID } from "../../tokyoComponents/utils/Utils";
import { isFunction } from "formik";
import EPModal, { EPModalButton } from "../EPModal";
import { Listbox, Transition } from "@headlessui/react";
import { ChevronDownIcon } from "@heroicons/react/24/outline";
import Link from "next/link";
import { buildInfo } from "src/BuildInfo";
import { PaginatedWebServiceResponse } from "src/tokyoComponents/utils/WebAPI";

interface GlobalFilterProps {
    preGlobalFilteredRowsCount: number;
    globalFilter?: string;
    setGlobalFilter: (globalFilter: string) => void;
    setPageIndex: (index: number) => void
}



export type LoadingState = "idle" | "loadingWithCache" | "loading"; 

function classNames(...classes: string[]) {
    return classes.filter(Boolean).join(' ')
  }

// Define a default UI for filtering
function GlobalFilter({
    preGlobalFilteredRowsCount,
    globalFilter,
    setGlobalFilter,
    setPageIndex
} : GlobalFilterProps) {
  const count = preGlobalFilteredRowsCount;
  const [value, setValue] = React.useState(globalFilter);
  const onChange = useAsyncDebounce((value:any) => {
      setGlobalFilter(value || undefined);
      setPageIndex(0);
  }, 600);

    return (
        <div className="search-box me-2 mb-2 d-inline-block" >
        <div className="position-relative">
          <label htmlFor="search-bar-0" className="search-label">
            <span id="search-bar-0-label" className="sr-only">
              Search this table
            </span>
            <input
              onChange={e => {
                setValue(e.target.value);
                onChange(e.target.value);
              }}
              id="search-bar-0"
              type="text"
              className="form-control"
              placeholder={`${count} records...`}
              value={value || ""}
            />
          </label>
          <i className="bx bx-search-alt search-icon"></i>
        </div>
            </div>
  );
}

interface TableContainerProps<T> {
    columns: ColumnDef<T, any>[]// { Header: string; accessorKey: string; adminOnly?: boolean }[];
    data: PaginatedWebServiceResponse<T>;
    isLoading: LoadingState
    fetchData: (pageIndex: number, pageSize: number, searchString: string) => void;
    addModal?: { modal: React.ReactNode, open : () => void, itemName: string }
    isMinimal: boolean
}

const TableContainer = <T,>({
    columns,
    data,
    isLoading,
    fetchData,
    addModal,
    isMinimal
}: TableContainerProps<T>) => {
    const user = useContext(EpicPenContext) || {};
    const api = user.state !== "uninitialised" ? user.api : null;
    const [{ pageIndex, pageSize }, setPagination] =
    React.useState<PaginationState>({
      pageIndex: 0,
      pageSize: 20,
    });
    const pagination = React.useMemo(
        () => ({
          pageIndex,
          pageSize,
        }),
        [pageIndex, pageSize]
      );
    const reactTable = useReactTable<T>(
        {
            columns,
            data: data.status === "success" ? data.items : [],
            initialState: {  },
            state: {
                pagination,
              },
            manualPagination: true,
            onPaginationChange: setPagination,
            getCoreRowModel: getCoreRowModel(),
            getPaginationRowModel: getPaginationRowModel(),
            getSortedRowModel: getSortedRowModel(), //order doesn't matter anymore!
        }
    );

    


    const [refreshToken, setRefreshToken] = React.useState(createGUID());

    const AddModal: React.ReactNode = addModal?.modal ?? <React.Fragment></React.Fragment>

    const reactTableState = reactTable.getState();

    React.useEffect(() => {
        if (buildInfo.channel.isDevelopment || api) {
            console.log(`pageIndex : ${pageIndex}, pageSize : ${pageSize}, globalFilter : ${reactTableState.globalFilter}`)
            fetchData(pageIndex, pageSize, reactTableState.globalFilter)
        }
    }, [pageIndex, pageSize, reactTableState.globalFilter, refreshToken, api])

    const generateSortingIndicator = (column: any) => {
        return column.isSorted ? (column.isSortedDesc ? " 🔽" : " 🔼") : "";
    };

    const onChangeInSelect = (event: any) => {
        reactTable.setPageIndex(Number(event.target.value));
    };

    const onChangeInInput = (event: any) => {
        const page = event.target.value ? Number(event.target.value) - 1 : 0;
        reactTable.setPageIndex(page);
    };
    const pageCount = data.status === "success" ? Math.max(1, Math.ceil(data.totalItemCount / pageSize)) : 1;
    return (
        <Fragment>

            {AddModal}
              <div className="flex flex-row mb-4">
                <div className={`${isMinimal ? "basis-1/2" : "w-1/4"} `}>
                    <Listbox  value={pageIndex} onChange={reactTable.setPageIndex} as="div" className="relative inline-block text-left">
                    <div>
                        <Listbox.Button className="inline-flex w-full justify-center gap-x-1.5 rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50">
                        Page {pageIndex + 1} of {pageCount}
                        <ChevronDownIcon className="-mr-1 h-5 w-5 text-gray-400" aria-hidden="true" />
                        </Listbox.Button>
                    </div>

                    <Transition
                        as={Fragment}
                        enter="transition ease-out duration-100"
                        enterFrom="transform opacity-0 scale-95"
                        enterTo="transform opacity-100 scale-100"
                        leave="transition ease-in duration-75"
                        leaveFrom="transform opacity-100 scale-100"
                        leaveTo="transform opacity-0 scale-95"
                    >
                        <Listbox.Options  className="absolute left-0 z-10 mt-2 w-56 origin-top-right rounded-md bg-white shadow-lg ring-1 ring-black ring-opacity-5 focus:outline-none">
                        <div className="py-1">
                            
                        {
                            Array.from({length: pageCount }, (x, index) =>
                            <Listbox.Option  key={index}   value={index}>
                            {({ selected  }) => (
                                <span
                                className={classNames(
                                    selected ? 'bg-gray-100 text-gray-900' : 'text-gray-700',
                                    'block px-4 py-2 text-sm'
                                )}
                                >
                                Page {index + 1} of {pageCount}
                                </span>
                            )}
                            </Listbox.Option>
                            
                            )
                        }
                            
                        </div>
                        </Listbox.Options>
                    </Transition>
                    </Listbox>
                </div>
                {!isMinimal && (
                    <div className={`${"w-1/4"}`}>
                        <GlobalFilter
                            preGlobalFilteredRowsCount={data.status === "success" ? Math.max(1, Math.ceil(data.totalItemCount / pageSize)) : 1}
                            globalFilter={reactTableState.globalFilter}
                            setGlobalFilter={reactTable.setGlobalFilter}
                            setPageIndex={reactTable.setPageIndex} 
                            />
                    </div>
                )}

                    <div className={`flex ${"basis-1/2"} justify-end`}>
                            <button type="button"  className="inline-flex items-center rounded-md bg-sky-600 px-3 py-2 text-center text-sm font-semibold text-white shadow-sm hover:bg-sky-500 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-sky-600" disabled={isLoading !== "idle"} onClick={() => { setRefreshToken(() => createGUID()) }}>
                                {isLoading !== "idle" || (!api) ?
                                    <><SpinnerIcon className="mr-1 inline text-white"/>Loading</> : <>Refresh</>
                                }
                            </button>
                        {(!isMinimal && addModal) ?
                            <button type="button" className="btn btn-light waves-effect waves-light ms-3" onClick={() => { addModal.open() }}><i className="bx bx-plus me-1" ></i> Add {addModal?.itemName ?? ""}</button> : []
                                }
                        </div>
                
            </div>

            <div className="mt-8 flow-root">
        <div className="-mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
          <div className="inline-block min-w-full py-2 align-middle sm:px-6 lg:px-8">
            <table className="min-w-full divide-y divide-gray-300">
              <thead>
                    {reactTable.getHeaderGroups().map(headerGroup => (
                        <tr key={headerGroup.id}>
                        {headerGroup.headers.map(header => (
                            <th key={header.id} scope="col"
                            className="px-3 py-3 text-left text-xs font-medium uppercase tracking-wide text-gray-500">{(header.column.columnDef.meta as any)?.adminOnly ? <span className="text-red-700">{header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}</span> : <span>{header.isPlaceholder
                                ? null
                                : flexRender(
                                    header.column.columnDef.header,
                                    header.getContext()
                                )}</span> }</th>
                        ))}
                        </tr>
                    ))}
                    </thead>
                    <tbody className="divide-y divide-gray-200 bg-white">
                        {
                            isLoading == "loading" || !api ? <tr>
                                <td colSpan={columns.length} className="whitespace-nowrap px-3 py-4 text-sm text-gray-500 text-center">
                                        <SpinnerIcon className="inline text-sky-600 align-middle"/><span className="text-gray-600 align-middle">Loading...</span>
                                    </td>
                                </tr> : <>{
                            reactTable.getRowModel().rows.map((row) => {
                                //prepareRow(row);
                                const key = row.id;
                                return (
                                    <tr key={key}>
                                        {row.getAllCells().map((cell) => {
                                            const cellkey = cell.id;
                                            const cell2 = cell.column.columnDef.cell
                                            const context = cell.getContext();
                                            return (
                                                <td key={cellkey} className={`${(cell.column.columnDef.meta as any)?.alignRight! ? "text-right" : "text-left"} whitespace-nowrap px-3 py-4 text-sm text-gray-500`}>
                                                    {flexRender(
                                                        cell.column.columnDef.cell,
                                                        cell.getContext()
                                                    )}
                                                </td>
                                            );
                                        })}
                                    </tr>
                                );
                            })}</>
                    }
                    </tbody>
                </table>
            </div>
            </div>
            </div>

        </Fragment>
    );
};

TableContainer.propTypes = {
  preGlobalFilteredRows: PropTypes.any,
};



interface EPColumns {
    includeInMinimal: boolean,
    adminOnly?: boolean
    alignRight?: boolean
}

interface PaginatedTableProps<T> {
    minimal?: boolean
    fetchData: ((pageIndex: number, pageSize: number, searchString: string, abortController: AbortController) => Promise<PaginatedWebServiceResponse<T>>) | null
    columns: ((setRows: ((rowsTransformer: (currentRows: T[]) => T[]) => void)) => ColumnDef<T, any>[]) | ColumnDef<T, any>[]// ((setRows: ((rowsTransformer: (currentRows: T[]) => T[]) => void)) => EPColumns[]) | EPColumns[]
    addModal?: { modal: React.ReactNode, open : () => void, itemName: string }
}


const PaginatedTable = <T,>(props: PaginatedTableProps<T>) => {
    const cache = useRef<any>({});

    const [items, setItems] = useState<PaginatedWebServiceResponse<T>>({status: "success", totalItemCount: 0, items: [] });

    const currentRequestGuid = useRef<string>(createGUID());
    const [requestsInProgress, setRequestsInProgress] = useState<Array<{ guid: string, loadingState: LoadingState, abortController: AbortController }>>([]);

    const loadingState = requestsInProgress.map((req) => req.loadingState).reduce((pre, cur) => (pre === "loading" || cur == "loading") ? "loading" : (pre === "loadingWithCache" || cur == "loadingWithCache") ? "loadingWithCache" : "idle", "idle")

    const user = useContext(EpicPenContext) || {};



    const getOrdersLocal = async (pageIndex: number, pageSize: number, searchString: string) => {
        const requestGuid = createGUID();
        currentRequestGuid.current = requestGuid;
        try {
            requestsInProgress.forEach((req) => req.abortController.abort())
            let newAbortController = new AbortController();

            const cacheResult = cache.current[`${pageIndex}-${searchString}`] as PaginatedWebServiceResponse<T>;

            if (cacheResult) {
                setItems(() => cache.current[`${pageIndex}-${searchString}`]);
                setItems((oldUsers) => {
                    if (requestGuid === currentRequestGuid.current) {
                        return cacheResult;
                    } else {
                        return oldUsers;
                    }
                });
                setRequestsInProgress((requests) => requests.concat([{ guid: requestGuid, loadingState: "loadingWithCache", abortController: newAbortController }]))
            } else {
                setRequestsInProgress((requests) => requests.concat([{ guid: requestGuid, loadingState: "loading", abortController: newAbortController }]))
            }

            //const users = (await getUsers(getAccessToken, buildInfo.isDevMachine, pageIndex, pageSize, searchString, newAbortController))!;
            if (props.fetchData) {
                const users = (await props.fetchData(pageIndex, pageSize, searchString, newAbortController))!;
                setItems((oldUsers) => {
                    if (requestGuid === currentRequestGuid.current) {
                        cache.current[`${pageIndex}-${searchString}`] = users;
                        return users;
                    } else {
                        return oldUsers;
                    }
                });
            }
        } catch (e: any) {
            console.log(`PaginatedTable call api error: ${JSON.stringify(e)}`);
            console.log(e);
        }
        setRequestsInProgress((requests) => requests.filter((req) => req.guid !== requestGuid))
    };
    //useEffect(() => {
    //    getOrdersLocal(pageIndex, pageSize, searchString);
    //}, [pageIndex, searchString]);

    const columns = useMemo(
        () => {
            const columns = isFunction(props.columns) ? props.columns((rowTransformer) => { setItems((items) => { return items.status === "success" ? { status: "success", totalItemCount: items.totalItemCount, items: rowTransformer(items.items) } : items }) }) : props.columns;
            return columns.filter((c) => props.minimal === true ? (c.meta as any)?.includeInMinimal : true && (((c.meta as any)?.adminOnly === undefined || (c.meta as any)?.adminOnly === false) ? true : (user.state === "loggedIn" && user.hasEPUser && user.epUser.role === "admin")))
        },
        [user ?? null]
    );
    const data = useMemo(() => items, [items]);

    return <React.Fragment>
        <TableContainer
            columns={columns}
            data={data}
            isLoading={loadingState}
            fetchData={(pageIndex, pageSize, searchString) => { getOrdersLocal(pageIndex, pageSize, searchString) }}
            isMinimal={props.minimal || false}
            addModal={props.addModal}
        />
    </React.Fragment>
}

export {TableContainer, PaginatedTable}