import React, { Fragment, useEffect, useState } from "react";
import { Badge } from "react-bootstrap";
import { DetailedUser, EmailSendStatus, Subscription } from "./WebAPI";
import { object } from "yup";
import { buildInfo } from "../../BuildInfo";
import { $Enums, SubscriptionStatus } from "@prisma/client";
import { isNull } from "lodash";

const formatActivationCode = (activationCode: string) => {
    return Array.from(Array(6).keys()).map(i => activationCode.substr(i * 4, 4)).join("-");
};

const moneyToDisplayString = (amount: number, currency: string | null) => {
    let formatter = currency ? new Intl.NumberFormat(undefined, {
        style: 'currency',
        currency: currency,
    }) : null;
    return formatter ? formatter.format(amount) : amount.toString()

}

const countryCodeToName = (countryCode: string) => {
    try {
        let regionNames = new Intl.DisplayNames(['en'], { type: 'region' });
        return regionNames.of(countryCode); 
    } catch (e) {
        return "";
    }
}

const createGUID = (): string => {
    return crypto.randomUUID()
    //@ts-ignore
    //return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, c =>
    //    (c ^ crypto.getRandomValues(new Uint8Array(1))[0] & 15 >> c / 4).toString(16)
    //);
}

const createActivationCode = (): string => {
    return Array.from(Array(4 * 6).keys()).map(() => { return (crypto.getRandomValues(new Uint8Array(1))[0] >> 4).toString(16) }).join("")
}

const nameof = <T,>(name: Extract<keyof T, string>): string => name;

export type PaddleMode = 'onceoff' | 'monthly' | 'yearly';

const paddleNums = {
    productionVendorId: 151418,
    sandboxVendorId: 8099,
    productionEpicPenProOnceOffProductId: 779005,
    sandboxEpicPenProOnceOffProductId: 35213,

    //sandboxEpicPenProDailyWithTrialPlanId: 36003,
    sandboxEpicPenProMonthlyWithTrialPlanId: 35663,
    sandboxEpicPenProYearlyWithTrialPlanId: 35662,
    //sandboxEpicPenProDailyPlanId: 36217,
    sandboxEpicPenProMonthlyPlanId: 36218,
    sandboxEpicPenProYearlyPlanId: 36219,
    productionEpicPenProMonthlyPlanId: 793334,
    productionEpicPenProYearlyPlanId: 793333,
    productionEpicPenProMonthlyWithTrialPlanId: 779020,
    productionEpicPenProYearlyWithTrialPlanId: 779011
}

const isTrialActive = (subscription2: Subscription | null) => {
    return false;
}

const activationCodeToStatusBadge = (subscription2: Subscription) => {
    const perModeStatusToBadge = (subscription2: Subscription) => {
        if (true) {//subscription2.permanentModeStatus === "active") {
            return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">Active</span>;
        } 
        //else if (subscription2.permanentModeStatus === "refunded") {
        //    return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Refunded</span>;
        //}
        return null;
        }
    const modeStatusToBadge = (modeStatus: SubscriptionStatus | null) => {
        if (modeStatus){
            switch (true) {
                case modeStatus === "active": 
                    return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">Active</span>;
                case modeStatus === "trialing":
                    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Trialing</span>;
                case modeStatus === "pastDue":
                    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Past Due</span>;
                case modeStatus === "paused":
                    return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Paused</span>;
                case modeStatus === "deleted":
                        return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Cancelled</span>;
                default: {
                    const exhaustiveCheck: never = modeStatus;
                    throw new Error(exhaustiveCheck);
                }
            }
        }
        return <></>;
    }
    return subscription2.mode === "standard" ? modeStatusToBadge(subscription2.status) : perModeStatusToBadge(subscription2);
}

//EmailSendStatus

const emailSendStatusToStatusBadge = (emailSendStatus: EmailSendStatus) => {
    //"unsent" | "processed" | "dropped" | "delivered" | "deferred" | "bounce" | "blocked"
    switch (true) {
        case emailSendStatus === "unsent": 
            return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Unsent</span>;
        case emailSendStatus === "processed": 
            return <span className="inline-flex items-center rounded-md bg-yellow-50 px-2 py-1 text-xs font-medium text-yellow-700 ring-1 ring-inset ring-yellow-600/10">Processed</span>;
        case emailSendStatus === "dropped": 
            return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Dropped</span>;
        case emailSendStatus === "delivered": 
            return <span className="inline-flex items-center rounded-md bg-green-50 px-2 py-1 text-xs font-medium text-green-700 ring-1 ring-inset ring-green-600/10">Delivered</span>;
        case emailSendStatus === "deferred": 
            return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Deferred</span>;
        case emailSendStatus === "bounce": 
            return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Bounce</span>;
        case emailSendStatus === "blocked": 
            return <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Blocked</span>;
        default: {
            const exhaustiveCheck: never = emailSendStatus;
            throw new Error(exhaustiveCheck);
        }
    }

}


export module LocalStorage {
    type BuildInfo = {
        gitSha : string,
        channel : typeof buildInfo.channel.trunk,
        env : typeof buildInfo.runtimeEnvironment.environment
    }

    type StorageSchema = {
        loginNonce : {
            value : string
        }
        loginState : {
            value : string
        }
        loginRedirectPathname: {
            pathname : string
        },
        accessTokenAndId : {
            accessToken : string,
            id : string
        }
        "cache-currentUser" : DetailedUser
    }

    export const getItem = <K extends keyof StorageSchema,>(key : K) => {
        const strValue = localStorage.getItem(key);
        const value =  isNull(strValue) ? null : JSON.parse(strValue) as StorageSchema[K] & BuildInfo 
        if (value && value.gitSha === buildInfo.source.gitCommitHash && value.channel === buildInfo.channel.trunk && value.env === buildInfo.runtimeEnvironment.environment) {
            return value;
        } else {
            return null;
        }
    }

    export const setItem = <K extends keyof StorageSchema,>(key : K, value : StorageSchema[K] ) => {
        localStorage.setItem(key, JSON.stringify(Object.assign({}, value, {
            gitSha : buildInfo.source.gitCommitHash,
            channel : buildInfo.channel.trunk,
            env : buildInfo.runtimeEnvironment.environment
        } satisfies BuildInfo)));
    }

    export const removeItem = <K extends keyof StorageSchema,>(key : K) => {
        localStorage.removeItem(key);
    }
}


const SpinnerIcon = ({className = ''}) => <svg className={`animate-spin -ml-1 mr-3 h-5 w-5 ${className}`} xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24">
<circle className="opacity-25" cx="12" cy="12" r="10" stroke="currentColor" strokeWidth="4"></circle>
<path className="opacity-75" fill="currentColor" d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 3.042 1.135 5.824 3 7.938l3-2.647z"></path>
</svg>

const productFriendlyName = "Jingle";//"Pin Wheel"

export const sleep = (ms: number) => {
    return new Promise( resolve => setTimeout(resolve, ms) );
}

export {
    isTrialActive,
    formatActivationCode,
    moneyToDisplayString,
    countryCodeToName,
    createGUID,
    paddleNums,
    createActivationCode,
    nameof,
    activationCodeToStatusBadge,
    emailSendStatusToStatusBadge,
    SpinnerIcon,
    productFriendlyName
}