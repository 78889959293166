"use client"

import { BuildInfo } from './tokyoComponents/PreBuildUtils'
import generatedBuildInfo from './buildInfo.generated.json'
const buildInfoData = generatedBuildInfo as BuildInfo;

const buildInfo = Object.assign({}, buildInfoData, {
    source:  Object.assign(buildInfoData.source, {
        gitCommitShortHash : buildInfoData.source.gitCommitHash.substring(0, 7)
    }) as BuildInfo["source"],
    channel: Object.assign(buildInfoData.channel, {
        isProduction : buildInfoData.channel.trunk === "production" || buildInfoData.channel.trunk === "staging",
        isStaging : buildInfoData.channel.trunk === "staging",
        isPreview : buildInfoData.channel.trunk === "preview",
        isDevelopment: buildInfoData.channel.trunk === "development"
    }) as BuildInfo["channel"],
    runtimeEnvironment: Object.assign(buildInfoData.runtimeEnvironment, {
        isDevMachine : buildInfoData.runtimeEnvironment.environment === "developmentmachine"
    }) as BuildInfo["runtimeEnvironment"]
} satisfies Partial<BuildInfo>) as BuildInfo

export const channelToString = (channel : BuildInfo["channel"]) => {
    const branchName = (() => {
        switch (true) {
            case channel.branch.mainBranch:
                return "main";
            default:
                return channel.branch.name;
        };
    })();
    return `${branchName}-${channel.trunk}`;
}

export {
    buildInfo
}