"use client"
import React, { useState, useEffect, useMemo, Fragment, useContext } from "react";
import {
    Badge,
    Col,
    Row,
    Table,
} from "react-bootstrap";
import { EpicPenContext, getUserLocale } from "../../tokyoComponents/utils/Identity";
import { buildInfo } from "../../BuildInfo";
import { countryCodeToName, moneyToDisplayString } from "../../tokyoComponents/utils/Utils";
import LoadingSpinner from "./../LoadingSpinner";
import { data } from "jquery";
import { PaginatedTable, TableContainer } from "./TableContainer";
import Link from "next/link";
import { createColumnHelper } from "@tanstack/react-table";
import { Order } from "src/tokyoComponents/utils/WebAPI";



interface OrderTableProps {
    minimal?: boolean,
    currentUserOnly: boolean
    subscription2id?: string
    userId?: string
}

const OrderTable = (props: OrderTableProps) => {
    const user  = useContext(EpicPenContext);
    const api = user.state !== 'uninitialised' ? user.api : null;
    const columnHelper = createColumnHelper<Order>()
    return PaginatedTable<Order>({
        minimal: props.minimal,
        fetchData: api ? (async (pageIndex, pageSize, searchString, abortController) => await api!.getOrders( pageIndex, pageSize, searchString, props.currentUserOnly, props.subscription2id, props.userId, abortController)) : null,
        columns: [
            columnHelper.accessor("date", {
                header: "Order Date",
                meta: {includeInMinimal: true},
                cell: info => `${new Date(info.getValue()).toLocaleTimeString(getUserLocale(user))} ${new Date(info.getValue()).toLocaleDateString(getUserLocale(user))}`
            }),
            columnHelper.accessor("displayAmount", {
                header: "Amount",
                meta: {includeInMinimal: true},
                cell: info => <>{info.getValue()} {info.row.original.status === "refunded" ? <span className="inline-flex items-center rounded-md bg-red-50 px-2 py-1 text-xs font-medium text-red-700 ring-1 ring-inset ring-red-600/10">Refunded</span> : []}</>
            }),
            columnHelper.accessor("payout", {
                header: "Payout amount",
                meta: {includeInMinimal: false},
                cell: info => info.row.original.payout ? info.getValue() : ""
            }),
            columnHelper.accessor("mode", {
                header: "Type",
                meta: {includeInMinimal: false},
                cell: info => info.getValue() === "subscription" ? "Subscription" : "Once off"
            }),
            //columnHelper.accessor("user.email", {
            //    header: "User",
            //    meta: {includeInMinimal: false, adminOnly: true},
            //    cell: info => info.row.original.userId && info.getValue() ? <Link href={`/profile/${info.row.original.userId}`}>{info.getValue()}</Link> : []
            //}),
            //columnHelper.accessor("user.countryCode", {
            //    header: "Country",
            //    meta: {includeInMinimal: false, adminOnly: true},
            //    cell: info => countryCodeToName(info.getValue())
            //}),
            columnHelper.display({
                id: "downloadInvoice",
                cell: info => {
                    //console.log(JSON.stringify(cellProps));
                    if (info.row.original.fastSpringOrderRef) {
                        return <a href={`https://licencing.epicpen.com/downloadOrderInvoice?orderId=${info.row.original.id}`} target="_blank" rel="noreferrer"><button
                            type="button"
                            className="btn btn-light btn-sm"
                        >
                            Download invoice
                        </button></a>
                    } else {
                        return [];
                    }

                }
            }),
            columnHelper.display({
                id: "viewDetails",
                meta: {includeInMinimal: true, alignRight: true},
                cell: info => {
                    //console.log(JSON.stringify(cellProps));
                    return <Link href={`#`} className="text-sky-600 hover:text-sky-900">
                        View details
                    </Link>;
                }
            })
        ]
    })
}

export default OrderTable;
