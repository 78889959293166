"use client"
import React, { useMemo, useRef } from 'react';
import {  useState, useEffect } from 'react';

import { setInterval, clearInterval } from 'timers';
import { ButtonAction, ToolbarOverlay } from './ToolbarOverlay';
import mixpanel from 'mixpanel-browser';
import { CallStatus, CursorState, DefaultDesktopSize, LineSegment, LocalMicMediaStream, LocalMicStatus, LocalWebcamMediaStream, LocalWebcamStatus, MediaDeviceDetailedInfo, RemoteMicStatus, RemoteWebcamStatus, Size, Stroke, TokyoConnectionController, createGUID, useTokyoConnection } from './TokyoConnection';

import { useSearchParams } from 'next/navigation';
import { useUser } from './utils/Identity';
import { buildInfo } from '../BuildInfo';
import { forEach } from 'lodash';


export type CursorInfoDataChannelMessage = 
{type : "desktopViewLineSegment", data : LineSegment}
| {type : "desktopViewCursorState", data : {state: "onscreen", x : number, y : number, timestamp: number} | {state: "offscreen", timestamp: number}}

export type OverlayMode = "clickSolid" | "clickThrough"

export module TokyoDesktopElementViewModel {
    export type PenSize = "small" | "medium" | "large"
    export type CursorMode = 
        "cursor" 
        | "highlightedCursor"
        | "pencil"
        | "highlighter"
        | "line"
        | "ellipse"
        | "rectangle"
        | "arrow"
        | "magnifier"
        | "vignette"
    export type Color = "cyan" | "magenta" |"yellow" |  "black"

    export type TokyoDesktopElementViewModel = {
       penSize : PenSize
       cursorMode : CursorMode
       color : Color
       localScreenShare : {enabled : false} | {enabled : true, displayId : string} 
       remoteScreenShare : {enabled : false} | {enabled : true, displayId : string} 
       callStatus : CallStatus
       localMic : LocalMicStatus
       remoteMic : RemoteMicStatus
       localWebcam : LocalWebcamStatus
       remoteWebcam : RemoteWebcamStatus
       mediaDevices : MediaDeviceDetailedInfo
       localUser : {state : "notLoggedIn"} | {state: "loggedIn", formalName : string, username : string}
    }
}



export type DesktopCursorState = CursorState 

export type TokyoDesktopElementClickThroughPortMessage = {
    type : "addDesktopViewLineSegment",
    lineSegment : LineSegment
} | {
    type : "setDesktopViewCursorState",
    desktopCursorState : DesktopCursorState
} | {
    type : "buttonAction",
    buttonAction : ButtonAction
} | {
    type : "updateViewModelProp",
    viewModelProperties : Partial<TokyoDesktopElementViewModel.TokyoDesktopElementViewModel>
} | {
    type : "updateViewModel",
    viewModel : TokyoDesktopElementViewModel.TokyoDesktopElementViewModel
} 


export type TokyoDesktopElementClickSolidPortMessage = {
    type : "updateViewModelProp",
    viewModelProperties : Partial<TokyoDesktopElementViewModel.TokyoDesktopElementViewModel>
} | {
    type : "localMicAudioActive",
    localMicAudioActive : boolean
} | {
    type : "localMicAudioLevel",
    localMicAudioLevel : number | null
} 

export type TokyoDesktopElementController = {
    type : "clickSolid" 
    sendToClickThroughController : (value : TokyoDesktopElementClickThroughPortMessage) => void
    setPortMessageHandler : (handler : (value : TokyoDesktopElementClickSolidPortMessage) => void) => void
    getPortMessageHandler : () => (value : TokyoDesktopElementClickSolidPortMessage) => void
    desktopSize : Size | null
    callSessionId : string,
} | {
    type : "clickThrough"
    connectionController : TokyoConnectionController,
    sendToClickSolidController : (value : TokyoDesktopElementClickSolidPortMessage) => void,
    setPortMessageHandler : (handler : (value : TokyoDesktopElementClickThroughPortMessage) => void) => void
    getPortMessageHandler : () => (value : TokyoDesktopElementClickThroughPortMessage) => void
}

export type OverlayArguments = {
    callSessionId : string, 
    mode : OverlayMode,
    screenInfo : {id : string, displayId: string, name: string, width : number, height : number, uiScale : number}
};

export type MainProcessAPI = { 
    onSetCallSessionId: (callback: (value : OverlayArguments) => void) => void
    sendPortMessage: (value : string) => void
    sendMessageToMain :(value : string) => void
    onPortMessage: (callback : (value : string) => void) => void
};

export type ElectronWindow = { mainProcessAPI: MainProcessAPI } & Window


const penSizeToIconWidth = (penSize : TokyoDesktopElementViewModel.PenSize) => {    
    switch (true) {
        case (penSize === 'small'):
            return 0.3;
        case (penSize === 'medium'):
            return 0.7;
        case (penSize === 'large'):
            return 1.0;
        default:
            throw new Error(penSize satisfies never);
    }
}

const mediaStreamToMediaElementRef = (mediaStream : MediaStream | null) => (ref : HTMLMediaElement | null) => {if (ref && mediaStream && ref.srcObject !== mediaStream) {ref.srcObject = mediaStream}}


const WebcamElement = ({mediaStream, top, left, showBorder, visible, muted, color, formalName, username} : {webCamVideoElement? : React.MutableRefObject<HTMLVideoElement | null>, mediaStream : MediaStream, top : number, left: number, showBorder : boolean, visible : boolean, muted : boolean, color? : TokyoDesktopElementViewModel.Color, formalName : string, username : string,}) => {
    return <>
            <div className={`webcamContainer absolute inset-0 rounded-md opacity-50 ${visible ? "" : "hidden"}`} style={{margin : `${top}px 0px 0px ${left}px`, width: "220px", height: "220px"}}>
                <video muted={muted} playsInline id='remoteWebcamVideoElement' autoPlay={true} ref={mediaStreamToMediaElementRef(mediaStream)} className={`absolute inset-0 rounded-md`} style={{width: "220px", height: "220px", objectFit: 'cover'}} />
                <div>
                    <h2 className='text-white absolute' style={{left: "12px", bottom: "30px"}}>{formalName}</h2>
                    <p className='text-white absolute text-xs' style={{left: "12px", bottom: "12px"}}>{username}</p>
                </div>
                <div  className={`absolute inset-0 rounded-md ${showBorder && color ? "border-2" : ""} ${color === 'black' ? "border-black" : color === 'cyan' ? "border-cyan-500" : color === 'magenta' ? "border-pink-500" : color === 'yellow' ? "border-yellow-500" : ""}`} style={{width: "220px", height: "220px"}}></div>
            </div>
    </>
}

const TokyoDesktopElementLayer = ({controller, scaleToFit, mode, visible, handelQuitRequest} : {
    controller : TokyoDesktopElementController, 
    scaleToFit: boolean, 
    mode : "desktopOverlay" | "webBrowser",
    visible: boolean,
    handelQuitRequest : () => void
}) => {
    const user = useUser();
    
    const [viewModel, setViewModel] = useState<TokyoDesktopElementViewModel.TokyoDesktopElementViewModel>({
        callStatus: {type : 'initialising'},
        color : "magenta",
        cursorMode : mode === 'desktopOverlay' ? "cursor" : "pencil",
        localMic : {state: "muted"},
        localScreenShare : {enabled: false},
        localWebcam : {state: "notShared"},
        penSize : 'medium',
        remoteMic : {state: "muted"},
        remoteScreenShare : {enabled: false},
        remoteWebcam : {state: 'notShared'},
        mediaDevices : {
            state: 'initialising'
        },
        localUser : {state: 'notLoggedIn'}
    });
    const [localMicAudioLevel, setLocalMicAudioLevel] = useState<null | number>(null)
    
    const [audioLevelHistroy, setAudioLevelHistroy] = useState<number[]>([])
    const [clickSolidLocalMicAudioActive, setClickSolidLocalMicAudioActive] = useState<boolean>(false)

    const localMicAudioActive = controller.type === 'clickThrough' ? audioLevelHistroy.findIndex((level) => level > 0.1) !== -1 : clickSolidLocalMicAudioActive



    const [localWebcamMediaStream, setLocalWebcamMediaStream] = useState<LocalWebcamMediaStream>({state: "notShared"})
    const [localMicMediaStream, setLocalMicMediaStream] = useState<LocalMicMediaStream>({state: "muted"})

    
    const setLocalWebcamMediaStreamSafe = (mediaStream : LocalWebcamMediaStream) => { 
        setLocalWebcamMediaStream((oldMediaStream) => {
            if (oldMediaStream.state === 'shared') {
                oldMediaStream.disposeVideoStream();
            }
            return mediaStream;
        })
    };
    
    const setLocalMicMediaStreamSafe = (mediaStream : LocalMicMediaStream) => { 
        setLocalMicMediaStream((oldMediaStream) => {
            if (oldMediaStream.state === 'unmuted') {
                oldMediaStream.disposeAudioStream();
            }
            return mediaStream;
        })
    };


    //sendPortMessage? : (value : string) => void},
    //setPortMessageHandler : (handler : (value : string) => void) => void) => {
    if (controller.type === 'clickThrough') {
        useEffect(() => {
            setViewModelProperties({localUser : user.state === 'loggedIn' && user.hasEPUser ? {state: "loggedIn", formalName: `${user.epUser.firstName} ${user.epUser.lastName}`, username: user.epUser.name } : {state: "notLoggedIn"}}, true)
        }, [user])

        useEffect(() => {
            let dispList : (() => void)[] = [];

            const TryGetMediaStream : (audio : LocalMicMediaStream, video : LocalWebcamMediaStream) => Promise<{state : "success", mediaStream : MediaStream, addAudioLevelListener : (listener : (level : number) => void) => void, disposeAudio : () => void, disposeVideo : () => void, audioDeviceId : string | null, videoDeviceId : string | null} | {state : "failure", error : "notAllowed" | "notReadable" | "unknown", message: string }> = async (audio, video) => {
                try {
                    const devices = await navigator.mediaDevices.enumerateDevices()

                    let videoDevice = video.state === 'sharedPendingPermission' ? devices.filter((device) => device.kind === 'videoinput').find((device, i) => video.deviceId ? device.deviceId === video.deviceId : i === 0) ?? null : null;
                    let audioDevice = audio.state === 'unmutedPendingPermission' ? devices.filter((device) => device.kind === 'audioinput').find((device, i) => audio.deviceId ? device.deviceId === audio.deviceId : i === 0) ?? null : null;

                    if (videoDevice || audioDevice) {
                        const mediaStream = 
                            await navigator.mediaDevices.getUserMedia(
                                { 
                                    video: (videoDevice ? {
                                        deviceId: videoDevice.deviceId,
                                        aspectRatio: 1,
                                        width: 256
                                        } : false),
                                    audio : audioDevice ? {
                                        deviceId: audioDevice.deviceId,
                                        echoCancellation: true
                                    } : false
                                }
                            );

                            
                        var audioListeners : ((level : number) => void)[] = []
                        var audioVolumnTimer : NodeJS.Timer | null = null
                        if (audioDevice) {
                            var audioContext = new AudioContext();
                            const mediaStreamSource = audioContext.createMediaStreamSource(mediaStream);
                            const analyser = audioContext.createAnalyser();
                            mediaStreamSource.connect(analyser)
                            const pcmData = new Float32Array(analyser.fftSize);
    
    
                            audioVolumnTimer = setInterval(() => {
                                analyser.getFloatTimeDomainData(pcmData);
                                var pcmSum = 0;
                                for (const amplitude of pcmData) {
                                    pcmSum += amplitude * amplitude;
                                }
                                const value1 = Math.sqrt(pcmSum / pcmData.length);
                                const value = Math.min(1.0, value1 * 4.0)
                                audioListeners.forEach((listener) => listener(value));
                            }, 10);
                        }

                        const disposeAudio = () => {
                            audioListeners = [];
                            if (audioVolumnTimer) {
                                clearInterval(audioVolumnTimer);
                            }
                            mediaStream.getAudioTracks().forEach((track) => track.stop());
                        }

                        const disposeVideo = () => {
                            mediaStream.getVideoTracks().forEach((track) => track.stop());
                        }

                        const addAudioLevelListener = (listener : (level : number) => void) => {
                            audioListeners.push(listener);
                        }

                        return { state : "success", mediaStream, disposeAudio, addAudioLevelListener, disposeVideo, audioDeviceId : audioDevice?.deviceId ?? null, videoDeviceId : videoDevice?.deviceId ?? null }
                    } else {
                        throw "Could not find video or audio device"
                    }

                } catch (e) {
                    const error = e as DOMException;
                    console.log({
                        errorName : error.name,
                        message : error.message
                    })
                    
                    if (error.name  === "NotAllowedError" ) {
                        return { state : "failure", error: "notAllowed", message : error.message }
                    } if (error.name  === "NotReadableError" ) {
                        return { state : "failure", error: "notReadable", message : error.message }
                    } else {
                        return { state : "failure", error: "unknown", message : error.message }
                    }
                }
            }

            const initMediaStream = async (audio : LocalMicMediaStream, video : LocalWebcamMediaStream) => {
                if (video.state === 'sharedPendingPermission') {
                    setViewModelProperties({
                        localWebcam :  {state : 'sharedPendingPermission', deviceId: video.deviceId},
                    }, true);
                }
                if(audio.state === 'unmutedPendingPermission') {
                    setViewModelProperties({
                        localMic : {state : 'unmutedPendingPermission', deviceId: audio.deviceId}
                    }, true);
                }

                const handleNewMediaSteamSuccess = (mediaStream : MediaStream, audioDeviceId : string | null, addAudioLevelListener : (listener : (level : number) => void) => void, disposeAudio : () => void, videoDeviceId : string | null, disposeVideo : () => void) => {
                    if (video.state === 'sharedPendingPermission' && videoDeviceId) {
                        setLocalWebcamMediaStreamSafe({state : 'shared', mediaStream, disposeVideoStream : disposeVideo, deviceId: videoDeviceId})
                        setViewModelProperties({
                            localWebcam : {state : 'shared', deviceId: videoDeviceId} 
                        }, true);
                    }
                    if(audio.state === 'unmutedPendingPermission' && audioDeviceId) {
                        setLocalMicMediaStreamSafe({state : 'unmuted', mediaStream, addAudioLevelListener, disposeAudioStream : disposeAudio, deviceId: audioDeviceId})
                        setViewModelProperties({
                            localMic : {state : 'unmuted', deviceId: audioDeviceId}
                        }, true);
                    }
                }

                const newMediaStream = await TryGetMediaStream(audio, video);

                if (newMediaStream.state === 'success') {
                    handleNewMediaSteamSuccess(newMediaStream.mediaStream, newMediaStream.audioDeviceId, newMediaStream.addAudioLevelListener, newMediaStream.disposeAudio, newMediaStream.videoDeviceId, newMediaStream.disposeVideo)
                } else {
                    if (newMediaStream.error === 'notAllowed') {
                        const status = await navigator.permissions.query({name: "camera" as any as PermissionName});

                        const changeEventistener = async () => {
                            const eventMediaStream = await TryGetMediaStream(audio, video);
                            if (eventMediaStream.state === 'success') {
                                handleNewMediaSteamSuccess(eventMediaStream.mediaStream, eventMediaStream.audioDeviceId, eventMediaStream.addAudioLevelListener, eventMediaStream.disposeAudio, eventMediaStream.videoDeviceId, eventMediaStream.disposeVideo)
                            }
                        }
                        status.addEventListener("change", changeEventistener, { once: true });
                        dispList.push(() => status.removeEventListener("change", changeEventistener));
                    } else {
                        throw `error initialising webcam - ${newMediaStream.message}`
                    }
                }
            };

            if (localWebcamMediaStream.state === 'sharedPendingPermission' || localMicMediaStream.state === 'unmutedPendingPermission') {
                initMediaStream(localMicMediaStream, localWebcamMediaStream);
            } 
            if (localWebcamMediaStream.state === 'notShared') {
                setViewModelProperties({localWebcam : {state : 'notShared'}}, true);
            }
            if (localMicMediaStream.state === 'muted') {
                setViewModelProperties({localMic : {state : 'muted'}}, true);
            }

            controller.connectionController.setLocalWebcamMediaStream(localWebcamMediaStream)
            controller.connectionController.setLocalMicMediaStream(localMicMediaStream)

            return () => dispList.forEach((disp) => disp());
        }, [localWebcamMediaStream.state, localMicMediaStream.state])



    }






    let [desktopViewUIScale, setDesktopViewUIScale] = useState<number>(1.0);

    let [localDesktopCursorState, setLocalDesktopCursorState] = useState<CursorState>({ state : 'offscreen', timestamp : Date.now()});

    
    const [pendingConnectioDesktopViewLineSegments, setPendingConnectioDesktopViewLineSegments] = useState<Stroke[]>([])
    
    const addPendingConnectionDesktopViewLineSegment = (lineSegment : LineSegment) => {
        const id = lineSegment.id;
        const strokeId = lineSegment.strokeId;
        setPendingConnectioDesktopViewLineSegments((strokes) => {
            const existingStroke = strokes.find((stroke) => stroke.id === strokeId);
            if (existingStroke) {
                return strokes.map((stroke) => {
                    if (stroke === existingStroke) {
                        return Object.assign({}, stroke, { lineSegments: stroke.lineSegments.concat([lineSegment]) } satisfies Partial<Stroke>);
                    } else {
                        return stroke;
                    }
                });
            } else {
                return strokes.concat([ {id : strokeId, lineSegments: [lineSegment]} satisfies Stroke])
            }
        });

        setTimeout(() => 
            setPendingConnectioDesktopViewLineSegments((strokes) => 
                strokes.map(
                    (stroke) => {
                        if (stroke.id === strokeId) {
                            return Object.assign({}, stroke, { lineSegments: stroke.lineSegments.filter((lineSegment) => lineSegment.id !== id)} satisfies Partial<Stroke>);
                        } else {
                            return stroke;
                        }
                    }
                )
            )
        , lineSegment.fadePeriod);
        
    };
    

    //if (controller.type === 'clickSolid') {
    //    console.log("clickSolid viewModel:")
    //    console.log(viewModel)
    //}if (controller.type === 'clickThrough') {
    //    console.log("clickSolid clickThrough:")
    //    console.log(viewModel)
    //}

    const setViewModelProperties = (viewModelProperties : Partial<TokyoDesktopElementViewModel.TokyoDesktopElementViewModel>, sendToPairedController : boolean) => {
        setViewModel((viewModel : TokyoDesktopElementViewModel.TokyoDesktopElementViewModel) => {
            const newViewModel =  Object.assign({}, viewModel, viewModelProperties);
            return newViewModel;
        });
        if (controller.type === 'clickSolid' && sendToPairedController) {
            controller.sendToClickThroughController({
                type: 'updateViewModelProp',
                viewModelProperties
            })
        } else if (controller.type === 'clickThrough' && sendToPairedController) {
            controller.sendToClickSolidController({
                type: 'updateViewModelProp',
                viewModelProperties
            })
        }
    }

    useEffect(() => {
        (async () => {
            const updateDeviceList = async () => {
                const loadedMediaDevices = (await navigator.mediaDevices.enumerateDevices()).map((o) => o);
                console.log(loadedMediaDevices)
                setViewModelProperties({
                    mediaDevices : {
                        state: 'loaded',
                        deviceInfo: loadedMediaDevices
                    } 
                }, true)
            };
            updateDeviceList();
            const cameraPermissionStatus = await navigator.permissions.query({name: "camera" as any as PermissionName});
            const microphonePermissionStatus = await navigator.permissions.query({name: "microphone" as any as PermissionName});
            
            navigator.mediaDevices.addEventListener("devicechange", updateDeviceList);
            cameraPermissionStatus.addEventListener("change", updateDeviceList);
            microphonePermissionStatus.addEventListener("change", updateDeviceList,);
            //dispList.push(() => status.removeEventListener("change", changeEventistener));
        })();
    }, [])
    
    if (controller.type === 'clickThrough') {
        const callStatus : CallStatus = useMemo(() => {
             return controller.connectionController.status === 'initialising' ? {type : 'initialising'} : ( controller.connectionController.status === 'pendingConnection' ? {type : 'pendingConnection', sessionId : controller.connectionController.sessionId} : {type : 'connected', sessionId : controller.connectionController.sessionId, remoteUserId: controller.connectionController.remoteUserId});
            }, [
                controller.connectionController.status, 
                controller.connectionController.status !== 'initialising' ? controller.connectionController.sessionId : null,
                controller.connectionController.status === 'connected' ? controller.connectionController.remoteUserId : null
            ]
        );
    
        useEffect(() => {
            setViewModelProperties({callStatus}, true)}
        , [callStatus])

        useEffect(() => {
            if (controller.type === 'clickThrough' && controller.connectionController.status === 'connected') {
                //console.log(controller.connectionController.localMicStatus)
                setViewModelProperties({
                    localMic : controller.connectionController.localMicStatus,
                    localWebcam : controller.connectionController.localWebcamStatus
                }, true)
            }
        }
        , [controller.connectionController])

        
        //useEffect(() => {
        //    console.log(`TokyoDesktopElement useEffect-controller.connectionController2`)
        //    console.log(controller.connectionController)
        //}, [controller.connectionController])
        
    }

    const lastMousePosRef = useRef<{x : number, y : number} | null>(null)

    
    if (controller.type === "clickThrough") {
        useEffect(() => {
            controller.setPortMessageHandler((message) => {
                if (message.type === "addDesktopViewLineSegment") {
                    if (controller.connectionController.status === 'connected') {
                        //controller.connectionController.sendCursorInfo(
                        //        {
                        //            type: "desktopViewLineSegment",
                        //            data : message.lineSegment
                        //        }
                        //);
                        controller.connectionController.sendDesktopViewLineSegment(message.lineSegment)
                    } else {
                        addPendingConnectionDesktopViewLineSegment(message.lineSegment)
                    }
                } else if (message.type === 'setDesktopViewCursorState') {
                    setLocalDesktopCursorState(() => message.desktopCursorState);
                    if (controller.connectionController.status === 'connected') {
                        controller.connectionController.sendDesktopViewCursorState(message.desktopCursorState);
                        controller.connectionController.sendCursorInfo(
                                {
                                    type: "desktopViewCursorState",
                                    data : message.desktopCursorState
                                } 
                        );
                    }
                } else if (message.type === "updateViewModelProp") { 
                    setViewModelProperties(message.viewModelProperties, false)
                } else if (message.type === "updateViewModel") { 
                    setViewModel((viewModel : TokyoDesktopElementViewModel.TokyoDesktopElementViewModel) => message.viewModel);
                } else if (message.type === 'buttonAction') {
                    console.log(message.buttonAction)
                    if (message.buttonAction.type === 'localMic' && message.buttonAction.value === 'unmute') {
                        //if (controller.connectionController.status === 'connected') {
                        //    controller.connectionController.setLocalMicStatus("unmuted")
                        //}
                        setLocalMicMediaStreamSafe({state : 'unmutedPendingPermission', deviceId: message.buttonAction.deviceId})
                    }
                    else if (message.buttonAction.type === 'localMic' && message.buttonAction.value === 'mute') {
                        //if (controller.connectionController.status === 'connected') {
                        //    controller.connectionController.setLocalMicStatus("muted")
                        //}
                        setLocalMicMediaStreamSafe({state : 'muted'})
                    }
                    else if (message.buttonAction.type === 'localWebcam' && message.buttonAction.value === 'share') {
                        //if (controller.connectionController.status === 'connected') {
                        //    controller.connectionController.setLocalWebcamStatus("shared")
                        //}
                        setLocalWebcamMediaStreamSafe({state : 'sharedPendingPermission', deviceId: message.buttonAction.deviceId});
                    }
                    else if (message.buttonAction.type === 'localWebcam' && message.buttonAction.value === 'stopSharing') {
                        //if (controller.connectionController.status === 'connected') {
                        //    controller.connectionController.setLocalWebcamStatus("notShared")
                        //}
                        setLocalWebcamMediaStreamSafe({state : 'notShared'});
                    }
                    else if (message.buttonAction.type === 'penSize') {
                        setViewModelProperties({ penSize: message.buttonAction.value}, true)
                    }
                    else if (message.buttonAction.type === 'color') {
                        setViewModelProperties({ color: message.buttonAction.value}, true)
                    }
                    else if (message.buttonAction.type === 'cursor') {
                        setViewModelProperties({ cursorMode: message.buttonAction.value}, true)
                    }
                    else if (message.buttonAction.type === 'call') {
                        if (message.buttonAction.value === 'end') {
                            if (controller.connectionController.status === 'connected') {
                                controller.connectionController.requestEndCall()
                            } else {
                                handelQuitRequest()
                            }
                        }
                    }
                }
            })
        }, [controller.connectionController.status])
    } else if (controller.type === "clickSolid") {
        useEffect(() => {
            controller.setPortMessageHandler((message) => {
                if (message.type === "updateViewModelProp") { 
                    setViewModelProperties(message.viewModelProperties, false)
                } else if (message.type === "localMicAudioActive") { 
                    setClickSolidLocalMicAudioActive(message.localMicAudioActive)
                } else if (message.type === "localMicAudioLevel") { 
                    setLocalMicAudioLevel(message.localMicAudioLevel)
                } 
            });
        }, []);
    }

  const isMouseDownToken = useRef<null | string>(null)

  const desktopViewMouseMoveEvent = (ev : React.MouseEvent<HTMLDivElement, MouseEvent> | React.TouchEvent<HTMLDivElement> | React.PointerEvent<HTMLDivElement>, mode : "moving" | "leaving") => {
    const rect = ev.currentTarget.getBoundingClientRect()
    //await new Promise((r) => setTimeout(r, 180))
    if (controller.type === "clickSolid") {
        

        const pointerEv = (ev as React.PointerEvent<HTMLDivElement>).pointerType ? ev as React.PointerEvent<HTMLDivElement> : null;
        
        const pointerPressure = pointerEv?.pressure ?? 0.5;
        
        const x = (("buttons" in ev ? ev.clientX : ev.touches[0].clientX) - rect.left) / (desktopViewUIScale ?? 1.0);
        const y = (("buttons" in ev ? ev.clientY : ev.touches[0].clientY) - rect.top) / (desktopViewUIScale ?? 1.0);
        if(("buttons" in ev ? ev.buttons > 0 : ev.touches.length > 0) && isMouseDownToken.current) {
            //console.log({x : x, y : y})

            const penSize = penSizeToIconWidth(viewModel.penSize)

            const lineSegment = {
                x1 : lastMousePosRef.current?.x ?? x, 
                y1 : lastMousePosRef.current?.y ?? y, 
                x2 : x, 
                y2 : y,
                thickness : Math.max(pointerPressure, 0.5) * 16.0 * penSize,
                color : viewModel.color,
                fadePeriod: 5000,
                id: createGUID(),
                strokeId: isMouseDownToken.current
            } satisfies LineSegment as LineSegment;
            //console.log("addDesktopViewLineSegment")
            //console.log(lineSegment)
            controller.sendToClickThroughController({
                type : "addDesktopViewLineSegment", 
                lineSegment: lineSegment
            });
            lastMousePosRef.current = {x : x, y: y};
        } else {
            lastMousePosRef.current = null
        }
        
        controller.sendToClickThroughController({
            type : "setDesktopViewCursorState", 
            desktopCursorState: mode === 'moving' ? {state: "onscreen", x : x, y : y, pressed: isMouseDownToken.current !== null, color: viewModel.color, timestamp: ev.timeStamp} : {state : 'offscreen', timestamp: ev.timeStamp}
        });
    } else {}
}


    const setUIScale = (uiScale : number) => {
        //if (controller.type === 'clickSolid') {
        //    setDesktopViewUIScale(uiScale)
        //} else if (controller.type === 'clickThrough' && controller.connectionController.status === 'connected') {
        //    controller.connectionController.sendDesktopViewUIScale(uiScale)
        //}
        setDesktopViewUIScale(uiScale)
    };

    const desktopSize = controller.type === "clickThrough" ? controller.connectionController.status === 'connected' ? controller.connectionController.desktopSize : DefaultDesktopSize : controller.type === 'clickSolid' ? controller.desktopSize ?? DefaultDesktopSize : DefaultDesktopSize

    const isCurrentUserFirst = (() => {
        if (controller.type === "clickThrough" && controller.connectionController.status === 'connected') {
            const sortedIds = [controller.connectionController.localUserId, controller.connectionController.remoteUserId].sort();
            return sortedIds[0] === controller.connectionController.localUserId;
        } else {
            return true;
        }
    })();

    const showWebcamBorder = (controller.type == 'clickThrough' && controller.connectionController.status === 'connected' ? controller.connectionController.remoteDesktopViewStrokes : []).length > 0;

    const viewModelColorToTailwindStroke = (color : TokyoDesktopElementViewModel.Color, type : "stroke" |  "fill") => {
        switch (true) {
            case (color === 'black'):
                return type === 'stroke' ? "stroke-gray-800" :  "fill-gray-800";
            case (color === 'cyan'):
                return type === 'stroke' ? "stroke-cyan-400" :  "fill-cyan-400";
            case (color === 'magenta'):
                return type === 'stroke' ? "stroke-pink-400" :  "fill-pink-400";
            case (color === 'yellow'):
                return type === 'stroke' ? "stroke-yellow-400" :  "fill-yellow-400";
            default:
                throw new Error(color satisfies never);
        }
    }



    useEffect(() => {
        if (localMicMediaStream.state === 'unmuted') {
            localMicMediaStream.addAudioLevelListener((level) => {
                setLocalMicAudioLevel(level);
            })
        } else {
            setLocalMicAudioLevel(null);
        }
    }, [localMicMediaStream.state])
    
    useEffect(() => {
        setAudioLevelHistroy((history) => [localMicAudioLevel ?? 0].concat(history).filter((o, i) => i < 60))
        
        if (controller.type === 'clickThrough') {
            controller.sendToClickSolidController({
                type: 'localMicAudioLevel',
                localMicAudioLevel
            })
        }
    }, [localMicAudioLevel])

    
    useEffect(() => {
        if (controller.type === 'clickThrough') {
            controller.sendToClickSolidController({
                type: 'localMicAudioActive',
                localMicAudioActive
            })
        }
    }, [localMicAudioActive])

    const localDesktopCursorIsPressed = localDesktopCursorState.state === 'onscreen' && localDesktopCursorState.pressed;
    const remoteDesktopCursorIsPressed = controller.type === 'clickThrough' && controller.connectionController.status === 'connected' && controller.connectionController.remoteDesktopViewCursorState.state === 'onscreen' && controller.connectionController.remoteDesktopViewCursorState.pressed 

    return  <div  
            className={`absolute inset-0 flex rounded-md ${controller.type === 'clickThrough' ? ((mode === 'desktopOverlay') || (viewModel.callStatus.type !== 'connected') ? "" : "bg-fuchsia-200") : ""}`} 
            style={{ 
                maxWidth: `100%`, 
                maxHeight: `100%`, 
                margin: "auto",
                pointerEvents: controller.type === "clickThrough" ? "none" : "auto",
                aspectRatio: `${ desktopSize.width/desktopSize.height}`
            }}
        >
            <div 
                className={`w-full h-full rounded-md ${controller.type === 'clickThrough' ? ((viewModel.callStatus.type === "connected") || (mode === 'desktopOverlay') ? "" : "animate-desktopbg bg-gradient-to-br from-pink-200 via-indigo-200 to-pink-200") : ""}`} 
                //onMouseMove={(ev) => desktopViewMouseMoveEvent(ev, "onMouseMove")} 
                onPointerMove={(ev) => desktopViewMouseMoveEvent(ev, "moving")}
                //onTouchMove={(ev) => desktopViewMouseMoveEvent(ev, "onTouchMove")} 
                //onMouseLeave={(ev) => desktopViewMouseLeaveEvent(ev)} 
                //onTouchEnd={(ev) => desktopViewMouseLeaveEvent(ev)}
                onPointerUp={(ev) => {isMouseDownToken.current = null; desktopViewMouseMoveEvent(ev, "moving")}} 
                onPointerDown={(ev) => {isMouseDownToken.current = createGUID(); desktopViewMouseMoveEvent(ev, "moving")}} 
                onPointerLeave={(ev) => {isMouseDownToken.current = null; desktopViewMouseMoveEvent(ev, "leaving")}} 
                >
            </div>
            {
                <ToolbarOverlay visible={visible} viewModel={viewModel} localMicAudioActive={localMicAudioActive} localMicAudioLevel={localMicAudioLevel} setViewModelProperties={(properties) => setViewModelProperties(properties, true)} mode={controller.type} scaleToFit={scaleToFit} className='absolute inset-0 flex z-10 rounded-md w-full h-full' videoWidth={desktopSize.width} setUIScale={setUIScale} buttonActionHandler={(buttonAction) => {if (controller.type === 'clickSolid') {controller.sendToClickThroughController({type : "buttonAction", buttonAction})}}} >{
                    <>
                    { controller.type === 'clickThrough' ? 
                        <>
                                
                            <video  muted={true} playsInline autoPlay={true} id='desktopVideoElement' ref={mediaStreamToMediaElementRef(controller.connectionController.mediaElements.desktopVideoMediaStream)} className={`absolute inset-0 ${viewModel.callStatus.type !== 'connected' ? "hidden" : ""} w-full rounded-md`} />
                            {
                                controller.connectionController.mediaElements.remoteWebcamVideoMediaStream ? <WebcamElement muted={true} mediaStream={controller.connectionController.mediaElements.remoteWebcamVideoMediaStream} top={isCurrentUserFirst ? 60 : 300} left={40}  showBorder={showWebcamBorder && (mode === 'desktopOverlay')} formalName={""} username='' visible={viewModel.callStatus.type === 'connected' } /> : <></>
                            }
                            {
                                controller.connectionController.mediaElements.localWebcamVideoMediaStream ? <WebcamElement muted={true} mediaStream={controller.connectionController.mediaElements.localWebcamVideoMediaStream} top={isCurrentUserFirst ? 300 : 60} left={40} showBorder={localMicAudioActive}  formalName={viewModel.localUser.state === 'loggedIn' ? viewModel.localUser.formalName : ""} username={viewModel.localUser.state === 'loggedIn' ? `@${viewModel.localUser.username}` : ""}  visible={viewModel.callStatus.type === 'connected' && viewModel.localWebcam.state === 'shared' } /> : <></>
                            }
                            {
                                localWebcamMediaStream.state === 'shared' ? <WebcamElement muted={true} mediaStream={localWebcamMediaStream.mediaStream} top={false ? 300 : 60} left={40} showBorder={localMicAudioActive} color={viewModel.color}  formalName={viewModel.localUser.state === 'loggedIn' ? viewModel.localUser.formalName : ""} username={viewModel.localUser.state === 'loggedIn' ? `@${viewModel.localUser.username}` : ""} visible={viewModel.callStatus.type !== 'connected'  && viewModel.localWebcam.state === 'shared'} /> : <></>
                            }
                            <audio playsInline autoPlay={true} ref={mediaStreamToMediaElementRef(controller.connectionController.mediaElements.remoteAudioMediaStream)} />
                        </> : []
                    }
                    <svg markerUnits="userSpaceOnUse"  className={`absolute inset-0 flex z-10 ${controller.type == 'clickThrough' && controller.connectionController.status === 'connected' ? "" : "opacity-50"}`}  style={{pointerEvents: "none", left:`${0}`, top: `${0}`, height: `${desktopSize.height}`, width: `${desktopSize.width}`}} viewBox={`0 0 ${desktopSize.width} ${desktopSize.height}`} aria-hidden="true">
                    {
                        (controller.type == 'clickThrough' && controller.connectionController.status === 'connected' ? controller.connectionController.remoteDesktopViewStrokes : pendingConnectioDesktopViewLineSegments).map((stroke) => {
                            return stroke.lineSegments.map((lineSegment) => {
                                return controller.type == 'clickThrough' ? 
                                    <line className={`${viewModelColorToTailwindStroke(lineSegment.color, "stroke")}`} key={`${stroke.id}-${lineSegment.id}`} x1={lineSegment.x1} y1={lineSegment.y1} x2={lineSegment.x2} y2={lineSegment.y2} strokeWidth={lineSegment.thickness} strokeLinecap='round'/>
                                 : <></>
                            });
                        })
                        
                    }</svg>
                    {
                        controller.type == 'clickThrough'  && localDesktopCursorState.state === 'onscreen' ? <svg className={`absolute inset-0 flex z-10 ${viewModelColorToTailwindStroke(localDesktopCursorState.color, "fill")}`} style={{pointerEvents: "none", left:`${localDesktopCursorState.x - (localDesktopCursorIsPressed ? 16 : 32)}`, top: `${localDesktopCursorState.y - (localDesktopCursorIsPressed ? 16 : 32)}`, height: localDesktopCursorIsPressed ? "32" : "64", width: localDesktopCursorIsPressed ? "32" : "64", opacity: 0.2}} viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg> : []
                    }
                    {
                        controller.type == 'clickThrough' && controller.connectionController.status === 'connected' && controller.connectionController.remoteDesktopViewCursorState.state === 'onscreen' ? <svg className={`absolute inset-0 flex z-10 ${viewModelColorToTailwindStroke(controller.connectionController.remoteDesktopViewCursorState.color, "fill")}`} style={{pointerEvents: "none", left:`${controller.connectionController.remoteDesktopViewCursorState.x - (remoteDesktopCursorIsPressed ? 16 : 32)}`, top: `${controller.connectionController.remoteDesktopViewCursorState.y - (remoteDesktopCursorIsPressed ? 16 : 32)}`, height: remoteDesktopCursorIsPressed ? "32" : "64", width: remoteDesktopCursorIsPressed ? "32" : "64", opacity: 0.2}} viewBox="0 0 6 6" aria-hidden="true">
                            <circle cx={3} cy={3} r={3} />
                        </svg> : []
                    }
                    
                    </>
                }</ToolbarOverlay>
            }
        </div>
}

const TokyoDesktopElement = ({handleQuitRequest} : {handleQuitRequest : () => void}) => {
    
  const user  = useUser();
  const userHasPermissionToSeePage = (user.state === "uninitialised") || (user.state === 'loggedIn' && ((user.hasEPUser && user.epUser.role === 'admin') || (user.mode === 'dummy') || (user.hasEPUser && user.epUser.tokyoAccess)))
  const searchParams = useSearchParams()
  
  let [clickSolidMessageHandler, setClickSolidMessageHandler]  = useState<(message :TokyoDesktopElementClickSolidPortMessage) => void>(() => (message :TokyoDesktopElementClickSolidPortMessage) => {});
  let [clickThroughMessageHandler, setClickThroughMessageHandler] = useState<(message :TokyoDesktopElementClickThroughPortMessage) => void >(() => (message :TokyoDesktopElementClickThroughPortMessage) => {});

  const connectionController = useTokyoConnection({
    callSessionId: searchParams.get("callSessionId") ?? undefined, 
    createSessionOffer: Boolean(searchParams.get("createSessionOffer")),
    user: user.state == 'loggedIn' && user.hasEPUser ? buildInfo.runtimeEnvironment.isDevMachine ? {type: 'anonymousDevice', deviceId : "eb20edda-8433-43e4-bee1-7eee6506820b"} : {type : "signedIn", user: user.epUser} : null,
    getClickSolidMessageHandler: () => clickSolidMessageHandler,
    setMessageHandler: (handler : (message :TokyoDesktopElementClickThroughPortMessage) => void ) => setClickThroughMessageHandler(() => handler),
    getMessageHandler: () => clickThroughMessageHandler,
    handleCallEndRequest : handleQuitRequest
  });

  const elementClickThroughController : TokyoDesktopElementController = {
    type: "clickThrough",
    connectionController,
    setPortMessageHandler: (handler : (message :TokyoDesktopElementClickThroughPortMessage) => void ) => setClickThroughMessageHandler(() => handler),
    getPortMessageHandler: () => clickThroughMessageHandler,
    sendToClickSolidController : (message : TokyoDesktopElementClickSolidPortMessage) => {
        clickSolidMessageHandler(message);
    }
  }

  const clickSolidController : TokyoDesktopElementController = {
    type: "clickSolid",
    callSessionId: searchParams.get("callSessionId")!, 
    desktopSize: connectionController.status === 'connected' ? connectionController.desktopSize : null,
    //desktopViewUIScale: connectionController.status === 'connected' ? connectionController.desktopViewUIScale : null,
    sendToClickThroughController: (message) => (elementClickThroughController.getPortMessageHandler() as (value : TokyoDesktopElementClickThroughPortMessage) => void)(message),
    setPortMessageHandler : (handler) => {setClickSolidMessageHandler(() => handler)},
    getPortMessageHandler: () => clickSolidMessageHandler
  }


    return <div className={`flex-auto justify-center w-full h-full relative rounded-md ${userHasPermissionToSeePage ? "" : "hidden"}`}>
        <TokyoDesktopElementLayer visible={userHasPermissionToSeePage} controller={elementClickThroughController} scaleToFit={true} mode="webBrowser" handelQuitRequest={handleQuitRequest} />
        <TokyoDesktopElementLayer visible={userHasPermissionToSeePage} controller={clickSolidController} scaleToFit={true} mode="webBrowser"  handelQuitRequest={handleQuitRequest}/>
    </div> 
}

export {
    TokyoDesktopElementLayer,
    TokyoDesktopElement,
    createGUID,
    penSizeToIconWidth
}
