"use client"
import '../globals.css'
import { Inter } from 'next/font/google'
import ReactGA from "react-ga4";
//
//
//

import Head from "next/head";
import { Fragment, PropsWithChildren, useEffect, useMemo, useState } from "react";
//import { useRouter } from "next/router";
import Link from "next/link";
import {
  BellIcon,
  CalendarIcon,
  ChartPieIcon,
  ChevronDownIcon,
  DocumentDuplicateIcon,
  FolderIcon,
  HomeIcon,
  UsersIcon,
  Squares2X2Icon,
  DocumentTextIcon,
  DocumentPlusIcon,
  UserIcon,
  PhoneIcon,
  ClockIcon,
  VideoCameraIcon,
  Cog8ToothIcon,
  BuildingOffice2Icon,
  CreditCardIcon,
  ShieldCheckIcon,
  Cog6ToothIcon,
  ArrowLeftOnRectangleIcon,
  ArrowRightOnRectangleIcon
} from '@heroicons/react/24/outline'
//import 'bootstrap/dist/css/bootstrap.css';
import { FiCompass} from "react-icons/fi";
import { Dialog, Menu, Transition } from "@headlessui/react";
import { Provider } from 'react-redux';
import { usePathname, useSearchParams } from 'next/navigation';
import { buildInfo } from "src/BuildInfo";// ''/ 'src/epicPenHelpers/build_info';
import { EPTUser, EpicPenContext, EpicPenProvider} from 'src/tokyoComponents/utils/Identity';
import { User as EPUser, User, createEpicPenLicencingAPI, createFakeUser } from 'src/tokyoComponents/utils/WebAPI';
import { Metadata } from 'next';
import mixpanel from 'mixpanel-browser';
import { useUser } from 'src/tokyoComponents/utils/Identity';
import { RootLayoutElement } from './components';

export default function RootLayout({
  children,
}: {
  children: React.ReactNode
}) {


  const useProductionTenant = true;
  return <EpicPenProvider>
        <RootLayoutElement>{children}</RootLayoutElement>
      </EpicPenProvider>
}