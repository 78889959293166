"use client"
import { create, isNumber, random } from "lodash";
import React, { useState, useEffect, useMemo, useContext, createContext, PropsWithChildren } from "react";
import { boolean, number } from "yup";
import { buildInfo } from "../../BuildInfo";
import { createGUID } from "./Utils";
import { 
    User as UserImport, 
    Subscription as SubscriptionImport , 
    Order as OrderImport, 
    Address as AddressImport, 
    Organisation as OrganisationImport, 
    $Enums,
} from "@prisma/client"
import { createGunzip } from "node:zlib";
import Decimal from 'decimal.js';

const epicPenServicesServerURL = "https://licencing.epicpen.com/"
export const tokyoServicesServerURL = (buildInfo.runtimeEnvironment.isDevMachine && false) ? "http://localhost:6041/" : "https://service.tokyo.tankstudios.ltd/"

type MetaDataFeilds = "meta_deleted"| "meta_version" | "crdb_region";//"meta_creationDateTime" | "meta_lastEditDateTime"  | "meta_ownedByOrganisationId" | "meta_ownedByUserId"
export type User = Omit<UserImport, MetaDataFeilds>

export type Subscription = Omit<SubscriptionImport, MetaDataFeilds>
export type Order = Omit<OrderImport, MetaDataFeilds>
export type Address = Omit<AddressImport, MetaDataFeilds>
export type Organisation = Omit<OrganisationImport, MetaDataFeilds>
export type CountryCode = $Enums.CountryCode
export type LanguageCode = $Enums.LanguageCode

export type DetailedUser = User & {address : Address}  & {organisation : Organisation | null} ;
//const test = ({} as Order).

//export interface User {
//    id: string
//    auth0id: string
//    lastName: string
//    firstName: string
//    company: string
//    countryCode: string | null
//    email: string
//    changeRequestEmail : string | null
//    registerDate: string
//    notes: string | null
//    testMode: boolean
//    userPermissionGrantedForEmailMarketing: boolean
//    tapfiliateReferralCode: string | null
//    mixPanelDistinctId: string | null
//    emailVerified: boolean
//    refCode: string | null
//    role: "admin" | "standard"
//    emailVerificationToken: string | null
//    emailVerificationEmailStatus: EmailSendStatus
//    emailChangeVerificationEmailStatus: EmailSendStatus
//    emailVerificationEmailSendGridId: string | null
//    hasPermissionToUseTokyo: boolean
//    deleted: boolean
//}

export interface Order_ {
    PONumber: string
    coupons: string
    currency: string
    payoutCurrency: string
    displayName: string
    fastSpringOrderRef: string
    paddleReceiptURL: string
    paddleOrderId: string
    fastspringProductPath: string
    fastspringTotalUSDPayout: string
    howIUse: string
    id: string
    individualActivationCodes: boolean
    licenceType: string
    licenceeId: string
    moneyAmount: number
    payoutMoneyAmount: number
    notes: string
    numOfLicences: number
    orderDate: string
    orderNum: number
    stackCommerceTransactionId: string
    stripeId: string
    mode: "subscription" | "onceoff"
    status: "active" | "refunded"
    userId: string | null
    subscriptionId: string | null
    user: User
}

export type BillingPeriod = $Enums.BillingPeriod;// "monthly" | "yearly";

export interface Subscription_ {
    id: string
    userId: string
    creationDate: string
    notes: string
    currency: string
    price: number
    cancelURL: string
    updateURL: string
    paddleCheckoutId: string
    paddleSubscriptionId: string
    paddleSubscriptionPlanId: string
    numOfLicences: number
    billingPeriod: BillingPeriod
    nextBillDate: string | null
    testMode: boolean
    status: "active" | "trialing" | "pastdue" | "paused" | "deleted"
}

export interface Payment {
    amount: number
    currency: string
    date: string
}

export interface PaymentInformation {
    payment_method: string
    card_type: string
    last_four_digits: string
    expiry_date: string
}

export interface PaddleSubscription {
    subscription_id: number
    plan_id: number
    user_id: number
    user_email: string
    marketing_consent: boolean
    update_url: string
    cancel_url: string
    state: "active" | "past_due" | "trialing" | "paused"
    signup_date: string
    last_payment: Payment
    payment_information: PaymentInformation
    quantity: number
    next_payment: Payment
}

export type PaddleBillingType = "day" | "month" | "year"

export interface PaddleSubscriptionPlan {
    id: number
    name: string
    billing_type: PaddleBillingType
    billing_period: number
    initial_price: {
        [index: string]: string;
    }
    recurring_price: {
        [index: string]: string;
    }
    trial_days: number
}

export interface DetailedSubscription2 {
    subscription: Subscription
    paddleSubscription: PaddleSubscription
    paddleSubscriptionPlans: PaddleSubscriptionPlan[]
}

export interface DeviceId {
    id: string
    activationCode: string
    osType: string
    activationDate: string
    activatedByGitSHA: string
    createdByEpicPenVersion: string
    lastVerifiedByEpicPenVersion: string
    signedHash: string
    creationDate: string
    createdByGitSHA: string
    lastVerificationDate: string
    lastVerifiedByGitSHA: string
    isTrialMode: string
    friendlyName: string
}

export interface ActivationCode {
    id: string
    maxDevices: number
    deviceIdCount: number
    displayName: string | null
    orderId: string | null
    subscriptionId?: string | null
    alternativeAdminEmail?: string
}

export type EmailSendStatus = "unsent" | "processed" | "dropped" | "delivered" | "deferred" | "bounce" | "blocked"


export interface Subscription2Upload {
    userId: string
    notes: string
    numOfLicences: number
    activationCode: string
    displayName: string
    alternativeAdminEmail: string | null
}


export interface CustomOrder {
    id: string
    userId: string
    billingPeriod: BillingPeriod
    percentageDiscount: number
    recurring: boolean
    numOfLicences: number
    endUserName: string
    endUserEmail: string
    paymentUserEmail: string
    notes: string
    paddleCouponCode: string
    PONumber: string
    fulfilled: boolean
}



export interface LicenceDetails {
    totalDeviceLicences: number
    activatedDevices: number
}

type WebServiceFailureResponse = {
    status : "failure",
    errorCode : string,
    message : string
}

export type WebServiceResponse<T> = {
    status : "success"
    item: T
} | WebServiceFailureResponse

export type PaginatedWebServiceResponse<T> = {
    status : "success"
    totalItemCount: number
    items: T[]
} | WebServiceFailureResponse



const createAPIRequestInternal = async <T,>(path: string, getAccessTokenSilently: () => Promise<string>, method?: string, queryParams?: any, body?: any, pageIndex?: number, pageSize?: number, searchString?: string, abortController?: AbortController, useTokyoServices? : boolean) => {

    //const { getAccessTokenSilently } = useAuth0();

    //const getAccessTokenResponse = await getAccessTokenSilently({
    //    audience: `https://licencing.epicpen.com`,
    //    scope: "read:current_user"
    //});
    const accessToken = await getAccessTokenSilently()

    //const responseTest = await fetch(`${serverUrl}auth0TestMethod`, {
    //    method: method || "GET",
    //    headers: {
    //        Authorization: `Bearer ${getAccessTokenResponse}`,
    //    },
    //});
    //console.log(`responseTest: ${await responseTest.text()}`)
    const paginatedRequest = isNumber(pageIndex) && isNumber(pageSize);
    const query = "?" + (paginatedRequest ? [`limit=${pageSize}`, `offset=${pageIndex * pageSize}`, `search=${searchString ?? ""}`] : []).concat(queryParams ? Object.keys(queryParams).filter((key) => queryParams[key] !== undefined).map(key => `${key}=${queryParams[key]}`) : []).join("&");

    const formData = new FormData();
    if (body instanceof File) {
        formData.append("fileupload", new Blob([body]));
    }

    const headers = Object.assign({}, {
        "Authorization": `Bearer ${accessToken}`
    }, body instanceof File ? {} : {
        "Content-Type": 'application/json'
    }) as HeadersInit;

    const response = await fetch(`${(useTokyoServices || true)? tokyoServicesServerURL : epicPenServicesServerURL}${path}${query}`, {
        method: method || "GET",
        body: body ? body instanceof File ? body : JSON.stringify(body) : null,
        headers: headers,
        signal: abortController?.signal
    });


    return response
}


const createAPIRequest = async <T,>(path: string, getAccessTokenSilently: () => Promise<string>, method?: string, queryParams?: any, body?: any, abortController?: AbortController, useTokyoServices? : boolean) => {
    const response = await createAPIRequestInternal(path, getAccessTokenSilently, method, queryParams, body, undefined, undefined, undefined, abortController, useTokyoServices)
    const jsonResponse = response.headers.has("content-type") ? response.headers.get("content-type")!.indexOf("application/json") !== -1 : false;

    const result : WebServiceResponse<T> = response.ok ? (await response.json() as WebServiceResponse<T>) : {status : "failure", errorCode : "unknown", message : "unknown"};
    //if (buildInfo.channel.isDevelopment)
        //console.log({
        //    name: "createAPIRequest",
        //    path,
        //    queryParams,
        //    response,
        //    jsonResponse,
        //    result
        //});
    return result;
}



const createPaginatedAPIRequest = async<T,>(path: string, getAccessTokenSilently: () => Promise<string>, method ?: string, queryParams? : any, body?: any, pageIndex?: number, pageSize?: number, searchString?: string, abortController?: AbortController, useTokyoServices? : boolean) => {
    const response = await createAPIRequestInternal(path, getAccessTokenSilently, method, queryParams, body, pageIndex, pageSize, searchString, abortController, useTokyoServices)
    const itemCount = response.headers.get("x-item-count");
    return (response.ok ? (await response.json()) : {status : "failure", errorCode : "unknown", message : "unknown"})  as PaginatedWebServiceResponse<T>;
}


const createFakeDataRequest = async <T,>(responseData: T, abortController?: AbortController) : Promise<WebServiceResponse<T>> => {
    const data = await createFakeDataResponse(responseData, abortController)
    return { status: "success", item: data } satisfies WebServiceResponse<T>;
}

const createFakeDataResponse = async <T,>(responseData: T, abortController?: AbortController) : Promise<T> => {
    await new Promise((resolve, reject) => {
        abortController?.signal.addEventListener("abort", ((ev) => { reject(); }));
        setTimeout(resolve, 600 + 400 * Math.random());
    });
    return responseData;
}

const createPaginatedFakeDataResponse = async <T,>(responseData: T[], abortController?: AbortController) => {
    await new Promise((resolve, reject) => {
        abortController?.signal.addEventListener("abort", ((ev) => { reject(); }));
        setTimeout(resolve, 600 + 400 * Math.random());
    });
    return {
        totalItemCount: 200,
        items: responseData
    } as PaginatedWebServiceResponse<T>;
}

const createFakeActivationCode = () => Array.from(Array(4 * 6).keys()).map(() => Math.floor(Math.random() * 16).toString(16)).join("")

const createFakeUser = (i? : number) => {
    return {
        id: createGUID(),
        name: "joe.smith",
        language: "en",
        email: "fake@icme.ie",
        firstName: "Joe",
        lastName: "Smith",
        role: "admin",
        auth0id: createGUID(),
        notes: "",
        testMode: false,
        tapfiliateReferralCode: "",
        addressId: createGUID(),
        loginCount: 1,
        emailMarketingPermission: "unspecified",
        marketingAnalyticsCookiePermission: "unspecified",
        productUsageTelemetryPermission: "unspecified",
        organisationId: null,
        paddleCustomerId: createGUID(),
        primarySubscriptionId: null,
        changeEmailRequest: null,
        meta_creationDateTime: new Date(),
        meta_lastEditDateTime: new Date(),
        tokyoAccess: true,
        emailVerificationEmailStatus: "unsent",
        changeEmailRequestVerificationEmailStatus: "unsent",
        changeEmailRequestVerificationToken: null,
        emailVerified: false,
        changeEmailRequestVerificationTokenExpirationDateTime: new Date(),
        resetPasswordRequestVerificationToken: null,
        resetPasswordRequestVerificationTokenExpirationDateTime: new Date(),
        avatarId: null,
        marketResearch_employeeCount: $Enums.MarketResearch_EmployeeCount.notSpecified,
        marketResearch_intendedUseCase: "",
        marketResearch_organisationType: $Enums.MarketResearch_OrgnisationType.notSpecified,
        marketResearch_sector: $Enums.MarketResearch_Sector.notSpecified,
    } satisfies User as User;
}
const createFakeDetailedUser = (i? : number) => {
    return {
        id: createGUID(),
        name: "joe.smith",
        language: "en",
        email: "fake@icme.ie",
        firstName: "Joe",
        lastName: "Smith",
        role: "admin",
        auth0id: createGUID(),
        notes: "",
        testMode: false,
        tapfiliateReferralCode: "",
        addressId: createGUID(),
        loginCount: 1,
        emailMarketingPermission: "unspecified",
        marketingAnalyticsCookiePermission: "unspecified",
        productUsageTelemetryPermission: "unspecified",
        organisationId: null,
        paddleCustomerId: createGUID(),
        primarySubscriptionId: null,
        changeEmailRequest: null,
        meta_creationDateTime: new Date(),
        meta_lastEditDateTime: new Date(),
        tokyoAccess: true,
        emailVerificationEmailStatus: "unsent",
        changeEmailRequestVerificationEmailStatus: "unsent",
        changeEmailRequestVerificationToken: null,
        emailVerified: false,
        organisation: null,
        changeEmailRequestVerificationTokenExpirationDateTime: new Date(),
        resetPasswordRequestVerificationToken: null,
        resetPasswordRequestVerificationTokenExpirationDateTime: new Date(),
        avatarId: null,
        marketResearch_employeeCount: $Enums.MarketResearch_EmployeeCount.notSpecified,
        marketResearch_intendedUseCase: "",
        marketResearch_organisationType: $Enums.MarketResearch_OrgnisationType.notSpecified,
        marketResearch_sector: $Enums.MarketResearch_Sector.notSpecified,
        address: {
            countryCode: "IE",
            city: "",
            id: createGUID(),
            line1: "",
            line2: "",
            meta_creationDateTime: new Date(),
            meta_lastEditDateTime: new Date(),
            meta_ownedByOrganisationId: null,
            meta_ownedByUserId: null,
            paddleAddressId: "",
            postalOrZipCode: "",
            regionOrState: "",
            testMode: false
        }
    } satisfies DetailedUser as DetailedUser;
}







const createFakeCustomerOrder = (): CustomOrder => {
    return {
        id: createGUID(),
        userId: createGUID(),
        billingPeriod: "monthly",
        percentageDiscount: 20,
        recurring: true,
        numOfLicences: 3,
        endUserName: "End User",
        endUserEmail: "endUser@icme.ie",
        paymentUserEmail: "purchases@icme.ie",
        notes: "test",
        paddleCouponCode: createGUID(),
        PONumber: createGUID(),
        fulfilled: false
    };
}




const createEpicPenLicencingAPI = (currentUserId : string, getAccessTokenSilently: () => Promise<string>, useFakeData: boolean) => {

    return {
        //
        //   Users
        //

        getUsers : (pageIndex: number, pageSize: number, searchString?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<User>(Array.from(Array(pageSize).keys()).map((i) => createFakeUser(i)
                ), abortController);
            } else {
                return createPaginatedAPIRequest<User>(`user`, getAccessTokenSilently, undefined, undefined, undefined, pageIndex, pageSize, searchString, abortController,  true);
            }
        },

        getCurrentUser : async () => {
            if (useFakeData) {
                return createFakeDataResponse<DetailedUser>(createFakeDetailedUser());
            } else {
                const result = await createAPIRequest<DetailedUser>(`user/${currentUserId}`, getAccessTokenSilently);
                if (result.status === "success") {
                    //const adjustedUser = buildInfo.runtimeEnvironment.isDevMachine ? Object.assign(
                    //    {}, 
                    //    result.item, 
                    //    {
                    //        name: Array.from(Array(15).keys()).map(() => "w").join(""),
                    //        organisation : Object.assign(
                    //            {}, 
                    //            result.item.organisation,
                    //            {
                    //                name: Array.from(Array(15).keys()).map(() => "w").join("")
                    //            }
                    //        )
                    //    }
                    //) : result.item;
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },


        getUser: async (userId: string) => {
            if (useFakeData) {
                return createFakeDataResponse<User>(createFakeUser());
            } else {
                const result = await createAPIRequest<User>(`user/${userId}`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },


        closeUserAccount : async (userId: string) => {
            if (useFakeData) {
                await new Promise((resolve, reject) => {
                    setTimeout(resolve, 600 + 400 * Math.random());
                });
                return {} as WebServiceResponse<void>;
            } else {
                return await createAPIRequest<void>(`closeaccount`, getAccessTokenSilently, "POST", { userId });
            }
        },

        changeUserEmail: async (userId: string, email : string) => {
            if (useFakeData) {
                await new Promise((resolve, reject) => {
                    setTimeout(resolve, 600 + 400 * Math.random());
                });
                return {} as WebServiceResponse<void>;
            } else {
                return await createAPIRequest<void>(`user/${userId}/changeEmail`, getAccessTokenSilently, "POST", undefined, { email });
            }
        },


        patchUser: (userId: string, body: Pick<User, "firstName" | "lastName" | "language"> & {countryCode : CountryCode}) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<void>(`user/${userId}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        
        setProductUsageTelemetryPermission: async (userId: string, body: Pick<User, "productUsageTelemetryPermission">) => {
            if (useFakeData) {
                return {} as WebServiceResponse<void>;
            } else {
                return await createAPIRequest<void>(`user/${userId}/productUsageTelemetryPermission`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        requestUsernameChange: (userId: string, body: { username : string }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}/requestUsernameChange`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        requestEmailChange: (userId: string, body: { email : string }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}/requestEmailChange`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },


        resendEmailVerificationEmail: (userId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`user/${userId}/resendemailverificationemail`, getAccessTokenSilently, "POST", undefined, { redirectURL : "https://epicpen.com" });
            }
        },

        requestPasswordReset: async (userId: string) => {
            if (useFakeData) {
                return {} as WebServiceResponse<void>;
            } else {
                return await createAPIRequest<void>(`user/${userId}/requestPasswordReset`, getAccessTokenSilently, "POST");
            }
        },

        setAvatar: async (userId: string, avatar : File) => {
            if (useFakeData) {
                return {} as WebServiceResponse<{avatarId : string}>;
            } else {
                return await createAPIRequest<{avatarId : string}>(`user/${userId}/setAvatar`, getAccessTokenSilently, "POST", undefined, avatar);
            }
        },

        //
        //   Orders
        //


        getOrders : (pageIndex: number, pageSize: number, searchString?: string, currentUserOnly?: boolean, subscription2Id?: string, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<Order>(Array.from(Array(pageSize).keys()).map((i) => {
                    return {
                        id: createGUID(),
                        mode: "subscription",
                        paddleTransactionId: null,
                        status: "active",
                        subscriptionId: createGUID(),
                        coupons: "",
                        currency: "EUR",
                        date: new Date(Date.now()),
                        displayAmount: "€25.00",
                        notes: "",
                        fastSpringOrderRef: null,
                        numOfLicences: 1,
                        paddleOrderId: null,
                        payout: new Decimal(20.0),
                        payoutCurrency: "EUR",
                        PONumber: null,
                        stripeId: null,
                        testMode: true,
                        meta_creationDateTime: new Date(),
                        meta_lastEditDateTime: new Date(),
                        meta_ownedByOrganisationId: null,
                        meta_ownedByUserId: null,
                    } satisfies Order as Order
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<Order>(`order${currentUserOnly ? "/ofcurrentuser" : ""}`, getAccessTokenSilently, undefined, { subscription2Id, userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },


getOrder : async (orderId: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<Order>({
                    id: createGUID(),
                    mode: "subscription",
                    paddleTransactionId: null,
                    status: "active",
                    subscriptionId: createGUID(),
                    coupons: "",
                    currency: "EUR",
                    date: new Date(Date.now()),
                    displayAmount: "€25.00",
                    notes: "",
                    fastSpringOrderRef: null,
                    numOfLicences: 1,
                    paddleOrderId: null,
                    payout: new Decimal(20.0),
                    payoutCurrency: "EUR",
                    PONumber: null,
                    stripeId: null,
                    testMode: true,
                    meta_creationDateTime: new Date(),
                    meta_lastEditDateTime: new Date(),
                    meta_ownedByOrganisationId: null,
                    meta_ownedByUserId: null,
                });
            } else {
                const result = await createAPIRequest<Order>(`order/${orderId}`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },

        //
        //   Subscription2
        //


        getDetailedSubscription2: async (subscription2Id: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<DetailedSubscription2>({
                    subscription: {
                        id: createGUID(),
                        activationCode: createFakeActivationCode(),
                        checkoutSessionId: createGUID(),
                        mode: "standard",
                        notes: "",
                        product: "epicpen",
                        quantity: 1,
                        status: "active",
                        paddleSubscriptionId: null,
                        billingUserId: null,
                        activationCodeEmailSendGridId: null,
                        activationCodeEnabled: true,
                        activationCodeEmailSentSuccessfully: true,
                        billingPeriod: "yearly",
                        displayName: null,
                        firstActivation: null,
                        nextBillingDate: new Date(),
                        nextBillingDisplayAmount: "€25.00",
                        currency: "EUR",
                        permanentModeOrderId: null,
                        quantityInUse: 1,
                        referralCode: null,
                        testMode: true,
                        alternativeAdminEmail: "",
                        paymentMethodCardCardHolderName: null,
                        paymentMethodCardExpiryMonth: null,
                        paymentMethodCardExpiryYear: null,
                        paymentMethodCardLast4: null,
                        paymentMethodCardType: null,
                        paymentMethodType: "unknown",
                        meta_creationDateTime: new Date(),
                        meta_lastEditDateTime: new Date(),
                        meta_ownedByOrganisationId: null,
                        meta_ownedByUserId: null,
                    },
                    paddleSubscription: {
                        "subscription_id": 502198,
                        "plan_id": 496199,
                        "user_id": 285846,
                        "user_email": "name@example.com",
                        "marketing_consent": true,
                        "update_url": "https://subscription-management.paddle.com/subscription/87654321/hash/eyJpdiI6IlU0Nk5cL1JZeHQyTXd.../update",
                        "cancel_url": "https://subscription-management.paddle.com/subscription/87654321/hash/eyJpdiI6IlU0Nk5cL1JZeHQyTXd.../cancel",
                        "state": "active",
                        "signup_date": "2015-10-06 09:44:23",
                        "last_payment": {
                            "amount": 5,
                            "currency": "EUR",
                            "date": "2015-10-06"
                        },
                        "payment_information": {
                            "payment_method": "card",
                            "card_type": "visa",
                            "last_four_digits": "1111",
                            "expiry_date": "02/2020"
                        },
                        "quantity": 3,
                        "next_payment": {
                            "amount": 10,
                            "currency": "EUR",
                            "date": "2015-11-06"
                        }
                    },
                    paddleSubscriptionPlans: [
                        {
                            id: 3,
                            name: createFakeActivationCode(),
                            billing_type: "month",
                            billing_period: 1,
                            initial_price: {
                                "EUR": "0.00"
                            },
                            recurring_price: {
                                "EUR": "3.00"
                            },
                            trial_days: 30
                        },
                        {
                            id: 4,
                            name: createFakeActivationCode(),
                            billing_type: "year",
                            billing_period: 1,
                            initial_price: {
                                "EUR": "0.00"
                            },
                            recurring_price: {
                                "EUR": "24.00"
                            },
                            trial_days: 30
                        }
                    ]
                });
            } else {
                const result = await createAPIRequest<DetailedSubscription2>(`detailedsubscription2/${subscription2Id}`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },


        patchSubscription: (subscription2Id: string, body: { [index: string]: any }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`subscription2/${subscription2Id}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        updateDelegatedAdmin: (subscription2id: string, displayName: string, alternativeAdminEmail: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`subscription2/${subscription2id}`, getAccessTokenSilently, "PATCH", undefined, { displayName: displayName, alternativeAdminEmail: alternativeAdminEmail });
            }
        },


        getSubscription2s: (pageIndex: number, pageSize: number, searchString?: string, currentUserOnly?: boolean, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<Subscription>(Array.from(Array(pageSize).keys()).map((i) => {
                return {
                    id: createGUID(),
                    activationCode: createFakeActivationCode(),
                    checkoutSessionId: createGUID(),
                    mode: "standard",
                    notes: "",
                    product: "epicpen",
                    quantity: 1,
                    status: "active",
                    paddleSubscriptionId: null,
                    billingUserId: null,
                    activationCodeEmailSendGridId: null,
                    activationCodeEnabled: true,
                    activationCodeEmailSentSuccessfully: true,
                    billingPeriod: "yearly",
                    displayName: null,
                    firstActivation: null,
                    nextBillingDate: new Date(),
                    nextBillingDisplayAmount: "€25.00",
                    currency: "EUR",
                    permanentModeOrderId: null,
                    quantityInUse: 1,
                    referralCode: null,
                    testMode: true,
                    alternativeAdminEmail: "",
                    paymentMethodCardCardHolderName: null,
                    paymentMethodCardExpiryMonth: null,
                    paymentMethodCardExpiryYear: null,
                    paymentMethodCardLast4: null,
                    paymentMethodCardType: null,
                    paymentMethodType: "unknown",
                    meta_creationDateTime: new Date(),
                    meta_lastEditDateTime: new Date(),
                    meta_ownedByOrganisationId: null,
                    meta_ownedByUserId: null,
                } satisfies Subscription;
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<Subscription>(`subscription${currentUserOnly ? "/ofcurrentuser" : ""}`, getAccessTokenSilently, undefined, { userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },


        getSubscription2: async (subscription2id: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<Subscription>({
                    id: subscription2id,
                    activationCode: createFakeActivationCode(),
                    checkoutSessionId: createGUID(),
                    mode: "standard",
                    notes: "",
                    product: "epicpen",
                    quantity: 1,
                    status: "active",
                    paddleSubscriptionId: null,
                    billingUserId: null,
                    activationCodeEmailSendGridId: null,
                    activationCodeEnabled: true,
                    activationCodeEmailSentSuccessfully: true,
                    billingPeriod: "yearly",
                    displayName: null,
                    firstActivation: null,
                    nextBillingDate: new Date(),
                    nextBillingDisplayAmount: "€25.00",
                    currency: "EUR",
                    permanentModeOrderId: null,
                    quantityInUse: 1,
                    referralCode: null,
                    testMode: true,
                    alternativeAdminEmail: "",
                    paymentMethodCardCardHolderName: null,
                    paymentMethodCardExpiryMonth: null,
                    paymentMethodCardExpiryYear: null,
                    paymentMethodCardLast4: null,
                    paymentMethodCardType: null,
                    paymentMethodType: "unknown",
                    meta_creationDateTime: new Date(),
                    meta_lastEditDateTime: new Date(),
                    meta_ownedByOrganisationId: null,
                    meta_ownedByUserId: null,
                });
            } else {
                const result = await createAPIRequest<Subscription>(`subscription/${subscription2id}`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },


        putSubscription2: async (subscription2: Subscription2Upload) => {
            if (useFakeData) {
                return await createFakeDataRequest<undefined>(undefined);
            } else {
                return (await createAPIRequest<undefined>(`subscription`, getAccessTokenSilently, "PUT", undefined, subscription2));
            }
        },


        createLicenseFile: async (subscription2id: string, notes : string) => {
            if (useFakeData) {
                return await createFakeDataRequest({ body: "", signature: "" });
            } else {
                return (await createAPIRequest<{body : string, signature : string}>(`subscription/createLicenseFile`, getAccessTokenSilently, "POST", { subscription2id }, { notes }));
            }
        },

        //
        //   Device ids
        //
        deleteDeviceId: (deviceId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`deviceId/${deviceId}`, getAccessTokenSilently, "DELETE");
            }
        },


        deleteDeviceIdsByActivationCode: (activationCode: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`deviceId`, getAccessTokenSilently, "DELETE", { activationCode });
            }
        },
        getDeviceIdsOfActivationCode: (activationCode: string, pageIndex: number, pageSize: number, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<DeviceId>(Array.from(Array(20).keys()).map((i) => {
                    return {
                        id: createGUID(),
                        activationCode: createFakeActivationCode(),
                        osType: i % 2 === 0 ? "mac" : "win",
                        activationDate: "",
                        activatedByGitSHA: "",
                        createdByEpicPenVersion: "",
                        lastVerifiedByEpicPenVersion: "",
                        signedHash: "",
                        creationDate: "",
                        createdByGitSHA: "",
                        lastVerificationDate: "",
                        lastVerifiedByGitSHA: "",
                        isTrialMode: "",
                        friendlyName: `Brian's MacBook Pro - ${i}`
                    }
                }
                ), abortController);
            } else {
                return createPaginatedAPIRequest<DeviceId>(`deviceId`, getAccessTokenSilently, undefined, { activationCode }, undefined, pageIndex, pageSize, undefined, abortController);
            }
        },


        //
        //   Custom orders
        //
        getCustomOrders: (pageIndex: number, pageSize: number, searchString?: string, userId?: string, abortController?: AbortController) => {
            if (useFakeData) {
                return createPaginatedFakeDataResponse<CustomOrder>(Array.from(Array(pageSize).keys()).map((i) => createFakeCustomerOrder()), abortController);
            } else {
                return createPaginatedAPIRequest<CustomOrder>(`customorder`, getAccessTokenSilently, undefined, { userId }, undefined, pageIndex, pageSize, searchString, abortController);
            }
        },

        getCustomOrder: async (customOrderId: string) => {
            if (useFakeData) {
                return await createFakeDataResponse<CustomOrder>(createFakeCustomerOrder());
            } else {
                const result = await createAPIRequest<CustomOrder>(`customorder/${customOrderId}`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },

        putCustomOrder: async (customOrderBody: { [index: string]: any }) => {
            if (useFakeData) {
                return await createFakeDataRequest<undefined>(undefined);
            } else {
                return (await createAPIRequest<undefined>(`customorder`, getAccessTokenSilently, "PUT", undefined, customOrderBody));
            }
        },

        patchCustomOrder: (customOrderId: string, body: { [index: string]: any }) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`customorder/${customOrderId}`, getAccessTokenSilently, "PATCH", undefined, body);
            }
        },

        deleteCustomOrder: (customOrderId: string) => {
            if (useFakeData) {
                return createFakeDataResponse(null);
            } else {
                return createAPIRequest<null>(`customorder/${customOrderId}`, getAccessTokenSilently, "DELETE");
            }
        },
        //
        // Misc
        //
        getLicenceDetailsOfCurrentUser: async () => {
            if (useFakeData) {
                var totalDeviceLicences = Math.floor(random() * 6 + 5)
                return await createFakeDataResponse({
                    totalDeviceLicences: totalDeviceLicences,
                    activatedDevices: totalDeviceLicences - Math.floor(random() * 3 + 1)
                }
                );
            } else {
                const result = await createAPIRequest<LicenceDetails>("licencedetails/ofcurrentuser", getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },
        
        getCurrencies: async () => {
            if (useFakeData) {
                return await createFakeDataResponse<string[]>(["USD", "EUR", "GBP"]);
            } else {
                const result = await createAPIRequest<string[]>(`currency`, getAccessTokenSilently);
                if (result.status === "success") {
                    return result.item;
                } else {
                    throw new Error(result.message);
                }
            }
        },

    }
}

export {
    createEpicPenLicencingAPI,
    createFakeUser
}